@mixin container {
  max-width: 1200px;
  margin: 0 auto;
}

@mixin for-xs-down {
  @media only screen and (max-width: ($breakpoint-xs - 0.02px)) {
    @content;
  }
}

@mixin for-sm-down {
  @media only screen and (max-width: ($breakpoint-sm - 0.02px)) {
    @content;
  }
}

@mixin for-md-down {
  @media only screen and (max-width: ($breakpoint-md - 0.02px)) {
    @content;
  }
}

@mixin for-lg-down {
  @media only screen and (max-width: ($breakpoint-lg - 0.02px)) {
    @content;
  }
}

@mixin for-xl-down {
  @media only screen and (max-width: ($breakpoint-xl - 0.02px)) {
    @content;
  }
}

@mixin for-xs-up {
  @media only screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin for-sm-up {
  @media only screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin for-md-up {
  @media only screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin for-lg-up {
  @media only screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin for-xl-up {
  @media only screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin for-xs-only {
  @media only screen and (max-width: ($breakpoint-xs - 0.02px)) {
    @content;
  }
}

@mixin for-sm-only {
  @media only screen and (min-width: $breakpoint-xs) and (max-width: ($breakpoint-sm - 0.02px)) {
    @content;
  }
}

@mixin for-md-only {
  @media only screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-md - 0.02px)) {
    @content;
  }
}

@mixin for-lg-only {
  @media only screen and (min-width: $breakpoint-md) and (max-width: ($breakpoint-lg - 0.02px)) {
    @content;
  }
}

@mixin for-xl-only {
  @media only screen and (min-width: $breakpoint-lg) and (max-width: ($breakpoint-xl - 0.02px)) {
    @content;
  }
}

@mixin widthAndHeight($width, $height, $top, $left, $position, $opacity) {
  width: $width;
  height: $height;
  top: $top;
  left: $left;
  position: $position;
  opacity: $opacity;
}