section {
  height: auto;
}
.featured-articles {
  background: $secondary;
  height: 917px;
}

.featured-articles {
  .container {
    display: $flex;
    position: relative;
    left: 336px;
  }

  article {
    background: $white;
    width: 600px;
    min-height: 678px;
    border-radius: 25px;
    margin-top: 119px;
    margin-right: 48px;

    img {
      display: block;
      width: 600px;
      height: 358px;
      border-radius: 25px 25px 0px 0px;
      opacity: 1;
    }

    h2 {
      margin-left: 30px;
      margin-top: 45px;
      font: normal normal bold 35px/47px $font-primary;
      letter-spacing: 0.1px;
      color: $text-color;
    }

    p {
      font: normal normal normal 18px/31px $font-secondary;
      letter-spacing: 0.05px;
      margin: 0px 30px;
      height: 98px;
      padding-top: 14px;
      overflow: hidden;
    }

    a {
      padding: 20px 24px;
      float: right;
      min-width: 260px;
      text-align: center;
      margin: 35px 30px 30px 0px;
    }
    .btn{
      font-family:  $font-secondary;
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
 @include for-xl-up{
  section.featured-articles {
    .container {
        @include widthAndHeight (1248px, auto, null, 0px, null, null);
      }
    }
  }

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.featured-articles {
    background: $secondary;
    height: 1571px;
    width: $breakpoint-sm;
  }

  .featured-articles {
    .container {
      display: block;
      left: 0;
      margin: 0;
      padding: 23px;
      width: 100%;
    }

    article {
      background: $white;
      width: 384px;
      min-height: 635px;
      border-radius: 25px;
      margin-top: 60px;
      height: auto;

      img {
        width: 384px;
        height: 271px;

        border-radius: 20px 20px 0px 0px;

      }

      h2 {
        margin: 23px 20px;
        font: normal normal bold 32px/43px $font-primary;
        letter-spacing: -0.03px;
        color: $text-color;
      }

      p {
        font: normal normal 300 16px/30px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
        opacity: 1;
        padding: 0px;
        margin: 0px 23px;
        height: 148px;
      }

      a {

        margin: 24px 23px 49px;
        font: normal normal 600 18px/27px $font-secondary;
        float: none;
        width: 338px;
      }

    }
  }


}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.featured-articles {

    @include widthAndHeight(100%, 666px, null, null, null, null);

    .container {
      display: flex;
      left: 16px;
      position: relative;
      width: 760px;

      article {
        background: $white;
        border-radius: 20px;
        min-height: 495px;
        margin-right: 30px;
        margin-top: 87px;
        width: 349px;
        position: relative;

        img {
          width: 100%;
          height: auto;
          border-radius: 20px 20px 0px 0px;

        }

        h2 {
          font: normal normal bold 22px/30px $font-primary;
          margin: 26px 15px 21px 15px;
        }

        p {
          font: normal normal normal 16px/27px $font-secondary;
          letter-spacing: .05px;
          width: 310px;
          min-height: 209px;
          margin: 0px 15px;
          padding: 0px;
          overflow: hidden;

        }

        a {
          font: normal normal 600 16px/25px $font-secondary;
          padding: 18px 32px;
          min-width: 220px;
          position: absolute;
          bottom: 0px;
          right: 0px;
          margin: 18px 15px 15px;
        }

      }

    }

  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .featured-articles {
    background: $secondary;
    @include widthAndHeight(100%, 916px, null, null, null, null);

    .container {
      display: $flex;
      @include widthAndHeight($breakpoint-lg - 110px, auto, null, 0px, relative, null);
      margin: 0px auto;

      article {
        background: $white;
        border-radius: 25px;
        height: 678px;
        margin-right: 30px;
        margin-top: 115px;
        width: 570px;
        position: relative;

        img {
          width: 570px;
          height: 339px;
          border-radius: 25px 25px 0px 0px;

        }

        h2 {
          font: normal normal bold 35px/47px $font-primary;
          margin-left: 31px;
          margin-top: 29px;
        }

        p {
          font: normal normal normal 18px/31px $font-secondary;
          letter-spacing: .05px;
          margin-left: 30px;
          width: 510px;
          height: 114px;
          overflow: hidden;
        }

        a {
          left: 275px;
          font: normal normal 600 18px/27px $font-secondary;
          margin: 35px 30px 30px 0px;
          position: absolute;
          bottom: 0px;
        }

      }

    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.featured-articles {
    background: $secondary;
    height: auto;
    width: 100%;
  }

  .featured-articles {
    .container {
      display: block;
      left: 0;
      margin: 0;
      padding: 23px;
      width: 100%;
    }

    article {
      background: $white;
      width: 100%;
      min-height: 635px;
      border-radius: 25px;
      margin-top: 60px;
      height: auto;
      padding-bottom: 7px;
      img {
        width: 100%;
        height: 271px;

        border-radius: 20px 20px 0px 0px;

      }

      h2 {
        margin: 23px 20px;
        font: normal normal bold 32px/43px $font-primary;
        letter-spacing: -0.03px;
        color: $text-color;
      }

      p {
        font: normal normal 300 16px/30px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
        opacity: 1;
        padding: 0px;
        margin: 0px 23px;
        height: 148px;
      }

      a {

        margin: 0px auto;
        font: normal normal 600 18px/27px $font-secondary;
        float: none;
        width: 88%;
        display: $block;
      }

    }
  }
}
