.btn, .button, .button.tertiary, .button.secondary {
  display: inline-block;
  padding: 16px 55px 17px 54px;
  border: 2px solid $primary;
  color: $primary;
  border-radius: 100px;
  text-decoration: none;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    background-color: $primary-lightest;
  }

}

.btn.primary, .button.primary{
  color: white;
  background: $primary;

  &:hover {
    background-color: $primary-light;
    border-color: $primary-light;
  }

}

.link{
  color: $primary;
  text-decoration: underline;
  &:hover{text-decoration:none;}
}

.hamburger{
  cursor: pointer;
}
.hamburger span{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3px;
  width: 28px;
  background: $text-color;
  margin: 6px 0;
}
.hamburger.open{
  span{display: none;}
  &:before{display:block;content:'X';font-size:40px;}
}
