section {
  position: unset;
}

#header {
  display: block;
}

.no-results-found {
  p {
    margin-top: 0.2em;
    font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;

    a {
      color: $primary;
      display: inline;
      font-weight: 500;
      text-decoration: none;
      transition: all .1s ease-out;
    }
  }

  .list {
    display: block;
    margin: 6px 0;
    padding-left: 36px;

    li {
      list-style-type: disc;
      font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;
      margin-bottom: 3px;
      color: $text-dark;
    }
  }
}

.funeral_listing_wrapper {
  width: 100%;

  @mixin text-ellipsis {
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .funeral-listing-flex {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;

    .left-column {
      margin: 34px 0px;

      #funeral-desktop-ad {
        min-height: auto;
        margin-top: $base-margin * 2;

        .container {
          @include widthAndHeight(317px, auto, 0px, 0px, null, 1);
          margin: 0px;

          .ad-block.ad-mrec[data-google-query-id] {
            margin-bottom: $base-margin * 3;
          }
        }
      }
    }

    .right-column {
      width: 901px;
      margin: 34px 0px 0px 34px;

      .funeral-notices-list {
        .funeral-container {
          width: 901px;

          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;

          .fn-header {
            width: 100%;

            display: grid;
            grid-template-rows: auto auto auto auto;
            grid-template-columns: 1fr 1fr;
            row-gap: 15px;

            h1 {
              grid-column: 1 / -1;
              margin: -8px 0 0 0;
              color: $text-dark;
              font: 700 40px/normal $font-primary;
            }

            .search-result-daterange {
              grid-column: 1 / -1;
              margin-top: -10px;
              color: $text-dark;
              font: italic 400 #{$base-font-size - 2px}/normal $font-secondary;
            }

            .heading-separator {
              grid-column: 1 / -1;
              border: none;
              border-bottom: 1px solid $secondary-dark;
            }

            .badge-pill {
              grid-column: span 1;
              align-self: center;
              width: 175px;
              height: 33px;
              color: $secondary-dark;
              font: 400 $base-font-size/normal $font-secondary;
              border-radius: 100px;
              border: 1px solid $secondary-dark;

              display: flex;
              justify-content: center;
              align-items: center;
            }

            .fn-sort-options-container {
              grid-column: span 1;

              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 15px;

              .fn-sort-icon-groups {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 15px;

                .calender-sort-icon {
                  background: url("./images/calendar-filter.svg") no-repeat center;
                }

                .calender-atoz-sort-icon {
                  background: url("./images/a-z.svg") no-repeat center;
                }

                .calender-ztoa-sort-icon {
                  background: url("./images/z-a.svg") no-repeat center;
                }

                .calender-sort-icon,
                .calender-atoz-sort-icon,
                .calender-ztoa-sort-icon {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  border: 1px solid $primary;

                  &.active {
                    background-color: $light-secondary;
                  }
                }
              }

              .daterange-dropdown {
                width: 150px;
                height: 45px;
                padding-left: 15px;
                color: $text-dark;
                font: normal $base-font-size/normal $font-secondary;
                background: $secondary url("./images/search-down.svg") no-repeat calc(100% - 15px);
                border-radius: 100px;
                border: 1px solid $secondary-dark;
                outline: none;

                &::after {
                  position: relative;
                  top: 10px;
                  color: $text-dark;
                  font: 400 $base-font-size/normal $font-secondary;
                }
              }
            }
          }

          .obituary {
            align-items: center;
            height: 448px;
            grid-column: span 2;

            .obituary-card {
              display: block;
              overflow: hidden;
              border-radius: $border-radius-sm;
              border: 1px solid $secondary-dark;

              .obituary-featured-img {
                img {
                  object-fit: cover;
                  transition: transform .25s ease-in-out;
                  height: 448px;
                  width: 100%;
                }
              }

              .obituary-card-details {
                position: relative;
                height: 72px;
                margin-top: -76px;
                flex-shrink: 0;
                border-radius: 0px 0px $border-radius-sm $border-radius-sm;
                padding: 0 25px;
                color: $secondary-lightest;
                display: flex;

                &.obituary-card-details-bg {
                  mix-blend-mode: multiply;
                  background-image: linear-gradient(90deg, rgba(90, 90, 91, 0.76) 50%, rgba(90, 90, 91, 0.00) 63.63%);
                }

                h2 {
                  font: normal normal 700 calc($base-font-size + 19px)/72px $font-primary;
                }

                h3 {
                  font: italic normal 400 calc($base-font-size + 8px)/72px $font-primary;
                  padding-left: 30px;
                }
              }
            }

            &:hover {
              transform: scale(0.97);
              transition: $allease-in-out;
            }
          }

          .premium-plus {
            grid-column: span 2;
            display: inline-flex;
            align-items: center;
            border-radius: $border-radius-sm;
            @include widthAndHeight(899px, 360px, 0px, 0px, relative, 1);

            .premium-plus-card {
              display: block;
              overflow: hidden;
              height: 360px;
              width: 395px;
              border-radius: $border-radius-sm 0px 0px $border-radius-sm;
            }

            .premium-plus-featured-img {
              display: table-cell;
              height: 360px;
              width: 395px;
              position: relative;
              text-align: center;
              vertical-align: middle;

              img {
                mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                object-fit: contain;
                transition: transform .25s ease-in-out;
                width: 100%;
                height: 100%;
              }
            }

            .premium-plus-card-details {
              @include widthAndHeight(501px, 360px, 0px, -1px, relative, 1);
              background: $primary 0% 0% no-repeat padding-box;
              margin: 0px;
              border-radius: 0px $border-radius-sm $border-radius-sm 0px;
              padding: 52px 20px 20px 32px;

              .premium-plus-container {
                color: $white;
                opacity: 1;
                margin: 0px;

                h2 {
                  @include widthAndHeight(auto, null, 0px, 0px, null, 1);
                  font: normal normal 600 calc($base-font-size + 18px)/46px $font-secondary;
                  overflow: hidden;
                  padding-top: 6px;
                  line-height: 48px;
                  @include text-ellipsis;
                }

                h3 {
                  @include widthAndHeight(auto, 40px, 0px, 0px, null, 1);
                  font: normal normal 400 calc($base-font-size + 11px)/27px $font-secondary;
                  padding-top: 10px;
                }

                span {
                  @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                  display: flex;
                  align-items: center;
                  margin: 25px 0px 20px 0;

                  img {
                    width: 22px;
                    height: 22px;
                    border-radius: unset;
                    filter: invert(0%) sepia(227%) saturate(1%) hue-rotate(-29deg) brightness(183%) contrast(105%);
                    margin: 0px 10px 0 0;
                  }

                  p {
                    @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                    font: normal normal normal #{$base-font-size}/27px $font-secondary;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }

                .premium-plus-date-listed {
                  img {
                    width: 20px;
                    height: 22px;
                    margin: 0 12px 0 2px;
                  }
                }

                .advertise-poster {
                  @include widthAndHeight(103px, 45px, null, null, absolute, null);
                  display: inline-block;
                  right: 20px;
                  bottom: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                    filter: none;
                    border-radius: unset;
                    object-fit: contain;
                  }
                }
              }
            }

            &:hover {
              transform: scale(0.97);
              transition: $allease-in-out;
            }
          }

          .premium {
            grid-column: span 2;
            display: inline-flex;
            @include widthAndHeight(899px, 292px, 0px, 0px, relative, 1);

            .premium-card-img {
              display: block;
              overflow: hidden;
              height: 292px;
              border-radius: $border-radius-sm 0px 0px $border-radius-sm;
              border: 1px solid $secondary-dark;
              border-width: 1px 0px 1px 1px;
            }

            .featured-banner-img-card {
              display: table-cell;
              height: 292px;
              position: relative;
              text-align: center;
              vertical-align: middle;
              width: 336px;

              img {
                mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                object-fit: contain;
                transition: transform .25s ease-in-out;
                width: 100%;
                height: 100%;
              }
            }

            .premium-card {
              @include widthAndHeight(560px, 292px, 0px, 0px, relative, 1);
              background: $secondary 0% 0% no-repeat padding-box;
              margin: 0px;
              border-radius: 0px $border-radius-sm $border-radius-sm 0px;
              padding: 43px 20px 20px 32px;
              border: 1px solid $secondary-dark;
              border-width: 1px 1px 1px 0px;

              .premium-card-container {
                color: $text-dark;
                opacity: 1;
                margin: 0px;

                h2 {
                  @include widthAndHeight(auto, null, 0px, 0px, null, 1);
                  font: normal normal 600 calc($base-font-size + 14px)/42px $font-secondary;
                  @include text-ellipsis;
                  padding-top: 6px;
                }

                h3 {
                  @include widthAndHeight(auto, 40px, 0px, 0px, null, 1);
                  font: normal normal 500 calc($base-font-size + 11px)/27px $font-secondary;
                  padding-top: 5px;
                  margin-top: 5px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                span {
                  @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                  display: flex;
                  align-items: center;
                  margin: 25px 0px 20px 0;

                  img {
                    width: 21px;
                    height: 20px;
                    border-radius: unset;
                    margin: 0px 10px 0px 0px;
                    filter: invert(26%) sepia(2%) saturate(438%) hue-rotate(227deg) brightness(-86%) contrast(71%)
                  }

                  p {
                    @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                    font: normal normal normal #{$base-font-size}/27px $font-secondary;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }

                .premium-date-listed {
                  img {
                    width: 17px;
                    height: 18px;
                    margin: 0 11px 0 3px;
                  }
                }

                .advertise-poster {
                  @include widthAndHeight(103px, 45px, 225px, 435px, absolute, 1);
                  display: inline-block;

                  img {
                    filter: none;
                    border-radius: unset;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            &:hover {
              transform: scale(0.97);
              transition: $allease-in-out;
            }
          }

          .standard {
            grid-column: span 1;

            .card-standard {
              @include widthAndHeight(434px, 257px, 0px, 0px, relative, 1);
              background: $floral-white 0% 0% no-repeat padding-box;
              border: 1px solid $secondary-dark;
              border-radius: $border-radius-sm;

              .card-container {
                color: $text-dark;
                margin: 0px;
                padding: 29px 20px;

                h2 {
                  @include widthAndHeight(100%, null, null, null, null, 0.73);
                  font: normal normal 600 calc($base-font-size + 8px)/43px $font-secondary;
                  padding-top: 6px;
                  margin-bottom: 10px;
                  @include text-ellipsis;
                }


                h3 {
                  @include widthAndHeight(auto, 40px, null, null, null, 0.73);
                  font: normal normal 400 calc($base-font-size + 4px)/27px $font-secondary;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  margin-top: 10px;
                }

                span {
                  @include widthAndHeight(auto, 25px, null, null, null, 0.73);
                  display: flex;
                  align-items: center;
                  margin: 0px 0px 20px 0;

                  img {
                    width: 22px;
                    height: 21px;
                    border-radius: unset;
                    margin: 4px 6px 0px 0px;
                    filter: invert(26%) sepia(2%) saturate(438%) hue-rotate(227deg) brightness(-86%) contrast(71%)
                  }

                  p {
                    @include widthAndHeight(auto, 25px, null, null, null, null);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font: normal normal normal #{$base-font-size}/27px $font-secondary;
                  }
                }

                .date-listed {
                  margin-bottom: 0px;

                  img {
                    width: 18px;
                    height: 18px;
                    margin: 2px 7px 0px 2px;
                  }
                }

                .advertise-poster {
                  img {
                    @include widthAndHeight(103px, 45px, 192px, 311px, absolute, 1);
                    filter: none;
                    border-radius: unset;
                    object-fit: contain;
                  }
                }
              }

              &:hover {
                transform: scale(0.97);
                transition: $allease-in-out
              }
            }
          }

          .card-dynamic {
            @include widthAndHeight(434px, 257px, 0px, 0px, null, 1);
            float: right;
            align-items: center;
            box-sizing: border-box;

            .card-learnMore {
              @include widthAndHeight(434px, 257px, 0px, 0px, relative, 1);
              background: $primary-darkest 0% 0% no-repeat padding-box;
              border: 4px solid $primary;
              border-radius: $border-radius-sm;

              .card-learnMore-container {
                color: $text-dark;
                opacity: 1;
                text-align: center;
                padding: 8% 0px;

                .card-learnMore-image {
                  text-align: center;
                  display: $block;
                  overflow: hidden;

                  .tile-img {
                    height: 82px;
                    margin: 10px auto;
                    width: 296px;

                    img {
                      height: 100%;
                      width: 100%;
                    }
                  }
                }

                .learn-more-txt {
                  height: 58px;
                  font: normal normal 600 #{$base-font-size}/27px $font-secondary;
                  padding: 0px 48px;
                  color: $text-dark;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  overflow: hidden;
                  margin: 0px;
                }

                .learn-more-btn {
                  width: 296px;
                  height: 53px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  color: $white;
                  margin: $base-margin + 12 auto;
                  border-radius: 34px;
                  background: $primary 0% 0% no-repeat padding-box;
                  font: normal normal 600 #{$base-font-size}/27px $font-secondary;

                  &:hover {
                    background-color: $primary-light;
                  }
                }
              }
            }

            &:hover {
              transform: scale(0.97);
              transition: $allease-in-out
            }
          }

          @extend .no-results-found;
        }
      }
    }
  }

  #pagination {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 342px;
  }

  .funeral-listing-ad {
    margin: 0 596px 37px 596px;

    .funeral-ifram-container {
      background-color: $background-pink;
      height: 90px;
      width: 728px;
    }
  }

  #footer {
    footer {
      position: relative;
      top: 0px;
    }
  }
}


//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  #funeral-listing-notices {
    width: $breakpoint-lg;
    margin: 0px auto;

    .funeral-listing-flex {
      grid-gap: 34px;
      position: none;

      .right-column {
        margin: 34px 0 0 0px;
      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .funeral_listing_wrapper {
    #funeral-listing-notices {
      width: $breakpoint-lg;
      margin: 0 auto;

      .funeral-listing-flex {
        gap: 20px;
        margin: 34px 16px;

        .left-column {
          margin: 0px;
        }

        .right-column {
          margin: 0px;

          .funeral-notices-list {
            .funeral-container {
              .card-dynamic {
                .card-learnMore {
                  .card-learnMore-container {
                    .learn-more-btn {
                      margin: $base-margin + 8 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// When tablet or smaller <1280px
@include for-lg-down {
  .funeral_listing_wrapper .funeral-listing-flex .right-column .funeral-notices-list .funeral-container {
    grid-template-columns: 1fr;

    .obituary {
      height: 406px;
      grid-column: span 1;

      .obituary-card {
        .obituary-featured-img {
          img {
            height: 406px;
          }
        }

        .obituary-card-details {
          height: 89px;
          margin-top: -94px;
          flex-direction: column;
          padding-left: 20px;

          h2 {
            font-size: calc($base-font-size + 14px);
            line-height: calc($base-font-size + 14px);
            height: 100%;
            display: flex;
            align-items: center;
          }

          h3 {
            font-size: calc($base-font-size + 8px);
            line-height: 0;
            padding-left: 0;
            height: 50%;
          }
        }
      }
    }

    .premium-plus {
      grid-column: span 1;
    }

    .premium {
      grid-column: span 1;
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .funeral_listing_wrapper {

    .funeral-listing-ad {
      margin: 0 20px 37px;
    }

    #pagination {
      margin-left: 0px;
      margin-top: 20px;
    }

    #funeral-listing-notices {
      width: $breakpoint-md;
      margin: 0 auto;

      .funeral-listing-flex {
        margin: 25px 27px;
        grid-gap: 26px;

        .left-column {
          margin: 0px;

          #funeral-desktop-ad {
            .container {
              @include widthAndHeight(317px, auto, 0px, 0px, nul, 1);
            }
          }
        }

        .right-column {
          width: 397px;
          margin: 0px;

          .funeral-notices-list {
            .funeral-container {
              width: 371px;

              .fn-header {
                &:not(:has(.search-result-daterange)) {
                  .badge-pill {
                    width: 100%;
                    grid-column: 1 / -1;
                  }
                }

                h1 {
                  font-size: 30px;
                }

                .search-result-daterange {
                  grid-column: span 1;
                }

                .heading-separator {
                  grid-row: 3;
                }

                .badge-pill {
                  grid-column: span 1;
                  height: 18px;
                  margin-top: -10px;
                  border: none;

                  justify-content: flex-end;
                }

                .fn-sort-options-container {
                  grid-column: 1 / -1;
                  width: 100%;
                  justify-content: space-between;
                  gap: 20px;
                }
              }

              .premium-plus {
                display: flex;
                flex-direction: column;
                @include widthAndHeight(371px, auto, 0px, 0px, relative, 1);

                .premium-plus-card {
                  width: 371px;
                  height: 237px;
                  border-radius: $border-radius-sm $border-radius-sm 0 0px;
                  border: 1px solid $primary;
                  border-width: 1px 1px 0px 1px;
                }

                .premium-plus-featured-img {
                  width: 371px;
                  height: 237px;
                }

                .premium-plus-card-details {
                  @include widthAndHeight(375px, auto, -1px, 0px, relative, 1);
                  border-radius: 0px 0px $border-radius-sm $border-radius-sm;
                  padding: 20px 10px 10px 10px;
                  border: 1px solid $primary;
                  border-width: 0px 1px 1px 1px;

                  .premium-plus-container {
                    h2 {
                      @include widthAndHeight(auto, null, 20px, 10px, null, 1);
                      font: normal normal 600 calc($base-font-size + 10px)/27px $font-secondary;
                      padding-top: 6px;
                    }

                    h3 {
                      @include widthAndHeight(auto, 25px, 10px, 10px, null, 1);
                      font: normal normal 600 #{$base-font-size}/27px $font-secondary;
                      overflow: none;
                      padding-top: 2px;
                      margin-top: 8px;
                    }

                    span {
                      @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                      margin: 15px 0px 20px;

                      img {
                        width: 23px;
                        height: 22px;
                        border-radius: unset;
                        margin: 0px 12px 0 0px;
                        filter: invert(0%) sepia(227%) saturate(1%) hue-rotate(-29deg) brightness(183%) contrast(105%)
                      }

                      p {
                        @include widthAndHeight(auto, 23px, 0px, 0px, null, 1);
                        font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;
                      }
                    }

                    .premium-plus-date-listed {
                      img {
                        width: 18px;
                        height: 20px;
                        margin: 0px 15px 0 3px;
                      }
                    }

                    .advertise-poster {
                      @include widthAndHeight(103px, 45px, 142px, 250px, absolute, 1);
                      display: inline-block;

                      img {
                        filter: none;
                        border-radius: unset;
                        object-fit: contain;
                      }
                    }
                  }
                }
              }

              .premium {
                display: flex;
                flex-direction: column;
                @include widthAndHeight(371px, auto, 0px, 0px, relative, 1);

                .premium-card-img {
                  height: 202px;
                  border-radius: $border-radius-sm $border-radius-sm 0px 0px;
                  border: 1px solid $secondary-dark;
                  border-width: 1px 1px 0px 1px;
                }

                .featured-banner-img-card {
                  width: 371px;
                  height: 202px;
                  border-radius: $border-radius-sm $border-radius-sm 0 0px;
                }

                .premium-card {
                  @include widthAndHeight(371px, auto, -1px, 0px, relative, 1);
                  border-radius: 0px 0px $border-radius-sm $border-radius-sm;
                  padding: 20px 10px 10px 10px;
                  border: 1px solid $secondary-dark;
                  border-width: 0px 1px 1px 1px;

                  .premium-card-container {
                    h2 {
                      @include widthAndHeight(305, null, 0px, 0px, null, 1);
                      font: normal normal 600 calc($base-font-size + 10px)/27px $font-secondary;
                      padding-top: 0px;
                    }

                    h3 {
                      @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                      font: normal normal 600 #{$base-font-size}/27px $font-secondary;
                      overflow: none;
                      padding-top: 0px;
                      margin-top: 0px;
                    }

                    span {
                      @include widthAndHeight(auto, 25px, 0px, 0px, null, 1);
                      margin: 15px 0px 20px 0px;

                      img {
                        width: 23px;
                        height: 22px;
                        border-radius: unset;
                        margin: 0px 12px 0 0px;
                        filter: invert(26%) sepia(2%) saturate(438%) hue-rotate(227deg) brightness(-86%) contrast(71%)
                      }

                      p {
                        @include widthAndHeight(auto, 23px, 0px, 0px, null, 1);
                        font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;
                      }
                    }

                    .premium-date-listed {
                      img {
                        width: 18px;
                        height: 20px;
                        margin: 0px 15px 0 3px;
                      }
                    }

                    .advertise-poster {
                      @include widthAndHeight(103px, 45px, 135px, 250px, absolute, 1);
                      display: inline-block;

                      img {
                        filter: none;
                        border-radius: unset;
                        object-fit: contain;
                      }
                    }
                  }
                }
              }

              .standard {
                @include widthAndHeight(371px, auto, 0px, 0px, null, 1);
                grid-gap: 30px;
                display: grid;
                grid-template-columns: auto;
                overflow: hidden;

                .card-standard {
                  @include widthAndHeight(371px, 198px, 0px, 0px, null, 1);
                  border-radius: $border-radius-sm;

                  .card-container {
                    padding: 15px 10px;
                    @include widthAndHeight (370px, 198px, 0px, 0px, null, 1);

                    h2 {
                      @include widthAndHeight(100%, null, 0px, 0px, null, 0.73);
                      font: normal normal 600 calc($base-font-size + 4px)/32px $font-secondary;
                    }

                    h3 {
                      @include widthAndHeight(auto, auto, 0px, 0px, null, 0.73);
                      font: normal normal 400 #{$base-font-size}/27px $font-secondary;
                      margin: 5px 0px;
                    }

                    span {
                      @include widthAndHeight(auto, 23px, 0px, 0px, null, 0.73);
                      display: flex;
                      align-items: center;
                      margin: 0px 0px 15px 0;

                      img {
                        width: 23px;
                        height: 22px;
                        margin: 4px 13px 0px 0px;
                        border-radius: unset;
                        filter: invert(26%) sepia(2%) saturate(438%) hue-rotate(227deg) brightness(-86%) contrast(71%)
                      }

                      p {
                        @include widthAndHeight(auto, 23px, 0px, 10px, null, null);
                        font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;
                      }
                    }

                    .date-listed {
                      margin-bottom: 0px;

                      img {
                        width: 18px;
                        height: 20px;
                        margin: 2px 13px 0 4px;
                      }

                      p {
                        font: normal normal normal calc($base-font-size - 2px)/27px $font-secondary;
                      }
                    }

                    .advertise-poster {
                      display: inline-block;

                      img {
                        @include widthAndHeight(103px, 45px, 140px, 250px, absolute, 1);
                        filter: none;
                        border-radius: unset;
                        object-fit: contain;
                      }
                    }
                  }
                }
              }

              .card-dynamic {
                @include widthAndHeight(371px, 239px, null, null, null, 1);
                float: unset;

                .card-learnMore {
                  @include widthAndHeight(371px, 239px, 0px, 0px, relative, 1);
                  background: $primary-darkest 0% 0% no-repeat padding-box;
                  border: 4px solid $primary;
                  border-radius: $border-radius-sm;

                  .card-learnMore-container {
                    color: $text-dark;
                    opacity: 1;

                    .card-learnMore-image {
                      .tile-img {
                        img {
                          height: 100%;
                          width: 100%;
                          margin: 0px auto;
                        }
                      }
                    }

                    .learn-more-txt {
                      width: auto;
                      height: 58px;
                      font: normal normal 600 #{$base-font-size}/27px $font-secondary;
                      padding: 0px 31px 0px 48px;
                      color: $text-dark;
                    }

                    .learn-more-btn {
                      width: 237px;
                      height: 51px;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      color: $white;
                      margin: 10px auto;
                      border-radius: 34px;
                      background: $primary 0% 0% no-repeat padding-box;
                      font: normal normal 600 #{$base-font-size}/27px $font-secondary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// for screen width less than 768 px
@include for-md-down {
  .funeral_listing_wrapper {
    .no-results-found {
      margin-bottom: 40px;
    }

    .funeral-listing-ad {
      display: block;
    }

    .funeral-listing-flex {
      display: block;

      .left-column {
        display: none;
        margin: 0;
      }

      .right-column {
        float: none;
        width: $breakpoint-sm;
        margin: 23px 0 0 23px;

        .funeral-notices-list {
          .funeral-container {
            width: 382px;
            margin-top: 25px;
            position: relative;

            flex-direction: column;

            .fn-header {
              row-gap: 20px;

              &:not(:has(.search-result-daterange)) {
                .badge-pill {
                  grid-column: 1 / -1;
                  justify-content: center;
                }
              }

              h1 {
                text-align: center;
              }

              .search-result-daterange {
                grid-column: span 1;
                margin-top: 0;
              }

              .heading-separator {
                display: none;
              }

              .badge-pill {
                grid-column: span 1;
                justify-content: flex-end;
                width: 100%;
                height: 18px;
                border: none;
              }

              .fn-sort-options-container {
                grid-column: 1 / -1;
                width: 100%;
                justify-content: center;

                .fn-sort-icon-groups {
                  width: 100%;
                  height: 43px;
                  overflow: hidden;
                  border-radius: 32px;
                  border: 1px solid $primary;

                  gap: 0;
                  justify-content: space-between;

                  .calender-sort-icon,
                  .calender-atoz-sort-icon,
                  .calender-ztoa-sort-icon {
                    flex: 1;
                    border-radius: 0;
                    border: none;
                    border-right: 1px solid $primary;
                  }

                  .calender-ztoa-sort-icon {
                    border: none;
                  }
                }

                .daterange-dropdown {
                  display: none;
                }
              }
            }

            .premium-plus {
              flex-direction: column;
              @include widthAndHeight(382px, auto, 0, 0, relative, 1);

              .premium-plus-card {
                width: 382px;
                height: 237px;
                border-radius: $border-radius-sm $border-radius-sm 0 0;
                border: 1px solid $primary;
                border-width: 1px 1px 0 1px;
              }

              .premium-plus-featured-img {
                width: 382px;
                height: 237px;
              }

              .premium-plus-card-details {
                @include widthAndHeight(382px, auto, -1px, 0, relative, 1);
                border-radius: 0 0 $border-radius-sm $border-radius-sm;
                padding: 20px 10px 10px 10px;
                border: 1px solid $primary;
                border-width: 0 1px 1px 1px;

                .premium-plus-container {
                  h2 {
                    @include widthAndHeight(auto, null, null, 0, null, 1);
                    padding-top: 0;
                    font: 600 calc($base-font-size + 4px)/32px $font-secondary;
                  }

                  h3 {
                    @include widthAndHeight(auto, auto, 0, 0, null, 1);
                    padding-top: 10px;
                    font: 400 #{$base-font-size}/27px $font-secondary;
                  }

                  span {
                    @include widthAndHeight(auto, 25px, 0, 0, null, 1);
                    margin: 10px 0 20px 0;

                    img {
                      width: 23px;
                      height: 22px;
                      margin: 0 14px 0 0;
                    }

                    p {
                      @include widthAndHeight(auto, 25px, 0, 0, null, 1);
                      font: normal calc($base-font-size - 2px)/25px $font-secondary;
                    }
                  }

                  .premium-plus-date-listed {
                    img {
                      width: 18px;
                      height: 20px;
                      margin: -3px 15px 0 3px;
                    }
                  }

                  .advertise-poster {
                    @include widthAndHeight(103px, 45px, null, null, absolute, 1);
                    bottom: 16px;
                    right: 16px;
                    display: inline-block;

                    img {
                      filter: none;
                      object-fit: contain;
                    }
                  }
                }
              }
            }

            .premium {
              flex-direction: column;
              @include widthAndHeight(382px, auto, -1px, 0, relative, 1);

              .premium-card-img {
                height: 202px;
                border-radius: $border-radius-sm $border-radius-sm 0 0;
                border: 1px solid $secondary-dark;
                border-width: 1px 1px 0 1px;
              }

              .featured-banner-img-card {
                width: 382px;
                height: 202px;
              }

              .premium-card {
                @include widthAndHeight(382px, auto, 0, 0, relative, 1);
                padding: 15px 10px 10px;
                background: $secondary 0% 0% no-repeat padding-box;
                border-radius: 0px 0px $border-radius-sm $border-radius-sm;
                border: 1px solid $secondary-dark;
                border-width: 0 1px 1px 1px;

                .premium-card-container {
                  h2 {
                    @include widthAndHeight(auto, null, 0, 0, null, 1);
                    padding-top: 0;
                    font: 600 calc($base-font-size + 4px)/32px $font-secondary;
                  }

                  h3 {
                    @include widthAndHeight(auto, auto, 0, 0, null, 1);
                    padding-top: 10px;
                    font: 400 #{$base-font-size}/27px $font-secondary;
                  }

                  span {
                    @include widthAndHeight(auto, 25px, 0, 0, nulll, 1);
                    margin: 10px 0 20px 0px;

                    img {
                      width: 23px;
                      height: 22px;
                      margin: 0 14px 0 0;
                    }

                    p {
                      @include widthAndHeight(auto, 25px, 0, 0, null, 1);
                      font: normal calc($base-font-size - 2px)/25px $font-secondary;
                    }
                  }

                  .premium-date-listed {
                    img {
                      width: 18px;
                      height: 20px;
                      margin: -3px 15px 0 3px;
                    }
                  }

                  .advertise-poster {
                    @include widthAndHeight(103px, 45px, null, null, absolute, 1);
                    display: inline-block;
                    bottom: 16px;
                    right: 16px;

                    img {
                      filter: none;
                      object-fit: contain;
                    }
                  }
                }
              }
            }

            .standard {
              @include widthAndHeight(382px, auto, 0, 0, null, 1);
              display: grid;
              grid-template-columns: auto;

              .card-standard {
                @include widthAndHeight(382px, 198px, 0, 0, null, 1);
                border-radius: $border-radius-sm;
                overflow: hidden;

                .card-container {
                  @include widthAndHeight (370px, 198px, 0, 0, null, 1);
                  padding: 15px 10px;

                  h2 {
                    @include widthAndHeight(100%, null, 0, 0, null, 0.73);
                    min-height: 39px;
                    font: 600 calc($base-font-size + 4px)/32px $font-secondary;
                  }

                  h3 {
                    @include widthAndHeight(auto, auto, 0, 0, null, 0.73);
                    font: 400 #{$base-font-size}/27px $font-secondary;
                    margin: 5px 0;
                  }

                  span {
                    @include widthAndHeight(auto, 23px, 0, 0, null, 0.73);
                    display: flex;
                    align-items: center;
                    margin: 0 0 15px 0;

                    img {
                      width: 23px;
                      height: 22px;
                      border-radius: unset;
                      margin: 4px 12px 0 6px;
                      filter: invert(26%) sepia(2%) saturate(438%) hue-rotate(227deg) brightness(-86%) contrast(71%)
                    }

                    p {
                      @include widthAndHeight(auto, 23px, 0, 10px, null, null);
                      font: normal calc($base-font-size - 2px)/27px $font-secondary;
                    }
                  }

                  .date-listed {
                    margin-bottom: 0;

                    img {
                      width: 18px;
                      height: 20px;
                      margin: 0 12px 0 10px;
                    }
                  }

                  .advertise-poster {
                    display: inline-block;

                    img {
                      @include widthAndHeight(103px, 45px, null, null, absolute, 1);
                      bottom: 16px;
                      right: 16px;
                      filter: none;
                      border-radius: unset;
                      object-fit: contain;
                    }
                  }
                }
              }
            }

            .card-dynamic {
              @include widthAndHeight(382px, 239px, null, null, null, 1);
              align-items: center;
              float: unset;

              .card-learnMore {
                @include widthAndHeight(382px, 239px, 0, 0, relative, 1);

                .card-learnMore-container {
                  color: $text-dark;
                  opacity: 1;

                  .card-learnMore-image {
                    .tile-img img {
                      height: 100%;
                      width: 100%;
                    }
                  }

                  .learn-more-txt {
                    width: auto;
                    height: 58px;
                    padding: 0 48px;
                    color: $text-dark;
                    font: 600 #{$base-font-size}/27px $font-secondary;
                  }

                  .learn-more-btn {
                    width: 248px;
                    height: 51px;
                    margin: 10px auto;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                    border-radius: 34px;
                    background: $primary;
                    font: 600 #{$base-font-size}/27px $font-secondary;
                  }
                }
              }
            }
          }
        }
      }
    }

    #pagination {
      margin-left: 0;

      .pagination ul {
        position: relative;
        left: 0;
      }
    }

    #navbar-search-filter .navbar-search-filter .container {
      display: none;
    }
  }
}

// for screen width less than 428px
@include for-sm-down {
  .funeral_listing_wrapper {
    width: 100%;

    .funeral-listing-flex {
      width: 100%;

      .right-column {
        width: 100%;
        margin: 0;

        .funeral-notices-list .funeral-container {
          width: 95%;
          margin: 25px auto 0 auto;

          .fn-header {
            .search-result-daterange {
              font-size: 14px;
            }
          }

          .obituary .obituary-featured-img img {
            width: 100%
          }

          .premium-plus {
            width: 100%;

            .premium-plus-card {
              width: 100%;
            }

            .premium-plus-featured-img {
              width: 393px;
            }

            .premium-plus-card-details {
              width: 100%;
            }
          }

          .premium {
            width: 100%;

            .featured-banner-img-card {
              display: block;
              width: 100%;
            }

            .premium-card {
              width: 100%;
            }
          }

          .standard {
            width: 100%;

            .card-standard {
              width: 100%;

              .card-container {
                width: 100%;
              }
            }
          }

          .card-dynamic {
            width: 100%;

            .card-learnMore {
              width: 100%;

              .card-learnMore-container {
                padding: 8% 0;
              }
            }
          }
        }
      }
    }
  }
}