// @TODO: Once RNN is gone look at cleaning this up to allow .alert self standing
.alertWrapper {
  position: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin-top: $base-margin;
  //@include overlay;

  .alert {
    height: 100%;
   // max-width: $max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
    padding-left: 30px;

    a {
      color: $link-color;
    }
  }

  .close {
    top: 0px;
    right: 0px;
    color: $grey-medium;
    transition: all .2s;
    width: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}

.alert-success {
  background-color: $green-light;
  margin-bottom: 18px;
}

.alert-error {
  background-color: $alertBackground;
  color: $error-colour;
  border-radius: 4px;
  margin-bottom: 18px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  align-content: center;
  .close {
    font-size: 20px;
    font-weight: 700;
    color: $error-colour;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .alert-heading {
    color: $error-colour;
    text-align: left;
    width: 100%;
    margin-top: $base-margin*2;
    font-size: 20px;
  }

  ul {
    list-style: disc;
    margin-left: 10px;
    padding-left: 20px;
    li {
      color: $text-dark;
      margin: 3px;
    }
  }
}

.alert-block {
  padding: 12px;
  width: 860px;
}
 


//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {

  .alert-block { 
    width: 380px;
  } 
  .alert-error .alert-heading { font-size: 18px; }
  .alertWrapper .close { margin: 0px; }

 } 

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {

  .alert-block { 
    width: 380px;
  }
  .alert-error {
    .alert-heading { font-size: 16px; }
    ul li, ul .link { font-size: 14px; }
  }

 } 


//When the screen width < 428px, render the 428px display
@include for-sm-down {

  .alert-block { 
    width:  380px;
  } 

  .alert-error {
    .alert-heading { font-size: 16px; }
    ul li, ul .link { font-size: 14px; }
  }

 }