section {
  height: auto;
}
.about-us {
  @include widthAndHeight ($breakpoint-xl, auto, null, null, null, null);

  .container-flex-box {
    display: $flex;
    flex-direction: row;

    .about-us-content {
      @include widthAndHeight(960px, 552px, null, null, null, null);
      margin-left: auto;
      .about-us-wrap{
        margin: inherit;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 4%;
        max-width: 504px;
        h2 {
          text-align: left;
          font: normal normal bold 40px/53px $font-primary;
          letter-spacing: 0.12px;
          color: $text-color;
          margin-top: $base-margin * 6 + 3;
        }

        p {
          font: normal normal 300 18px/33px $font-secondary;
          text-align: left;
          letter-spacing: 0.05px;
          color: $text-color;
          margin: 15px auto 25px;
          word-wrap: break-word;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        a {
          width: 247px;
          text-align: center;
          border-radius: 34px;
          margin-right: 0px;
          font-family: $font-secondary;

        }
      }
    }

    .about-us-content .about-us-wrap a:hover {
        background-color:$primary-light;
    }

    .about-us-banner {
      @include widthAndHeight(960px, 552px, null, null, null, null);
      background-image: url("./images/about-us.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 36%;
      height: 552px;
    }

  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  .about-us {
    width: 100%;
    .container-flex-box{
      .about-us-banner {
        width: 50%;
      }
      .about-us-content{
        width: 50%;
        .about-us-wrap{
          margin-right: 117px;

          p{
            width: 504px;
            margin-right: 117px;
          }
        }
      }
    }
  }
}





 //When the screen width < 1280px and >= 768px, render the 768px display
 @include for-lg-only {
  .about-us {
    @include widthAndHeight(100%, auto, null, null, null, null);

    .container-flex-box {
      display: $flex;
      flex-direction: row;

      .about-us-content {
        @include widthAndHeight(50%, 438px, null, null, null, 1);
        float: left;
        .about-us-wrap{
          margin: 0px 26px 0px auto;
          h2 {
            text-align: left;
            font: normal normal bold 30px/40px $font-primary;
            letter-spacing: 0.09px;
          }

          p {
            text-align: left;
            font: normal normal 300 16px/27px $font-secondary;
            letter-spacing: 0.05px;
            overflow: hidden;
          }

          a {
            border-radius: 34px;
            margin-top: 0px;
            text-align: center;
          }
        }
      }


      .about-us-banner {
        @include widthAndHeight(50%, 438px, null, null, null, null);
        background-image: url("./images/about-us.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 40%;

      }

    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .about-us {
    @include widthAndHeight(100%, 552px, null, null, null, null);

    .container-flex-box {
      display: $flex;
      flex-direction: row;

      &>* {
        width: 50%;
      }

      .about-us-content {
        @include widthAndHeight(50%, 552px, null, null, null, null);
        float: left;
        .about-us-wrap{
          margin: 0px 115px 0px auto;
          h2 {
            text-align: left;
            font: normal normal bold 40px/53px $font-primary;
            letter-spacing: 0.12px;
            margin-top:$base-margin * 6 + 3;
          }

          p {
            text-align: left;
            font: normal normal 300 18px/33px $font-secondary;
            letter-spacing: 0.05px;

          }

          a {
            border-radius: 34px;
            margin-top: 0px;
            text-align: center;
          }
        }
      }


      .about-us-banner {
        @include widthAndHeight(50%, 552px, null, null, null, null);
        background-image: url("./images/about-us.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 36%;
        height: 552px;
        opacity: 1;
      }

    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .about-us {
    @include widthAndHeight (100%, auto, null, null, null, null);
    .container-flex-box {
      display: inline-flex;
      flex-direction: column;
      flex-flow: column-reverse;
      margin-bottom: 95px;
      .about-us-content {
        @include widthAndHeight($breakpoint-sm, 380px, 0px, 0px, relative, 1);
        .about-us-wrap{
          h2 {
            font: normal normal bold 32px/53px $font-primary;
            letter-spacing: 0.1px;
            color: $text-color;
            margin: 0px;
          }

          p {
            font: normal normal 300 16px/30px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
          }

          a {
            width: 382px;
            text-align: center;
          }
        }
      }

      .about-us-banner {
        @include widthAndHeight($breakpoint-sm, 364px, null, null, null, null);
        background-image: url("./images/about-us-mb.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 35%;
      }

    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .about-us {
    @include widthAndHeight (100%, auto, null, null, null, null);
    .container-flex-box {
      display: inline-flex;
      flex-direction: column;
      flex-flow: column-reverse;
      margin-bottom: 95px;
      .about-us-content {
        width: 100%;
        height: auto;
        .about-us-wrap{
          margin-left: 0px;
          h2 {
            font: normal normal bold 32px/53px $font-primary;
            letter-spacing: 0.1px;
            color: $text-color;
            margin: 0px;
          }

          p {
            font: normal normal 300 16px/30px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            overflow: hidden;
            display: block;
          }

          a {
            text-align: center;
            width:100%;
          }
        }
      }

      .about-us-banner {
        @include widthAndHeight(100%, 364px, null, null, null, null);
        background-image: url("./images/about-us-mb.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 35%;
      }

    }
  }
}
