.flowers-details {
  width: 100%;
  height: auto;
  overflow: hidden;

  .flowers-details-wrapper {
    overflow: hidden;
    width: $breakpoint-lg;
    margin: 38px auto 64px;
    display: $grid;
    grid-template-columns: auto auto;
    grid-gap: $gap + 15;

    .promotional-content {
      overflow: hidden;

      .ad-block.ad-mrec[data-google-query-id] {
        padding: $base-padding;
        margin-top: $base-margin * 4;
      }

      .ad-block.ad-mrec.fld-mobiles-ad[data-google-query-id] {
        display: none;
        padding: $base-padding;
        margin-bottom: $base-margin * 4;
      }

      .promotional-wrap {
        background: $pink-alt 0% 0% no-repeat padding-box;
        margin-bottom: 6px;
      }
    }

    .flowers-fixed-column {
      overflow: hidden;
      width: 317px;
      height: auto;
    }

    .flex-column {
      overflow: hidden;
      width: 896px;

      .listing-wrapper {
        overflow: hidden;
        background: $secondary;
        padding: 20px 25px;
        border-radius: $border-radius-sm;

        .mb-flowers-content {
          display: none;
        }

        .flex-flowers-listing {
          display: $grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: $gap + 13;

          .flower-details {
            overflow: hidden;
            width: 486px;
            height: 510px;
            position: relative;
            border-radius: $border-radius-sm;
            border: 1px solid $secondary-dark;
            transition: all .3s ease-in-out;
          }

          .flower-details>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 10px;
            transform: scale(1.05);
            transition: all .3s ease-in-out;
          }

          .flower-details:hover>img {
            transform: scale(1.15);
          }

          .flowers-content {
            display: $block;

            .flowers-title {
              margin: 0;
              font: bold calc(2 * $base-font-size - 2px)/normal $font-primary;
              color: $text-color;
            }

            .flowers-price-list {
              font: normal normal bold 29px/43px $font-secondary;
              letter-spacing: 0px;
              color: $text-dark;
              margin: 12px 0px 13px 0px;
            }

            .flowers-contents {
              color: $text-dark;
              line-height: 20px;
              letter-spacing: 0.6px;
              overflow: hidden;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }

            .flowers-to-cart {
              margin-top: $base-margin * 3;

              .flowers-cart {
                vertical-align: -6px;
                padding-right: $base-padding;
              }

              .flowers-btn {
                background: $primary;
                border: 2px;
                border-radius: 34px;
                height: 59px;
                padding: 16px 20px;
                text-align: center;
                width: 226px;
                color: $white;
                display: $block;
                font: normal normal 600 18px/27px $font-secondary
              }

              .flowers-btn:hover {
                background-color: $primary-light;
              }
            }

            .tab-flowers-price-list {
              display: none;
            }
          }

          .as-seen-up {
            background: $black;
            border-radius: 0 0 10px 10px;
            height: 65px;
            overflow: hidden;
            position: absolute;
            right: 25px;
            top: 0;
            width: 105px;
          }

          .as-seen-up img {
            position: relative;
            top: -18px;
            width: 100%;
          }
        }
      }
    }
  }
}

//When the screen width > 1920px, render the 1920px display
@include for-xl-up {}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .flowers-details {
    .flowers-details-wrapper {
      width: $breakpoint-lg - 30;

      .flowers-fixed-column {
        margin-left: 0px;
      }

      .flex-column {
        margin-right: 0px;
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .flowers-details {
    .flowers-details-wrapper {
      width: $breakpoint-md - 49;
      margin: 25px auto 19px;
      grid-gap: $gap + 6;

      .promotional-content {
        overflow: unset;
        margin: $base-margin * 3 0px;

        .ad-block.ad-mrec[data-google-query-id] {
          margin-bottom: 0px;
        }
      }

      .flowers-fixed-column {
        margin-left: 0px;
      }

      .flex-column {
        margin-right: 0px;
        width: 375px;

        .listing-wrapper {
          padding: $base-padding + 3px;

          .flex-flowers-listing {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 0px;

            .flower-details>img {
              top: 7px;
            }

            .as-seen-up {
              width: 104px;
              height: 52px;
            }

            .as-seen-up img {
              top: -25px;
            }

            .flower-details {
              width: 346px;
              height: 334px;
            }

            .flowers-content {
              margin-top: $base-margin + 3;
              display: $grid;
              grid-template-columns: auto auto;

              .flowers-title {
                width: 275px;
                margin-bottom: 10px;
                font: bold calc($base-font-size + 2px)/95px $font-primary;
                letter-spacing: 0.09px;
                line-height: 25px;
              }

              .flowers-price-list {
                display: none;
              }

              .flowers-contents {
                margin: 0px;
                grid-column: 1/ span 2;
                font: normal normal normal 16px/24px $font-secondary;
              }

              .flowers-to-cart {
                .flowers-btn {
                  font: normal normal 600 16px/25px $font-secondary;
                }
              }

              .tab-flowers-price-list {
                display: inline-block;
                font: bold calc($base-font-size + 2px)/30px $font-secondary;
                color: $text-dark;
                margin: 42px 0 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .promotion-wrapper {
    display: block;
    margin: 19px 0 44px;
    overflow: hidden;
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .flowers-details {
    .flowers-details-wrapper {
      grid-gap: 0px;
      width: $breakpoint-sm;
      grid-template-columns: 1fr;
      margin-bottom: $base-margin * 3;

      .promotional-content {
        grid-row: 2;
        margin-top: 20px;

        .ad-block.ad-mrec[data-google-query-id] {
          display: none;
        }

        .ad-block.ad-mrec.fld-mobiles-ad[data-google-query-id] {
          width: 382px;
          display: block;
        }
      }

      .flowers-fixed-column {
        width: 382px;
        margin: 0px auto;
        display: $grid;
      }

      .flex-column {
        margin: 0px auto;
        width: 382px;
        grid-row: 1;

        .listing-wrapper {
          padding: 0px;
          background: $secondary-lightest 0% 0% no-repeat padding-box;

          .mb-flowers-content {
            display: $block;

            .mb-flowers-title {
              margin: 0;
              font: bold calc($base-font-size + 2px)/28px $font-primary;
              letter-spacing: -0.03px;
              color: $text-color;
            }

            .mb-flowers-price-list {
              margin: 7px 0px;
              font: normal 20px/30px $font-secondary;
              color: $text-dark;
            }
          }

          .flex-flowers-listing {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 0px;

            .flower-details {
              width: 382px;
              height: 386px;
            }

            .flower-details>img {
              top: 8px;
            }

            .flowers-content {
              margin-top: 0px;
              display: $grid;
              grid-column: 1/ span 2;

              .flowers-title,
              .flowers-price-list {
                display: none;
              }

              .flowers-contents {
                margin-top: $base-margin;
              }

              .flowers-to-cart {
                .flowers-btn {
                  width: 382px;
                  font: normal normal 600 16px/25px $font-secondary;
                  margin-bottom: $base-margin * 3;
                }
              }
            }
          }
        }
      }
    }
  }

  .searchImgEnable {
    display: block;
  }

  standalone-partner {
    margin-bottom: 50px;
  }

}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .flowers-details {
    width: 100%;
    padding: 0 20px;

    .flowers-details-wrapper {
      grid-gap: 0px;
      width: 100%;
      grid-template-columns: 1fr;
      margin-bottom: $base-margin * 3;

      .promotional-content {
        grid-row: 2;
        margin-top: 20px;

        .ad-block.ad-mrec[data-google-query-id] {
          display: none;
        }

        .ad-block.ad-mrec.fld-mobiles-ad[data-google-query-id] {
          width: 100%;
          display: block;
        }
      }

      .flowers-fixed-column {
        width: 100%;
        margin: 0px auto;
        display: $grid;
      }

      .flex-column {
        margin: 0px auto;
        width: 100%;
        grid-row: 1;

        .listing-wrapper {
          padding: 0px;
          background: $secondary-lightest 0% 0% no-repeat padding-box;

          .mb-flowers-content {
            display: $block;

            .mb-flowers-title {
              margin: 0;
              font: bold calc($base-font-size + 2px)/28px $font-primary;
              letter-spacing: -0.03px;
              color: $text-color;
            }

            .mb-flowers-price-list {
              margin: 7px 0px;
              font: normal 20px/30px $font-secondary;
              color: $text-dark;
            }
          }

          .flex-flowers-listing {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 0px;

            .flower-details {
              width: 100%;
              max-height: 400px;
              min-height: 280px;
              height: unset;
            }

            .flower-details>img {
              top: 8px;
              object-fit: contain;
            }

            .flowers-content {
              margin-top: 0px;
              display: $grid;
              grid-column: 1/ span 2;

              .flowers-title,
              .flowers-price-list {
                display: none;
              }

              .flowers-contents {
                margin-top: $base-margin;
              }

              .flowers-to-cart {
                .flowers-btn {
                  width: 100%;
                  font: normal normal 600 16px/25px $font-secondary;
                  margin-bottom: $base-margin * 3;
                }
              }
            }
          }
        }
      }
    }
  }

  .searchImgEnable {
    display: block;
  }

  standalone-partner {
    margin-bottom: 50px;
  }
}