  @supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    html {
      overflow-x: hidden;
    }

    //When the screen width >= 1920px, render the 1920px display
    @include for-xl-up {
      .navbar-search-filter .container form .search-list .locationDropDown {
        border: 0px;
        border-radius: 0px;
      }

      .navbar-search-filter .container form .filter-list .funeral_publication {
        border: 0px;
      }
    }

    //When the screen width < 1280px and >= 768px, render the 768px display
    @include for-lg-only {
      .banner .search h1 {
        font: normal normal bold 24px/51px $font-primary
      }

      .recent-notices .container .recent-notice-grid .nav a {
        font: normal normal 599 16px/25px $font-secondary;
      }

      .featured-notices .containers .split-header a.btn {
        padding: 16px 18px 17px 37px;
      }

      .featured-obituaries .split-header a.btn {
        padding: 6px 18px 20px 44px;
      }

      section.featured-partners .col-2 .content h3 {
        width: 100%;
      }

      .featured-directors .containerfd .split-header h2 {
        width: 100%;
        height: auto;
        text-align: left;
        position: unset;
      }

      .recent-notices .container .recent-notice-grid .nav {
        width: 92%;
      }

      .recent-notices .container .headerSection h1 {
        width: 100%;
      }
    }

    //When the screen width < 1920px and >= 1280px, render the 1280px display
    @include for-xl-only {

      .recent-notices .container .headerSection h1 {
        width: 100%;
      }

      .featured-partners .col-2 .partners h3 {
        width: 249px;
      }

      .featured-notices .containers .bottom_link {
        top: 100px;
      }

      .featured-notices .bottom_link {
        width: 105px;
      }
    }

    //When the screen width < 768px and >= 428px, render the 428px display
    @include for-md-only {
      .featured-directors .containerfd .split-header .mb-content {
        font: normal normal 700 31px/43px $font-primary;
        width: 390px;
      }

      .mb-recent-notices .container h1 {
        width: 100%;
      }

      .mb-recent-notices .container .mb-grid .mb-nav {
        width: 92%;
      }

      .featured-partners .col2 .content .partners h3,
      section.featured-partners .container .content .partners h3 {
        width: 100%;
      }
    }

    //When the screen width < 428px, render the 428px display
    @include for-sm-down {
      .featured-directors .containerfd .split-header .mb-content {
        font: normal normal 700 31px/43px $font-primary;
        width: 390px;
      }

      .mb-recent-notices .container h1 {
        width: 100%;
      }

      .mb-recent-notices .container .mb-grid .mb-nav {
        width: 92%;
      }

      .featured-partners .col2 .content .partners h3,
      section.featured-partners .container .content .partners h3 {
        width: 100%;
      }
    }
  }