#grid-columns-dynamic{
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-flow: row;

}

.itemsbgchange {
  background-color: $dark-green;
  color:$secondary-lightest;
  border-radius: 10px;
  opacity: 1;
  a {
    h3, p {
      color:$white;
    }
  }
  hr {
    border: 1px solid $white;
  }
}

#grid-columns-dynamic .item:hover{
  color: $white;
  @extend .itemsbgchange;
}

#grid-columns .item:hover{
  @extend .itemsbgchange;
}

.menu-item {
  display: none;
  position: absolute;
  top: 36px;
  left: 0px;
  width: 1270px;
  border: 2px solid $dark-green;
  opacity: 1;
  z-index: 102;
  background-color: $white;
  .row {
    display: $flex;
    flex-wrap: wrap;
    padding: 15px;
    .column {
      flex: 50%;
      &.column-bg-color{
        background-color: $secondary-lightest;
      }
      .item {
        position: relative;
        width:561px;
        padding: 20px;
        opacity: 1;
        text-decoration: none;
        left: 26px;
        color: $white;
        a {
          text-decoration: none;
          &:hover {
            background: none;
          }
          h3 {
            text-align: left;
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: -0.21px;
            color: $text-color;
            opacity: 1;
          }

          p {
            position: relative;
            width: 528px;
            text-align: left;
            font: normal normal normal 16px/21px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            opacity: 1;
            margin-bottom: 0px;
          }
          hr {
            width: 100%;
            height: 0px;
            border: 1px $bold;
            opacity: 0.48;
            margin: 5px 0;
          }
        }

        .itemImg {
          img {
            width: 548px;
            height: 273px;
            border-radius: 9px;
            &:hover {
              transform: scale(1.01);
              transition: $allease-in-out;
            }
          }
          a {
            position: relative;
            padding:0px;
            width: 221px;
            text-align: left;
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: 0.08px;
            color: $text-color;
            opacity: 1;
          }
          p {
            position: relative;
            margin-top: 15px;
            width: 549px;
            text-align: left;
            font: normal normal normal 16px/21px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            opacity: 1;
          }
        }

        .article_link {
          a {
            position: relative;
            left: 400px;
            width: 120px;
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
            opacity: 1;
            text-decoration: none;
            padding-bottom: 5px;
            border-bottom: 2px solid $primary;
            line-height: 60px;
            &:hover {
              border-bottom: 2px solid $primary-light;
              color:$primary-light;
            }
          }
        }
      }
    }
  }
}

@include for-xl-only {
  .menu-item {
    display: none;
    position: absolute;
    top: 36px;
    left: 0px;
    width: 1248px;
    border: 2px solid $dark-green;
    opacity: 1;
    background-color: $white;
    .row {
      display: $flex;
      flex-wrap: wrap;
      .column {
      flex: 50%;
      .item {
        position: relative;
        width:541px;
        padding-left:20px;
        opacity: 1;
        text-decoration: none;
        left: 26px;
        top:5px;
        color: $white;
        a {
          text-decoration: none;
          &:hover {
            background: none;
            color: $black;
          }
          h3 {
            text-align: left;
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: -0.21px;
            color: $text-color;
            opacity: 1;
          }

          p {
            position: relative;
            width: 512px;
            text-align: left;
            font: normal normal normal 18px/21px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            opacity: 1;
          }
          hr {
            height: 0px;
            border: 1px $bold;
            opacity: 0.48;
          }
        }

        .itemImg {
          img {
            width: 480px;
            height: 240px;
          }
          a {
            position: relative;
            top: 10px;
            width: 221px;
            text-align: left;
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: 0.08px;
            color: $text-color;
            opacity: 1;
          }
          p {
            position: relative;
            width: 480px;
            text-align: left;
            font: normal normal normal 16px/21px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            opacity: 1;
          }
        }



        .article_link {
          a {
            left: 360px;
            width: 120px;
            color: $primary;
            font: normal normal 600 16px/25px $font-secondary;
          }
        }
      }
    }
    }


  }
}

@include for-lg-only{
  .menu-item {
    display: none;
    position: absolute;
    border: 2px solid $dark-green;
    opacity: 1;
    width: 714px;
    left: 0px;
    top: 36px;
    .row {
      display: $flex;
      padding: 0;
      .column {
        flex: 50%;
        padding: 10px;
        &.column-bg-color:not(.partner-services) {
          background-color: $secondary;
        }
        .item {
          align-items: baseline;
          display: flex;
          position: inherit;
          left:10px;
          width: 330px;
          padding: 10px;
          a {
            text-decoration: none;
            &:hover {
              background: none;
              color: $black;
            }
            p {
              position: relative;
              width: 100%;
              font: normal normal normal 14px/21px $font-secondary;
            }
            h3 {
              font: normal normal bold 20px/35px $font-primary;
            }
            hr {
              width: 310px;
            }
          }

          .itemImg {
            img {
              max-width: 310px;
              height: 169px;
            }
            a {
              max-width: 170px;
              top: 5px;
              font: normal normal bold 20px/35px $font-primary;
            }
            p {
              max-width: 320px;
              margin-top: 10px;
              margin-bottom:0px;
              font: normal normal normal 14px/21px $font-primary;
            }
          }

          .article_link {
            a {
              color: $primary;
              left: 185px;
              font: normal normal 600 16px/27px $font-secondary;
            }
          }
        }
      }
    }
  }
}


