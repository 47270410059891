section {
  height: auto;
}
section.featured-obituaries {
  background: $floral-white 0% 0% no-repeat padding-box;
  margin: 0 auto;
  overflow: hidden;

  .container {
    width: $breakpoint-xl;
    height: 880px;
    margin: 0;
  }

  .split-header {
    display: $flex;
    justify-content: space-between;
    margin-top: 120px;
    margin-left: 337px;
    align-items: center;


    h2 {
      text-align: left;
      font: normal normal bold 40px/53px $font-primary;
      letter-spacing: 0.12px;
      width: 311px;
      height: 53px;
      color: $text-color;
    }

    .split-header-right {
      padding-right: 0px;
      .btn {
        margin-right: 25px;
        font-family: $font-secondary;
      }
      .btn.primary{
        margin: 0px;
        font-family: $font-secondary;
      }


    }
  }
  .card-slide {
    margin-right: 22px;
    display: $grid;
    grid-gap: 22px;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(1,1fr);
    width: 517px;
  }

  .obituaries-list {
    display: $flex;
    margin-bottom: 80px;


    .card {
      width: 517px;
      height: 483px;
      background: $secondary 0% 0% no-repeat padding-box;
      margin-top: 0px;
      h4 {
        margin: 84px 23px 38px 23px;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis
      }
      p{
      padding: 0px 23px;
      word-wrap: break-word;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      width: 252px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;

      }
      .card-items {
        width: 258px;
        height: 483px;
        float: left;
        overflow: hidden;
      }
      .card-img{
        position: relative;
        width: 258px;
        height: 483px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        img {
          -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
          mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
          -o-object-fit: contain;
          object-fit: contain;
          transition: transform .25s ease-in-out;
          width: 100%;
          height: auto;
          z-index: 1;
          border-radius: 0px;

        }

      }
      .card-details {
        height: 483px;
        width: 242px;
        float: left;
        display: block;

        .card-title {
          max-height: 111px;
          text-align: center;
          font: normal normal bold 28px/37px $font-primary;
          letter-spacing: 0.08px;
          color: $text-color;
          opacity: 1;
        }

        .card-description {
          height: 225px;
          text-align: center;
          font: normal normal normal 18px/37px $font-secondary;
          letter-spacing: 0.05px;
          color: $text-color;
          opacity: 1;
        }
      }

    }
    .card:hover{
      .card-items .card-img > img{
        transform: scale(1.15);
        transition: $allease-in-out;
      }
    }
  }

  .space-line {
    border-bottom: 2px solid $secondary-dark;
    margin: 0 auto;
    width: 50%;
  }

  .split-header-mobile {
    display: none;
  }
}

/* Slider */
.slider {
  margin: 53px auto;
}
.card-initialized .card-slide {
  display: $grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 10px;
}
.card-slide {
  margin: 0px 10px;
}

.card-prev {
  width: 52px;
  height: 52px;
  background-image: url("./images/ArrowButton.svg");
}

.card-next {
  width: 52px;
  height: 52px;
  margin-bottom: 25px;
  transform: rotateZ(178deg) !important;
  right: 30%;
  background-image: url("./images/ArrowButton.svg");
}

.card-prev,
.card-next {
  background-color: transparent;
}

.card-prev:hover,
.card-next:hover {
  filter: opacity(0.7);
}

.card-prev:before,
.card-next:before {
  color: $black;
}

.card-slide {
  transition: all ease-in-out 0.3s;
}

.card-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.card-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.card-slider .card-track,
.card-slider .card-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.card-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .card-loading & {
    visibility: hidden;
  }
}

.card-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.card-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .card-initialized & {
    display: block;
  }

  .card-loading & {
    visibility: hidden;
  }

  .card-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.card-arrow.card-hidden {
  display: none;
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  section.featured-obituaries {
    width: 100%;
    .container {
      width: 100%;
      .split-header {
        width: 1248px;
        margin: 120px auto 0px;
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.featured-obituaries {
    margin: 0px;
    @include widthAndHeight($breakpoint-sm, 657px, null, null, null, null);
    background: $floral-white 0 0 no-repeat padding-box;

    .container {
      width: 100%;

      .split-header {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        margin-top: 50px;

        h2 {
          font: normal normal bold 30px/40px $font-primary;
          padding: 0px 0px 14px 23px;
          color: $text-dark;
        }

        .split-header-links {
          display: none;
        }
      }
    }

    .split-header-mobile {
      .btn {
        @include widthAndHeight(187px, 65px, 20px, 0px, relative, 1);
        border: 2px solid $primary;
        border-radius: 34px;
        opacity: 1;
        display: inline-block;
        margin: 2px;
        padding: 20px !important;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family:  $font-secondary;
        &::after {
          width: 160px;
          height: 25px;
          text-align: center;
          font: normal normal 600 16px/27px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }

      .btn.primary {
        @include widthAndHeight(187px, 65px, 20px, 0px, relative, 1);
        background: $primary 0% 0% no-repeat padding-box;
        border: 2px solid $primary;
        border-radius: 34px;
        opacity: 1;
        color: $white;
        padding: 20px !important;

        &::after {
          width: 160px;
          height: 25px;
          text-align: center;
          font: normal normal 600 16px/27px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }
    }

    .slider {
      margin: 0 auto;
    }

    .card-slide {
      margin: 0 8px;
      width:259px;
    }

    .space-line {
      display: none;
    }

    .card-prev {
      left: 5%;
      bottom: 135px;
      opacity: 1;
      z-index: 1;
    }

    .card-next {
      right: 5%;
      bottom: 135px;
      opacity: 1;
      z-index: 1;
    }

    .split-header-mobile {
      display: $flex;
       position: relative;
       left: 23px;
    }
    .card-list{
      padding: 0px;
    }
    .card-slide {
      margin-right: 22px;
      display: $grid;
      grid-gap: 22px;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(1,1fr);
      width: 244px;
    }
    .obituaries-list {
      display: $flex;


      .card {
        height: 347px;
        max-width: 244px;
        margin-right: 0px;
        width: 100%;
        position: relative;
        background: $secondary 0% 0% no-repeat padding-box;



        .card-details {
          position: absolute;
          top: 302px;
          width: 100%;
          height: 46px;
          z-index: 1;
          opacity: 0.8;
          padding: 10px;
          background: $black 0% 0% no-repeat padding-box;
          border-radius: 0px 0px 20px 20px;
          .card-title {
            font: normal normal 700 16px/25px $font-secondary;
            letter-spacing: 0px;
            color: $white;
            margin: 0px;
            -webkit-line-clamp: 1;
          }

          .card-description {
            display: none;
          }
        }
        .card-items {
          width: 244px;
          height: 347px;
          float: left;
          overflow: hidden;
        }

        .card-img{
          position: relative;
          width: 244px;
          height: 347px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          img {
            -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
            mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
            -o-object-fit: contain;
            object-fit: contain;
            transition: transform .25s ease-in-out;
            width: 100%;
            height: auto;
            z-index: 1;
            border-radius: 0px;

          }

        }
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.featured-obituaries {
    height: 700px;
    margin: 0px;
    .container {
      width: 100%;
    }

    .split-header {
      display: $flex;
      justify-content: space-between;
      margin: 0px auto;
      padding-left: 25px;
      padding-top: 60px;
      width: $breakpoint-md;
      a.btn {
        padding:16px 25px;
      }


      h1,
      h2,
      h3 {
        margin-right: auto;
      }

      h2 {

        font: normal normal bold 30px/40px $font-primary;
        letter-spacing: .12px;
        float: left;
        text-align: left;
        color: $text-color;
        @include widthAndHeight(254px, 53px, 5px, 0px, relative, 1);
      }

      .split-header-right {
        padding-right: 0px;

        .btn {
          margin-right: 20px;
        }

      }
    }

    /* Slider */
    .slider {
      margin: 28px auto;
    }

    .card-prev {
      left: 10%;
      bottom: -90px;
    }

    .card-next {
      right: 10%;
      bottom: -90px;
    }
    .card-slide {
      margin-right: 22px;
      display: $grid;
      grid-gap: 22px;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(1,1fr);
      width: 441px;
    }
    .obituaries-list {
      margin-bottom: 80px;
      .card {
        width: 441px;
        height: 351px;
        background: $secondary 0% 0% no-repeat padding-box;
        margin: 0px;
        h4 {
          margin:20px 23px 18px 23px;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis
        }
        p{
        padding: 0px 25px;
        word-wrap: break-word;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        width: 221px;
        height: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;

        }
        .card-items {
          width: 221px;
          height: 351px;
          float: left;
          overflow: hidden;
        }

        .card-img{
          position: relative;
          width: 221px;
          height: 351px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          img {
            -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
            mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
            -o-object-fit: contain;
            object-fit: contain;
            transition: transform .25s ease-in-out;
            width: 100%;
            height: auto;
            z-index: 1;
            border-radius: 0px;

          }

        }
        .card-details {
          height: 351px;
          margin: 0 auto;
          width: 220px;

          .card-title {
            font: normal normal bold 20px/27px $font-primary;
            letter-spacing: 0.08px;
            color: $text-color;
          }
          .card-description {
            height: 200px;
            text-align: center;
            font: normal normal normal 16px/29px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
          }
        }
      }
    }

    .space-line {
      width: 90%;
    }

  }

}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
    section.featured-obituaries {
        height: 890px;
        margin: 0px;
       .container {
        width: 100%;
        height: auto;
       }

       .split-header{
        display: $flex;
        justify-content: space-between;
        margin:105px auto 0px;
        width: $breakpoint-lg;
        h1,h2,h3{margin-right:auto;}
        h2{
          text-align: left;
          font: normal normal bold 40px/53px $font-primary;
          letter-spacing: 0.12px;
          width: 311px;
          height: 53px;
          color: $text-color;
          margin-left: 55px;
        }
        .split-header-links{
          padding-right: 25px;
        }
      }


      /* Slider */
      .slider {
        margin: 50px auto;
      }
      .card-slide {
        margin-right: 22px;
        display: $grid;
        grid-gap: 22px;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(1,1fr);
        width: 441px;
      }
      .card-prev {
        left:25%;
        bottom: -90px;
      }
      .card-next {
        right:25%;
        bottom: -90px;
      }

      .obituaries-list {
        display: $flex;
        margin-bottom: 80px;

        .card {
          width: 441px;
          height: 484px;
          background: $secondary 0% 0% no-repeat padding-box;
          margin: 0px;
          h4 {
            margin: 54px 21px 34px 21px;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis
          }
          p{
          padding: 0px 19px;
          word-wrap: break-word;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          width: 221px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0px;

          }
          .card-items {
            width: 221px;
            height: 484px;
            float: left;
            overflow: hidden;
          }

          .card-img{
            position: relative;
            width: 25221px8px;
            height: 484px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            img {
              -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
              mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
              -o-object-fit: contain;
              object-fit: contain;
              transition: transform .25s ease-in-out;
              width: 100%;
              height: auto;
              z-index: 1;
              border-radius: 0px;

            }

          }
          .card-details {
            height: 484px;
            margin: 0 auto;
            width: 220px;

            .card-title {
              font: normal normal bold 28px/37px $font-primary;
              letter-spacing: 0.08px;
              color: $text-color;
            }
            .card-description {
              height: 260px;
              text-align: center;
              font: normal normal normal 18px/37px $font-secondary;
              letter-spacing: 0.05px;
              color: $text-color;
            }
          }
        }
      }
      .space-line {
          width: 95%;
      }

    }

  }

 //When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.featured-obituaries {
    margin: 0px;
    @include widthAndHeight(100%, 657px, null, null, null, null);
    background: $floral-white 0 0 no-repeat padding-box;

    .container {
      width: 100%;

      .split-header {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        margin-top: 50px;

        h2 {
          font: normal normal bold 30px/40px $font-primary;
          padding: 0px 0px 14px 23px;
          color: $text-dark;
        }

        .split-header-links {
          display: none;
        }
      }
    }

    .split-header-mobile {
      .btn {
        @include widthAndHeight(90%, 65px, null, null, null, null);
        border: 2px solid $primary;
        border-radius: 34px;
        opacity: 1;
        display: inline-block;
        margin: 2px;
        padding: 20px !important;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-family:  $font-secondary;
        margin: $base-margin * 2;
        &::after {
          width: 160px;
          height: 25px;
          text-align: center;
          font: normal normal 600 16px/27px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }

      .btn.primary {
        @include widthAndHeight(90%, 65px, null, null, null, null);
        background: $primary 0% 0% no-repeat padding-box;
        border: 2px solid $primary;
        border-radius: 34px;
        opacity: 1;
        color: $white;
        padding: 20px;
        margin: 0px $base-margin * 2;
        &::after {
          width: 160px;
          height: 25px;
          text-align: center;
          font: normal normal 600 16px/27px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }
    }

    .slider {
      margin: 0 auto;
    }

    .card-slide {
      margin: 0 8px;
      width:259px;
    }

    .space-line {
      display: none;
    }

    .card-prev {
      left: 5%;
      bottom: 135px;
      opacity: 1;
      z-index: 1;
    }

    .card-next {
      right: 5%;
      bottom: 135px;
      opacity: 1;
      z-index: 1;
    }

    .split-header-mobile {
      display: $flex;
      flex-direction: column;
    }
    .card-list{
      padding: 0px;
    }
    .card-slide {
      margin-right: 22px;
      display: $grid;
      grid-gap: 22px;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(1,1fr);
      width: 244px;
    }
    .obituaries-list {
      display: $flex;


      .card {
        height: 347px;
        max-width: 244px;
        margin-right: 0px;
        width: 100%;
        position: relative;
        background: $secondary 0% 0% no-repeat padding-box;



        .card-details {
          position: absolute;
          top: 302px;
          width: 100%;
          height: 46px;
          z-index: 1;
          opacity: 0.8;
          padding: 10px;
          background: $black 0% 0% no-repeat padding-box;
          border-radius: 0px 0px 20px 20px;
          .card-title {
            font: normal normal 700 16px/25px $font-secondary;
            letter-spacing: 0px;
            color: $white;
            opacity: 1;
            margin: 0px;
            -webkit-line-clamp: 1;
          }

          .card-description {
            display: none;
          }
        }
        .card-items {
          width: 244px;
          height: 347px;
          float: left;
          overflow: hidden;
        }

        .card-img{
          position: relative;
          width: 244px;
          height: 347px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          img {
            mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
            object-fit: contain;
            transition: transform .25s ease-in-out;
            width: 100%;
            height: auto;
            z-index: 1;
            border-radius: 0px;

          }

        }
      }
    }
  }
}
