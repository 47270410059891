section {
  height: auto;
}

section.featured-notices {
  @include widthAndHeight($breakpoint-xl, 1004px, null, null, null, null);
  background: $secondary;
  max-width: $breakpoint-xl;
  overflow: hidden;
  .showInMobileview {
    display: none;
  }

  .hideInMobileview {
    display: block;
  }

  .card-block {
    overflow: hidden;

    .featured-banner-img-card {
      border-radius: 3px 3px 0 0;
      display: table-cell;
      height: 204px;
      overflow: hidden;
      position: relative;
      text-align: center;
      vertical-align: center;
      width: 224px;

      img {
        margin: auto;
        mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
        transition: transform .25s ease-in-out;
        z-index: 1;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  .card-block .featured-banner-img-card:hover > img {
      transform: scale(1.15);
      transition: $allease-in-out;

  }

  .date {
    padding: 9px 11px;
    height: 93px;
    justify-content: space-evenly;

    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $text-dark;
      font: normal normal 600 18px/27px $font-secondary;
      padding-bottom: 7px;
      margin: 0px;
    }

    .mb-calender {

      img {

        width: 19px;
        height: 19px;
        float: left;
        margin-right: 11px;
        margin-top: 2px;
        border-radius: 0px;
      }

      p {
        font: normal normal normal 16px/25px $font-secondary;
        letter-spacing: 0px;
        color: $grey;
        margin: 0px;
        text-align: left;
      }
    }
  }


  .bottom_link {
    position: relative;
    top: 50px;
    width: 105px;
    height: 30px;
    margin: 0px auto;
    text-align: center;
    border-bottom: 3px solid $primary;

    a {
      text-decoration: none;
      color: $font-secondary;
      letter-spacing: 0px;
      color: $primary;
      font: normal normal 600 18px/27px $font-secondary;
    }
  }
  .bottom_link:hover {
    border-bottom: 3px solid $primary-light;
    a {
      color: $primary-light;
    }
  }

}



.containers {
  position: relative;
  left: 0px;
  width: $breakpoint-xl;
  height: 1004px;

  .split-header {
    display: $flex;
    justify-content: space-between;
    margin-top: 105px;
    margin-left: 337px;
    align-items: center;

    h1,
    h2,
    h3 {
      margin-right: auto;
    }

    h2 {
      text-align: left;
      font: normal normal bold 40px/53px $font-primary;
      letter-spacing: 0.12px;
      width: 325px;
      height: 53px;
    }

    .split-header-right {
      padding-right: 332px;
      padding-bottom: 20px;
      .btn{
        font-family: $font-secondary;
      }
      a.hideInMobileView {
        margin-right: 25px;
      }
    }
  }
  .cards-4{
    overflow-x: hidden;
    overflow-y: auto;
    width: $breakpoint-lg;
    grid-auto-rows: minmax(min-content, max-content);
    padding-top: 0px;
  }

}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  section.featured-notices {
    max-width: 100%;
    width: 100%;

    .containers {
      margin: 0px auto;
    }
  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  section.featured-notices {


    @include widthAndHeight(100%, 960px, null, null, null, null);
    background: $secondary;


    .containers {
      @include widthAndHeight($breakpoint-lg, 850px, 50px, 0px, relative, 1);
      padding: 15px;
      margin: 0px auto;

      .split-header {
        display: $flex;
        justify-content: space-between;
        margin-left: 30px;
        margin-top: 0px;
        padding-bottom: 20px;

        a.btn {
          margin-right: 10px;
          padding: 16px 32px 17px 37px;
        }

        h1,
        h2,
        h3 {
          margin-right: auto;
        }

        h2 {

          font: normal normal bold 30px/40px $font-primary;
          letter-spacing: .12px;
          float: left;
          text-align: left;
          @include widthAndHeight(254px, 53px, 5px, 0px, relative, 1);
        }

        .split-header-right {
          padding-right: 88px;

          a.hideInMobileView {
            margin-right: 25px;
          }

        }
      }

      .bottom_link {
        top: 35px;
      }

      .cards-4 {
        display: $grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(min-content, max-content);
        padding-top: 15px;
        @include widthAndHeight(97%, 685px, 0px, 40px, relative, 1);

        .card {
          @include widthAndHeight(200px, 297px, 0px, 0px, relative, 1);
          margin-right: 24px;
          float: left;
          display: block;


          .card-block {
            overflow: hidden;

            .featured-banner-img-card {
              height: 204px;
            }
          }

          .date {
            @include widthAndHeight(auto, 93px, 0px, 0px, relative, 1);

            display: $flex;
            flex-direction: column;
            justify-content: space-evenly;

            .mb-calender {
              img {
                margin-top: 0px;
                margin-bottom: 10px;
              }

              p {
                @include widthAndHeight(auto, 20px, 0px, 0px, null, 1);
                font: normal normal normal 16px/21px $font-secondary;
                color: $grey;
                margin-left: 25px;
              }

            }
          }

        }

      }
      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        height: 865px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $grey;
        border-radius: 8px;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $grey;
        border-radius: 8px;
      }


    }

  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.featured-notices {

    @include widthAndHeight(100%, 1040px, null, null, null, null);

    .containers {

      @include widthAndHeight($breakpoint-md, 1000px, 0px, 0px, relative, 1);
      padding: 30px;
      margin: 0px auto;

      .split-header {

        display: $flex;

        justify-content: space-between;

        margin: 0;


        a.btn {
          margin-right: 10px;
          padding: 16px 25px;
        }


        h1,
        h2,
        h3 {
          margin-right: auto;
        }

        h2 {

          font: normal normal bold 30px/40px $font-primary;

          letter-spacing: .12px;

          float: left;

          text-align: left;

          @include widthAndHeight(262px, 53px, 5px, 0px, relative, 1);

        }

        .split-header-right {

          padding-right: 0px;

          .hideInMobileView {
            margin-right: 20px;
          }


        }

      }

      .cards-4 {

        display: grid;

        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(min-content, max-content);
        padding-top: 15px;

        @include widthAndHeight(718px, 865px, 0px, 0px, relative, 1);

        margin-bottom: $base-margin * 2;

        .card {

          @include widthAndHeight(154px, 241px, 0px, 0px, relative, 1);

          margin-right: 24px;

          float: left;

          display: block;

          .card-block {
            overflow: hidden;

            .featured-banner-img-card {
              height: 172px;
            }
          }



          .date {
            @include widthAndHeight(auto, 69px, 0px, 0px, relative, 1);
            display: $flex;
            flex-direction: column;
            justify-content: space-evenly;

            h4 {
              font: normal normal normal 16px/21px $font-secondary;
            }

           .mb-calender {
              img {
                margin-top: 0px;
                margin-bottom: 5px;
                font-size: 16px;
                margin-right:7px;
              }

              p {
                margin-left: 22px;
                font: normal normal normal 14px/21px $font-secondary;
              }
            }

          }
        }

      }

      .bottom_link {
        top: -5px;
      }

      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        height: 865px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $grey;
        border-radius: 8px;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $grey;
        border-radius: 8px;
      }
    }

  }

}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.featured-notices {
    @include widthAndHeight ($breakpoint-sm, 647px, null, null, null, null);
    z-index: -1;

    .showInMobileView {
      display: block;
    }

    .hideInMobileView {
      display: none;
    }

    .containers {
      width: $breakpoint-sm;
      height: 647px;
      .bottom_link {
        display: none;
      }

      .split-header {
        display: block;
        margin: 0px;

        h2 {
          @include widthAndHeight(100%, 43px, 65px, 23px, absolute, 1);
          text-align: left;
          margin: 0px;
          font: normal normal bold 32px/43px $font-primary;
          letter-spacing: 0.1px;
          color: $text-color;
        }

        .split-header-right {
          display: $flex;
          flex-direction: column-reverse;
          position: absolute;
          top: 420px;
          left: 23px;
          padding: 0px;
          width: 90%;

          .hideInMobileView {
            display: none;
          }

          a {
            width: 100%;
            height: 65px;
            text-align: center;
            padding: 0px;
            margin: $base-margin 0px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &::after {
              width: 128px;
              height: 25px;
              text-align: center;
              font: normal normal 600 18px/27px $font-secondary;
              letter-spacing: 0px;
              color: $white;
              opacity: 1;
            }
          }
        }
      }

      .cards-4 {
        @include widthAndHeight(100%, auto, 118px, 0px, relative, 1);
        display: block;
        padding: 0px;
        .card-list{
          .card-slide{
            width: 172px;
          }
        }
        .card-prev {
          left: 19px;
          top: 44%;
          z-index: 9;
        }
        .card-next {
          right: 19px;
          z-index: 9;
          top: 35%;
        }
        .card-slide{
          display: block;
          .card {
            @include widthAndHeight (178px, 272px, 6px, 14px, relative, 1);
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px $light-black;
            border-radius: 20px;
            margin-bottom: 20px;
            margin-right: 26px;
            margin-top: 0px;

            .card-block {
              overflow: hidden;

              .featured-banner-img-card {
                height: 191px;
              }
            }

            .date {
              @include widthAndHeight (auto, 81px, 4px, 0px, relative, 1);

              h4 {
                @include widthAndHeight (156px, 25px, 0px, 35px, none, 1);
                text-align: left;
                font: normal normal 600 18px/27px $font-secondary;
                letter-spacing: 0px;
                color: $dark-grey;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .mb-calender {
                img {
                  overflow: visible;
                  width: 19px;
                  height: 19px;
                  margin-left: 0px;
                  margin-top: 3px;
                }

                p {
                  font: normal normal normal 18px/27px $font-secondary;
                  margin-left: 30px;
                  margin-top: 6px
                }
              }
            }
          }
        }

      }

    }
  }

}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.featured-notices {
    @include widthAndHeight (100%, 647px, null, null, null, null);
    z-index: -1;

    .showInMobileView {
      display: block;
    }

    .hideInMobileView {
      display: none;
    }

    .containers {
      width: 100%;
      height: 647px;
      .bottom_link {
        display: none;
      }

      .split-header {
        display: block;
        margin: 0px;

        h2 {
          @include widthAndHeight(100%, 43px, 60px, 23px, absolute, 1);
          text-align: left;
          margin: 0px;
          font: normal normal bold 32px/43px $font-primary;
          letter-spacing: 0.1px;
          color: $text-color;
        }

        .split-header-right {
          display: $flex;
          flex-direction: column-reverse;
          position: absolute;
          top: 420px;
          left: 23px;
          padding: 0px;
          width: 90%;

          .hideInMobileView {
            display: none;
          }

          a {
            width: 100%;
            height: 65px;
            text-align: center;
            padding: 0px;
            margin: $base-margin 0px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &::after {
              width: 128px;
              height: 25px;
              text-align: center;
              font: normal normal 600 18px/27px $font-secondary;
              letter-spacing: 0px;
              color: $white;
              opacity: 1;
            }
          }
        }
      }

      .cards-4 {
        @include widthAndHeight(100%, auto, 118px, 0px, relative, 1);
        display: block;
        .card-list{
          .card-slide{
            width: 172px;
          }
        }
        .card-prev {
          left: 19px;
          top: 44%;
          z-index: 9;
        }
        .card-next {
          right: 19px;
          z-index: 9;
          top: 35%;
        }
        .card-slide{
          display: block;
          .card {
            @include widthAndHeight (178px, 272px, 6px, 9px, relative, 1);
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px $light-black;
            border-radius: 20px;
            margin-bottom: 20px;
            margin-right: 26px;
            margin-top: 0px;

            .card-block {
              overflow: hidden;

              .featured-banner-img-card {
                height: 191px;
              }
            }

            .date {
              @include widthAndHeight (auto, 81px, 4px, 0px, relative, 1);

              h4 {
                @include widthAndHeight (156px, 25px, 0px, 35px, none, 1);
                text-align: left;
                font: normal normal 600 18px/27px $font-secondary;
                letter-spacing: 0px;
                color: $dark-grey;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .mb-calender {
                img {
                  overflow: visible;
                  width: 19px;
                  height: 19px;
                  margin-left: 0px;
                  margin-top: 3px;
                }

                p {
                  font: normal normal normal 18px/27px $font-secondary;
                  margin-left: 30px;
                  margin-top: 6px
                }
              }
            }
          }
        }

      }

    }
  }
}

@media only screen and (max-width: 414px) {
  section.featured-notices {
    .containers{
      .split-header {
        h2{
          top: 55px;
        }
      }
      .cards-4{
        overflow: unset;
        width: 100%;
        height: auto;
        .card{
          margin-right: 21px;
        }
      }
    }
  }
}

@media only screen and (max-width: 412px) {
  section.featured-notices {
    .containers{
      .cards-4{
        margin: 0px auto;
        width: 100%;
        height: auto;
        display: block;
        overflow: unset;
        padding: 0px;
        .card .date h4{
          width: 170px;
        }
      }
    }
  }
}
