@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins&display=swap);
// $font-primary
$font-playfair: 'Playfair Display', serif;
$font-primary: $font-playfair;
// $font-secondary
$font-poppins: 'Poppins', sans-serif;
$font-secondary: $font-poppins;

$breakpoint-xs: 320px;
$breakpoint-sm: 428px;
$breakpoint-md: 768px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// Common scss file variables
$font: $font-secondary;
$base-font-size: 18px;
$max-width: $breakpoint-xl;
$viewport-s: $breakpoint-md - 1;
$viewport-m: $breakpoint-lg - 1;

$fixed-width: 312px;
$meduim-grey : #dddddd;
$primary: #0b6e4f;
$primary-light: #3b896e;
$primary-link-dark : #096044;
$primary-lightest: #bdd8cf;
$secondary: #eee5d9;
$secondary-light: $secondary;
$secondary-lightest: #fffcf8;
$secondary-dark: #b9978d;
$light-black: #00000029;
$lightest-black: #00000038;
$text-color: #2d3033;
$text-dark: #414042;
$background-pink: #e568c8;
$grey-light: #cacabd !default;
$lightest-brown: rgb(126, 66, 57, 0.4);

$floral-white: #fffcf8;
$light-brown: #beac95;
$lightest-grey: #d9d9d9;
$dark-grey: #101010;
$light-pink: #b39692;
$dark-green: #0c6e4f;
$bold: solid #7e4239;
$light-grey: #747373;
$light1-grey: #ffffff00;
$white: white;
$black: black;
$grey: #A5A5A5;
$light-nickel: #959595;
$light-gray: #EDEDED;
$dark-gray: #6A6A6A;
$orange: #d2b14d;
$purple : #67679A;
$maron : #8E263D;
$dark-color1: #414141;
$gray-triplet: #C7C7C7;
$primary-darkest: #EFF6F4;
$red: #ff0000;
$dark-red: #f56f7849;
$bright-red: #f01926;
$mild-gray: #a7a7a7;
$mild-gray1: #cccccc;
$light-brown: rgb(126, 66, 57, 0.4);
$box-shadow : 0 2px 8px rgba(0, 0, 0, 0.4);
$box-shadow-hover: 0 0 0 rgba(0, 0, 0, 0.3);
$pink-alt : #DB88C8;
$lightest-gray : #707070;
$green: $primary;
$light-green: $primary-light;
$background: $secondary-lightest;
$background-alt: $secondary-light;
$light-black-rgba : rgba(0, 0, 0, 0.7);
$dim-gray: #707070;
$light-dim-gray : #717171;
$slate-gray : #5A5B5D;
$dim-secondary : #B9978D;
$light-secondary : #dceee8;
$green-foreground-primary : #0b6d4d;
// WL Footer Background Color
$footer-bg-color: $secondary;

$border-radius : 10px;
$border-radius-sm: 5px;
$base-padding : 10px;
$border-radius-lg: 20px;
$flex: flex;
$block: block;

$base-margin : 10px;
$baseline: baseline;
$middle: middle;

$grid : grid;
$gap :20px;

$allease-in-out : all 0.3s ease-in-out;
$font-bold: bold;

$alertBackground : #f2dede;
$grey-medium: #9B9B9B !default;
$green-light: #D4EDDA !default;
$alertGreen: #94CC4E !default;
$error-colour: #b94a48 !default;
$link-color: $alertGreen;
$modal-primary: #0C6E4F;
$white-chocolate: #EEE5D9;
$white-lighter : rgba(255, 255, 255, 0.5);

$z-index-mb-max : 9999999;