section {
  height: auto;
}

section.featured-partners {
  @include widthAndHeight ($breakpoint-xl, 637px, null, null, null, null);

  .mb_partners {
    display: none;
  }
}

.col-2 {

  .partners-banner {
    background-image: url("./images/our-partners.jpg");
    @include widthAndHeight(960px, 637px, 0px, 0px, relative, 1);
  }

  .content {
    .partners h2{
      margin-top: 0px;
      margin-left: 0px;
    }
    .partners p{
      margin-top: 0px;
      margin-left: 0px;
    }
    h2 {
      @include widthAndHeight (250px, 53px, 145px, 60px, relative, 1);
      text-align: left;
      font: normal normal bold 40px/53px $font-playfair;
      letter-spacing: 0.12px;
      color: $text-color;
    }

    p {
      @include widthAndHeight (562px, 99px, 160px, 60px, relative, 1);
      text-align: left;
      font: normal normal normal 18px/31px $font-secondary;
      letter-spacing: 0.05px;
      color: $text-color;
    }
  }
}

.partners {
  .partner1 {
    @include widthAndHeight(478px, 103px, 175px, 100px, relative, 1);

    .card-next, .card-prev{
      width: 39px;
      height: 39px;
      background-size: 39px;
      bottom:12px
    }

    .card-prev {
      left: -10%;
      bottom: 16px;

    }

    .card-next {
      right: -10%;
    }

    .card-list{
      padding: 3px 4px;
    }

    .card-3 {
      background: $white;
      overflow: hidden;
      width: 103px;
      height: 103px;
      box-shadow: 0px 3px 6px $light-black;
      border-radius: 8px;
      a {
        margin: 0px;
      }
      img {
        width: 103px;
        height: 103px;
      }

    }
    .card-slide:hover {
      transform: scale(0.94);
      transition: $allease-in-out;
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  section.featured-partners {
    width: 100%;
    height: 637px;
    .container,
    .col2 {
      .partners-banner {
        background-position-x: 100%;
        background-size: cover;
        width: 100%;
      }
    }
  }
 }

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .mb_partners_show {
    display: $grid;
  }

  section.featured-partners {
    @include widthAndHeight($breakpoint-sm, auto, null, null, null, null);

    .container,
    .col2 {
      display: $flex;
      flex-direction: column-reverse;

      .partners-banner {
        @include widthAndHeight($breakpoint-sm, 364px, null, null, null, null);
        background: transparent url("./images/our-partners-mb.jpg") 0% 0% no-repeat padding-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .content {
        width: $breakpoint-sm;

        .partners {
          h2 {
            @include widthAndHeight (195px, 43px, null, null, initial, null);
            text-align: left;
            margin: 0px;
            font: normal normal bold 32px/43px $font-primary;
            letter-spacing: 0.1px;
            color: $text-color;
            margin: 87px 0px 15px 23px;
          }

          p {
            @include widthAndHeight (367px, 141px, null, null, initial, null);
            text-align: left;
            font: normal normal 300 16px/30px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            margin: 0px 0px 0px 23px;
            height: 147px;
            word-wrap: break-word;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .partner1 {
            display: none;

          }

        }

        .mb_partners {
          width: 382px;
          display: $grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 42px;
          margin: 23px auto 105px;
          a{
            margin-left: 0px;
          }
          img {
            width: 100%;
            height: 100%;
            @include widthAndHeight (169px, 169px, null, null, null, null);
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px $light-black;
            border-radius: 8px;
          }
        }
      }
    }
  }
}


 //When the screen width < 1280px and >= 768px, render the 768px display
 @include for-lg-only {
  section.featured-partners {
    @include widthAndHeight(100%, 484px, null, null, null, null);


    .container.col-2>* {
      width: 50%;
    }

    .col-2 {
      .partners-banner {
        background-image: url("./images/our-partners.jpg");
        background-position: 87% 50%;
        @include widthAndHeight(100%, 484px, 0px, 0px, relative, 1);
        background-size: cover;
      }

      .content {

        h2 {
          @include widthAndHeight (190px, 40px, 72px, 20px, relative, 1);
          text-align: left;
          font: normal normal bold 30px/40px $font-playfair;
          letter-spacing: 0.09px;
          color: $text-color;

        }

        p {
          @include widthAndHeight (341px, 163px, 85px, 20px, relative, 1);
          text-align: left;
          font: normal normal normal 16px/27px $font-secondary;
          letter-spacing: 0.05px;
          color: $text-color;
        }
      }

      .slider {

        .card-list {
          padding: 3px 10px;
          width: 275px;
        }
      }


      .partners {
        margin-left: 10px;

        .partner1 {
          width: 220px;
          height: 103px;
          left: 50px;
          top:70px
        }

        .card-next, .card-prev{
            background-size: 31px;
            bottom: 12px;
            height: 31px;
            width: 31px;
        }

        .card-prev {
          z-index: 9999;
          left: -33px;
          bottom: 12px;
        }

        .card-next {
          bottom: 2px;
          right: -95px;
          z-index: 9999;
        }

        .card-slide {
          display: block;
          margin: 0px 8px;
        }

        .card-3 {

          background: $white;
          overflow: hidden;
          width: 77px;
          height: 77px;
          box-shadow: 0px 3px 6px $light-black;
          border-radius: 8px;

          img {
            width: 77px;
            height: 77px;
          }

        }

      }

    }

  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
    section.featured-partners {
      @include widthAndHeight(100%, 583px, null, null, null, null);

      .container.col-2>* {
        width: 50%;
      }

      .col-2 {
        .partners-banner {
          background-image: url("./images/our-partners.jpg") no-repeat;
          background-position-x: 100%;
          background-size: cover;
          @include widthAndHeight(100%, 583px, 0px, 0px, relative, null);
        }

        .slider {
          .card-list {
            padding: 3px 10px;
          }
        }

        .partners {

          margin-left: 55px;
          h2 {
            @include widthAndHeight (100%, 53px, 145px, 0px, relative, 1);
            text-align: left;
            font: normal normal bold 40px/53px $font-playfair;
            letter-spacing: 0.12px;
            color: $text-color;

          }

          p {
            @include widthAndHeight (562px, 99px, 160px, 0px, relative, 1);
            text-align: left;
            font: normal normal normal 18px/31px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
          }
          .partner1 {
            @include widthAndHeight(478px, 103px, 175px,48px, relative, 1);

            .card-prev {
              left: -10%;
              bottom: 16px;
            }

            .card-next {
              right: -10%;
            }

            .card-list{
              padding: 0px 4px;
            }

            .card-3 {
              background: $white;
              overflow: hidden;
              width: 103px;
              height: 103px;
              box-shadow: 0px 3px 6px $light-black;
              border-radius: 8px;

              a {
                margin: 0px;
              }
              img {
                width: 103px;
                height: 103px;
              }

            }
          }
        }

      }

    }
  }

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .mb_partners_show {
    display: $grid;
  }

  section.featured-partners {
    @include widthAndHeight(100%, auto, null, null, null, null);
    .container.col-2>* {
        width: 100%;
    }
    .container,
    .col2 {
      display: $flex;
      flex-direction: column-reverse;

      .partners-banner {
        @include widthAndHeight(100%, 364px, null, null, null, null);
        background: transparent url("./images/our-partners-mb.jpg") 0% 0% no-repeat padding-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .content {
        width: 100%;

        .partners {
          overflow: hidden;
          h2 {
            @include widthAndHeight (85%, 43px, null, null, initial, null);
            text-align: left;
            font: normal normal bold 32px/43px $font-primary;
            letter-spacing: 0.1px;
            color: $text-color;
            margin: 87px 0px 15px 23px;
          }

          p {
            @include widthAndHeight (87%, auto, null, null, initial, null);
            text-align: left;
            font: normal normal 300 16px/30px $font-secondary;
            letter-spacing: 0.05px;
            color: $text-color;
            margin: 0px 23px;
          }

          .partner1 {
            display: none;

          }

        }

        .mb_partners {
          width: 100%;
          display: $grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 42px;
          margin: 23px auto 105px;
          a{
            margin-left: 0px;
          }
          .card-2{
            text-align: center;
          }
          img {
            width: 85%;
            height: 90%;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px $light-black;
            border-radius: 8px;
          }
        }
      }
    }
  }
}





