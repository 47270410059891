/********************
 Tributes Flatpages
*********************/

// Flatpages
.flatpage {
  @import "~/common/styles/component.flatpage";
}

section.flatpage {
  overflow: hidden;
  height: auto;

  .content-wrapper {

    .container,
    .protector,
    .w-page {
      padding: 0px 24px;
      margin-bottom: 24px;
    }

    .message {
      clear: both;
    }

    .buttonpadintro {
      display: flex;
      justify-content: center;
      padding: unset;

      .open-modal {
        margin: unset;
      }

    }

    .desk {
      .protector2 {
        max-width: 100%;
      }
    }

    .descriptiongrey {
      .columnblack2 {
        .protector3 {
          max-width: 100%;
        }
      }
    }

  }

}



//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  section.flatpage {
    .content-wrapper {
      max-width: $breakpoint-lg;
      margin: 0px auto;

      .protector {
        .row {
          .boxy {
            a {
              padding: 20px;
            }
          }
        }
      }
    }
  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  section.flatpage {
    .content-wrapper {
      max-width: $breakpoint-lg;
      margin: 0px auto;

      .protector {
        .row {
          .boxy {
            a {
              padding: 20px;
            }
          }
        }
      }
    }
  }
}

//when the screen width < 1280px
@include for-lg-down {
  section.flatpage {
    .content-wrapper {
      max-width: $breakpoint-md;
      margin: 0 auto;

      .open-modal {
        padding: 20px 10px;
      }

      .buttonpadintro {
        .call-to-action {
          .button {
            text-align: center;
            padding: 20px 10px;
          }
        }
      }

    }


  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.flatpage {
    .content-wrapper {
      max-width: $breakpoint-sm;

      .call-to-action {
        text-align: center;

        a {
          margin: unset;
        }
      }

    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.flatpage {
    .content-wrapper {
      max-width: $breakpoint-sm;

      .call-to-action {
        text-align: center;

        a {
          margin: unset;
        }
      }

      .boxy {
        .buttonWrapper {
          padding: unset;
          margin: 16px auto 0;

          .nowrap {
            white-space: unset;
          }
        }
      }
    }
  }
}