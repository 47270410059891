.standalone-partner {

    background: $secondary 0% 0% no-repeat padding-box;
    border-radius: $border-radius-sm;
    padding: 10px;
    margin-bottom: 50px;
    @include widthAndHeight(317px, auto, null, null, null, null);

    h3 {
        height: 45px;
        margin-bottom: 15px;
        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
        padding: 10px;
        .h-line {
            border: 0px;
            border-bottom: $bold 2px solid;
            opacity: 0.48;
            margin-top: 5px;
        }
    }

    .mb_partner {
        @include widthAndHeight (295px, auto, null, null, null, null);
        display: $grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        padding: 10px;

        a {
            margin-left: 0px;

            img {
                object-fit: cover;
                width: 100%;
                max-height: 100%;
                background: $white 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px $light-black;
                border-radius: $border-radius-sm;
                &:hover {
                    transform: scale(0.97);
                    transition: $allease-in-out;
                }
            }
        }
    }

}




//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {

    .standalone-partner {

        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        padding: 10px;
        margin-bottom: 50px;
        @include widthAndHeight(380px, auto, null, null, null, null);

        h3 {
            padding: 15px;
        }

        .mb_partner {
            @include widthAndHeight (360px, auto, null, null, null, null);
            display: $grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            padding: 15px;

            a {
                margin-left: 0px;
            }
        }

    }

}


//When the screen width < 428px, render the 428px display
@include for-sm-down {

    .standalone-partner {

        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        padding: 10px;
        margin-bottom: 50px;
        @include widthAndHeight(100%, auto, null, null, null, null);

        h3 {
            padding: 15px;
        }

        .mb_partner {
            @include widthAndHeight (100%, auto, null, null, null, null);
            display: $grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            padding: 15px;

            a {
                margin-left: 0px;
            }
        }

    }

}
