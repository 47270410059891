.flowers-listing {
  width: $breakpoint-lg;
  height: auto;
  overflow: hidden;
  margin: 0px auto;

  .flowers-wrapper {
    overflow: hidden;
    padding: 0px $base-padding;
    .no-results-found{
      margin-bottom: $base-margin * 2;
    }
    .flowers-header-wrap {
      overflow: hidden;
      display: $flex;
      justify-content: space-between;
      margin: 41px 0px 44px 0px;

      h1 {
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        color: $text-color;
        line-height: 45px;
        margin: 0px;
      }
    }

    .grid-tiles {
      display: $grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 0.8fr;
      grid-gap: $gap - 4px;
      .my-promotion-activity {
        box-sizing: content-box;
      }
      .ad-block.ad-mrec[data-google-query-id]{
        grid-row: 1;
        grid-column: 4;
        width: 300px;
        max-width: 300px;
      }
      .ad-block.ad-mrec.fl-mobiles-ad[data-google-query-id]{
        grid-row: 5;
        grid-column: 1;
        width: 300px;
        max-width: 300px;
        display: none;
      }
    }

    .grid-tiles .grid-item {
      position: relative;
      display: $block;
      /* Use padding to get a consistent height that is 1:1 with the width */
      height: 333px;
      width: 300px;
      padding-top: 100%;
      border-radius: $border-radius;
      transition: $allease-in-out;
      box-shadow: $box-shadow;
      overflow: hidden;
      background: $white;
      margin-bottom: $base-margin + 4px;
    }

    .grid-tiles .grid-item:hover {
      box-shadow: $box-shadow;
    }

    .grid-tiles .info-bar {
      position: absolute;
      bottom: 0;
      display: $flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 16px;
      background: rgba(20, 20, 20, 0.6);
    }

    .grid-tiles .info-bar * {
      color: $white;
      font-size: 1.1em;
    }

    .grid-tiles .info-bar .info-title {
      font-weight: bold;
      /* Multiline text elipsis */
      display: $block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      font: normal normal normal 18px/29px $font-secondary;
      letter-spacing: 0.05px;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.3em;
      transition: all ease-in-out 1s;
      /* A few browser try and animate the clamp so let them try */
    }

    .grid-tiles .grid-item:hover .info-bar .info-title {
      -webkit-line-clamp: 3;
    }

    .grid-tiles .info-bar .info-price {
      overflow: inherit;
      font: normal normal normal 18px/29px $font-secondary;
    }

    .grid-tiles .as-seen-up {
      position: absolute;
      right: $gap + 5px;
      border-radius: 0px 0px $border-radius $border-radius;
      overflow: hidden;
      top: 0px;
      width: 71px;
      height: 51px;
      background: $black;

      img {
        width: 100%;
        position: relative;
        top: -10px;
      }
    }

    .grid-tiles .as-seen {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      border-radius: $border-radius + 4px;
      overflow: hidden;
      display: none;
    }

    .grid-tiles .as-seen:before {
      content: "As seen on";
      display: $block;
      float: left;
      background: $primary;
      color: $white;
      margin-right: 2px;
      height: 30px;
      padding: 0 8px;
      line-height: 30px;
      font-size: 0.8em;
    }

    .grid-tiles .as-seen img {
      display: $block;
      height: 30px;
      max-width: 150px;
      object-fit: cover;
    }

    .grid-tiles .grid-item>img {
      position: absolute;
      top: 7px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $allease-in-out;
      transform: scale(1.05);
      /* Set an initial scale to avoid the unloaded image border */
    }

    .grid-tiles .grid-item:hover>img {
      transform: scale(1.15);
    }
  }
}

.pagination-wrapper {
  display: block;
  overflow: hidden;
  margin: 97px 0px 53px 0px;
}

.promotion-wrapper {
  display: block;
  overflow: hidden;
  .ad-block.ad-leader[data-google-query-id]{
    margin-bottom: 6px;
    margin: 50px auto 44px;
  }
}

//When the screen width > 1920px, render the 1920px display
@include for-xl-up {
  .flowers-listing {
    width: $breakpoint-lg;

    .flowers-wrapper {
      .grid-tiles .ad-block.ad-mrec[data-google-query-id]{
        grid-row: 1;
        grid-column: 4;
      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .flowers-listing {
    width: $breakpoint-md;

    .flowers-wrapper {
      padding: 0px $base-padding * 2 + 7px;

      .flowers-header-wrap {
        margin: 25px 0px 15px 0px;

        h1 {
          height: 56px;
          font: normal normal bold 30px/95px $font-primary;
          line-height: 45px;
        }
      }

      .grid-tiles {
        grid-template-columns: repeat(2, 1fr);

        .grid-item {
          height: 345px;
          width: 346px;
          margin-bottom: $base-margin * 3 - 2px;
        }


        .as-seen {
          display: block;
        }
      }

      .grid-tiles .ad-block.ad-mrec[data-google-query-id]{
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .flowers-listing {
    width: $breakpoint-sm;

    .flowers-wrapper {
      padding: 0px $base-padding * 2;

      .flowers-header-wrap {
        margin: 30px 0px 15px 0px;

        h1 {
          height: 56px;
          font: normal normal bold 30px/40px $font-primary;
        }
      }

      .grid-tiles {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: $gap + 8px;

        .grid-item {
          height: 381px;
          width: 382px;
        }




        .as-seen {
          display: block;
        }
      }
      .grid-tiles .ad-block.ad-mrec[data-google-query-id]{
        display: none;
      }
      .grid-tiles .ad-block.ad-mrec.fl-mobiles-ad[data-google-query-id]{
        display: block;
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .flowers-listing {
    width:100%;

    .flowers-wrapper {
      padding: 0px $base-padding * 2;

      .flowers-header-wrap {
        margin: 30px 0px 15px 0px;

        h1 {
          height: 56px;
          font: normal normal bold 30px/40px $font-primary;
        }
      }

      .grid-tiles {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: $gap + 8px;

        .grid-item {
          height:auto;
          width:100%;
        }



        .as-seen {
          display: block;
        }
      }
      .grid-tiles .ad-block.ad-mrec[data-google-query-id]{
        display: none;
      }
      .grid-tiles .ad-block.ad-mrec.fl-mobiles-ad[data-google-query-id]{
        display:block;
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }

}
