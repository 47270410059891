.view-notice-fd-info-std {
    width: 897px;
    padding: 15px;
    background: $secondary 0% 0% no-repeat padding-box;
    border-radius: $border-radius-sm;
    margin-bottom: 30px;


    .h-line {
        border: 0px;
        border-bottom: $bold 2px solid;
        opacity: 0.48;
        margin-top: 5px;
    }


    .ph-icon {
        background: url("././images/phone.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;


    }

    .web-icon {
        background: url("././images/globe.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .email-icon {
        background: url("././images/share-envelope.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;

    }

    .home-icon {
        background: url("././images/home.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;

    }


    h3 {

        height: 45px;
        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
    }

    .std-info {
        display: $grid;
        grid-template-columns: repeat(2, 1fr);

        .info-details-fname {
            width: 800px;

            .info-item {

                height: auto;

                .hideOnTabletView {
                    display: $block;
                }

            }

            .headTxt {
                text-align: left;
                font: normal normal 600 20px/42px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                vertical-align: $middle;

            }

            .subText1 {
                width: 100%;
                text-align: left;
                font: normal normal normal 20px/42px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                padding-right: 22px;
                vertical-align: $middle;
            }
        }

        .info-details {
            width: 635px;

            .info-item {

                height: auto;

                .hideOnTabletView {
                    display: $block;
                }

            }

            .headTxt {
                text-align: left;
                font: normal normal 600 20px/42px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                vertical-align: $middle;

            }

            .subText1 {
                width: 100%;
                text-align: left;
                font: normal normal normal 20px/42px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                padding-right: 22px;
                vertical-align: $middle;
            }

            .subText {

                text-align: left;
                font: normal normal normal 20px/42px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                padding-right: 22px;
                vertical-align: $middle;

                span {
                    color: $text-dark;
                    vertical-align: $middle;
                    cursor: pointer;
                }

                a {
                    color: $primary-light;
                }

            }
        }

        .info-advt-content {
            .sinfo-img-wrapper {
                overflow: hidden;

                .sinfo-img-card {
                    align-items: baseline;
                    display: $flex;
                    height: auto;
                    margin: 0;
                    overflow: hidden;
                    padding-top: 30%;

                    img {
                        margin-top: -25%;
                        object-fit: cover;
                        transition: transform .25s ease-in-out;
                        width: 80%;
                        height: auto;
                        margin-left: 20%;
                    }
                }
            }

            .hideOnMobileView {
                display: $block;
            }
        }

    }


}


//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
    .view-notice-fd-info-std {

        .hideOnTabletView {
            display: $block;
        }

        .ph-icon,
        .email-icon,
        .home-icon {
            background-position: 0 50%;
            vertical-align: $middle;
            background-size: 18px;
        }

        width: 380px;
        padding: $base-padding;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 35px;

        h3 {

            height: 45px;
            margin-left: 0px;
            font: normal normal bold 24px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
            display: inline-block;
        }

        .std-info {
            line-height: 0px;
            margin-left: 0px;
            grid-template-columns: repeat(1, 1fr);

            .info-details-fname {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {
                        height: 28px;
                        text-align: left;
                        font: normal normal 600 16px/36px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: $middle;

                    }


                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 16px/36px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: $middle;
                    }

                }

            }

            .info-details {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {
                        height: 28px;
                        text-align: left;
                        font: normal normal 600 16px/36px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: $middle;

                    }


                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 16px/36px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: $middle;
                    }

                    .subText {

                        text-align: left;
                        font: normal normal normal 16px/36px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        padding-right: 22px;
                        vertical-align: $middle;

                        span {
                            color: $text-dark;
                            vertical-align: $middle;
                            cursor: pointer;
                        }



                    }
                }

            }

            .info-advt-content {

                width: 60%;
                margin-left: 40%;

                .sinfo-img-wrapper {
                    overflow: hidden;

                    .sinfo-img-card {
                        align-items: baseline;
                        display: $flex;
                        height: auto;
                        margin: 0;
                        overflow: hidden;
                        padding-top: 30%;


                        img {
                            margin-top: -25%;
                            object-fit: cover;
                            transition: transform .25s ease-in-out;
                            width: 100%;
                            height: auto;
                            margin-left: 0px;
                        }
                    }
                }
            }


        }




    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {

    .view-notice-fd-info-std {

        .ph-icon,
        .email-icon,
        .home-icon {
            display: none;
        }

        .hideOnTabletView {
            display: $block;
        }

        width: 100%;
        padding: $base-padding;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 35px;

        h3 {

            height: 45px;
            margin-left: 0px;
            font: normal normal bold 24px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
        }

        .std-info {
            line-height: 0px;
            margin-left: 0px;
            grid-template-columns: repeat(1, 1fr);


            .info-details-fname {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {

                        height: 28px;
                        text-align: left;
                        font: normal normal 600 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: text-top;

                    }

                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: text-top;
                    }

                }

            }

            .info-details {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {

                        height: 28px;
                        text-align: left;
                        font: normal normal 600 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: text-top;

                    }

                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: text-top;
                    }

                    .subText {

                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        padding-right: 22px;
                        vertical-align: bottom;

                        span {
                            color: $text-dark;
                            vertical-align: text-top;
                            cursor: pointer;
                        }

                    }

                }

            }

            .info-advt-content {
                margin: 0 auto;
                width: 80%;
                height: auto;


                .hideOnMobileView {
                    display: none;
                }

                .showOnMobileView {
                    display: $block;
                }

                .showOnTabletView {
                    display: $block;
                }

                .sinfo-img-wrapper {
                    .sinfo-img-card {
                        padding-top: 30%;

                        img {
                            margin-top: -25%;
                            object-fit: cover;
                            transition: transform .25s ease-in-out;
                            width: 100%;
                            height: auto;
                            margin-left: 0px;
                        }

                    }
                }

            }

        }




    }

}

//When the screen width < 428px, render the 428px display
@include for-sm-down {

    .view-notice-fd-info-std {

        .ph-icon,
        .email-icon,
        .home-icon {
            display: none;
        }

        .hideOnTabletView {
            display: $block;
        }

        width: 100%;
        padding: $base-padding;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 35px;

        h3 {

            height: 45px;
            margin-left: 0px;
            font: normal normal bold 22px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
        }

        .std-info {
            line-height: 0px;
            margin-left: 0px;

            grid-template-columns: repeat(1, 1fr);

            .info-details-fname {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {

                        height: 28px;
                        text-align: left;
                        font: normal normal 600 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: text-top;

                    }

                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: text-top;
                        word-break: break-word;
                    }

                }

            }

            .info-details {
                width: 100%;

                .info-item {
                    margin-bottom: 0px;
                    height: auto;

                    .headTxt {

                        height: 28px;
                        text-align: left;
                        font: normal normal 600 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        vertical-align: text-top;

                    }

                    .subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        vertical-align: text-top;
                        word-break: break-word;
                    }


                    .subText {
                        text-align: left;
                        font: normal normal normal 18px/42px $font-secondary;
                        letter-spacing: 0px;
                        color: $text-dark;
                        padding-right: 22px;
                        vertical-align: bottom;

                        span {
                            color: $text-dark;
                            cursor: pointer;
                            vertical-align: text-top;
                            word-break: break-word;
                        }

                    }

                }
            }

            .info-advt-content {

                margin: 0 auto;
                width: 80%;

                height: auto;


                .hideOnMobileView {
                    display: none;
                }

                .showOnMobileView {
                    display: $block;
                }

                .showOnTabletView {
                    display: $block;
                }

                .sinfo-img-wrapper {
                    .sinfo-img-card {
                        padding-top: 30%;

                        img {
                            margin-top: -25%;
                            object-fit: cover;
                            transition: transform .25s ease-in-out;
                            width: 100%;
                            height: auto;
                            margin-left: 0px;
                        }

                    }
                }

            }

        }




    }

}


@media only screen and (max-width: 375px) {
    .view-notice-fd-info-std h3 {
        height: auto;

        hr {
            margin: 0px 0px $base-margin + 8
        }
    }
}