h1,h2,h3{
  font-family: $font-primary;
}

body{
  background: $background;
  font-family: $font-secondary;
}

.nowrap {
  white-space: nowrap;
}
