.fd-details-wrapper-content {
    max-width: $breakpoint-xl;

    .container {
        padding: 20px;

        .showOnMobileView {
            display: none;
        }

        .hideOnMobileView {
            display: $block;
        }

        .fd-details-section {
            display: $grid;
            grid-template-columns: repeat(2, 0fr);

            .fd-details-left {
                .fd-details-left-content {
                    .standalone-partner {
                        display: $block;
                    }

                    .fd-details-profile {
                        display: $block;
                        margin-bottom: 30px;
                        background: $secondary 0% 0% no-repeat padding-box;
                        border: 2px solid $secondary;
                        height: auto;
                        width: 330px;
                        border-radius: 0px 0px 9px 9px;

                        .profile-pic {
                            width: 325px;
                            display: $block;
                            padding: 5px;

                            .image-wrapper {
                                overflow: hidden;

                                .std-banner-img-card {
                                    align-items: center;
                                    border-radius: 3px 3px 0 0;
                                    display: $flex;
                                    height: 0;
                                    margin: -6px;
                                    overflow: hidden;
                                    padding-top: calc(100% + 12px);
                                    position: relative;

                                    img {
                                        margin-top: -100%;
                                        mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                                        object-fit: contain;
                                        transition: transform .25s ease-in-out;
                                        width: 100%;
                                        height: auto;
                                        border-radius: 10px;
                                    }
                                }
                            }
                        }

                        .afda-details {
                            display: $grid;
                            grid-template-columns: repeat(2, 3fr);
                            padding: 5px;

                            .headerTxt {
                                font: normal normal 600 16px/21px $font-secondary;
                                letter-spacing: -0.26px;
                                color: $text-dark;
                                width: 180px;
                            }

                            .afda-img {
                                padding: 3px;
                                background: $white 0% 0% no-repeat padding-box;
                                width: 133px;
                                height: 62px;

                                img {
                                    width: 100%;
                                    object-fit: cover;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .advt_content {
                        height: auto;
                        margin-top: $base-margin * 3;
                    }
                }
            }

            .fd-details-right {
                padding: 10px 20px;

                .fd-details-right-content {
                    width: 100%;

                    .profile-pic {
                        display: none;
                    }

                    .inner-content {
                        .fd-show-error-lg {
                            display: block;
                        }

                        .fd-show-error-md {
                            display: none;
                            width: 100%;
                        }

                        .inner-content-header {
                            width: 860px;
                            padding-bottom: 20px;

                            .header-block h1 {
                                width: 81%;
                                text-align: left;
                                margin-bottom: 30px;
                                color: $text-dark;
                                font: bold 35px/53px $font-secondary;
                            }

                            .inner-content-desc {
                                width: 897px;
                                padding-bottom: 20px;
                                text-align: left;
                                font: normal 18px/24px $font-secondary;
                                color: $text-dark;

                                p {
                                    width: 840px;
                                    margin-bottom: 5px;
                                    color: $text-dark;
                                    font: normal 18px/24px $font-secondary;
                                }

                                ul {
                                    margin-left: $base-margin + 22;
                                }
                                ol {
                                    margin-left: $base-margin + 22;
                                }
                            }
                        }
                    }

                    .right-standalone-partner {
                        display: none;
                    }
                }
            }
        }
    }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
    .fd-details-wrapper-content {
        width: $breakpoint-lg;
        margin: 0 auto;
    }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
    .fd-details-wrapper-content {
        width: $breakpoint-md;
        margin: 0 auto;

        .container {
            padding: 20px;

            .showOnMobileView {
                display: none;
            }

            .hideOnMobileView {
                display: $block;
            }

            .fd-details-section {
                .fd-details-right {
                    .fd-details-right-content {
                        width: 100%;

                        .inner-content {
                            .inner-content-header {
                                width: 375px;

                                .header-block h1 {
                                    width: 100%;
                                    font: bold 28px/42px $font-secondary;
                                }

                                .inner-content-desc {
                                    width: 375px;
                                    font: normal 16px/24px $font-secondary;

                                    p {
                                        width: 375px;
                                        font: normal 16px/24px $font-secondary;
                                    }
                                }
                            }
                        }

                        .right-standalone-partner {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
    .fd-details-wrapper-content {
        width: $breakpoint-sm;

        .container {
            padding: 20px;

            .hideOnMobileView {
                display: none;
            }

            .showOnMobileView {
                display: $block;
            }

            .fd-details-section {
                grid-template-columns: repeat(1, 1fr);

                .fd-details-left {
                    .fd-details-left-content {
                        .ad-block.ad-m-mrec[data-google-query-id] {
                            display: none;
                        }

                        .standalone-partner {
                            display: none;
                        }

                        .fd-details-profile {
                            display: $block;
                            margin-bottom: 30px;
                            background: $secondary 0% 0% no-repeat padding-box;
                            border: 2px solid $secondary;
                            height: auto;
                            width: 375px;
                            border-radius: 0px 0px 9px 9px;

                            .profile-pic {
                                width: 370px;
                                display: $block;
                                padding: 5px;

                                img {
                                    background: $white 0% 0% no-repeat padding-box;
                                    width: 370px;
                                    height: 233px;
                                }
                            }

                            .afda-details {
                                grid-template-columns: repeat(2, 1fr);
                                gap: 3.5em;
                                padding: 5px;

                                .headerTxt {
                                    font: normal normal bold 16px/21px $font-secondary;
                                    letter-spacing: -0.26px;
                                    color: $text-dark;
                                    width: 180px;
                                }

                                .afda-img {
                                    background: $white 0% 0% no-repeat padding-box;
                                    width: 120px;
                                    height: 62px;

                                    img {
                                        background: $white 0% 0% no-repeat padding-box;
                                    }
                                }
                            }
                        }
                    }
                }

                .fd-details-right {
                    padding: 10px 0px;

                    .fd-details-right-content {
                        width: 100%;

                        .inner-content {
                            .fd-show-error-lg {
                                display: none;
                            }

                            .fd-show-error-md {
                                display: block;
                            }

                            .inner-content-header {
                                width: 375px;

                                .header-block h1 {
                                    width: 100%;
                                    font: bold 28px/42px $font-secondary;
                                }

                                .inner-content-desc {
                                    width: 375px;
                                    font: normal 16px/24px $font-secondary;

                                    p {
                                        width: 375px;
                                        font: normal 16px/24px $font-secondary;
                                    }
                                }
                            }
                        }

                        .right-standalone-partner {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}


//When the screen width < 428px, render the 428px display
@include for-sm-down {


    .fd-details-wrapper-content {
        width: 100%;

        .container {

            padding: 20px;

            .centered {
                display: block;
            }

            .hideOnMobileView {
                display: none;
            }

            .showOnMobileView {
                display: $block;
            }

            .fd-details-section {
                display: block;

                .fd-details-left {

                    .fd-details-left-content {
                        .ad-block.ad-m-mrec[data-google-query-id] {
                            display: none;
                        }

                        .standalone-partner {
                            display: none;
                        }

                        .fd-details-profile {

                            display: $block;
                            margin-bottom: 30px;
                            background: $secondary 0% 0% no-repeat padding-box;
                            border: 2px solid $secondary;
                            height: auto;
                            width: 100%;
                            border-radius: 0px 0px 9px 9px;

                            .profile-pic {

                                width: 100%;
                                display: $block;
                                padding: 5px;

                                img {
                                    background: $white 0% 0% no-repeat padding-box;
                                    width: 100%;
                                    height: 233px;
                                }

                            }

                            .afda-details {

                                grid-template-columns: auto;
                                gap: 0.8em;
                                padding: 5px;

                                .headerTxt {

                                    font: normal normal bold 16px/21px $font-secondary;
                                    letter-spacing: -0.26px;
                                    color: $text-dark;
                                    width: 100%;

                                }

                                .afda-img {
                                    background: $white 0% 0% no-repeat padding-box;
                                    width: 120px;
                                    height: 62px;

                                    img {
                                        background: $white 0% 0% no-repeat padding-box;
                                    }

                                }
                            }
                        }
                    }
                }


                .fd-details-right {
                    padding: 10px 0px;

                    .fd-details-right-content {
                        width: 100%;


                        .inner-content {
                            .fd-show-error-lg {
                                display: none;
                            }

                            .fd-show-error-md {
                                display: block;
                            }

                            .inner-content-header {
                                width: 100%;

                                .header-block h1 {
                                    width: 100%;
                                    font: bold 28px/42px $font-secondary;
                                }

                                .inner-content-desc {
                                    width: 100%;
                                    font: normal 16px/24px $font-secondary;

                                    p {
                                        width: 100%;
                                        font: normal 16px/24px $font-secondary;
                                    }
                                }
                            }
                        }

                        .right-standalone-partner {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}