.ankle {
  width: $breakpoint-xl;
  height: auto;
  margin: 0 auto 45px;
  overflow: hidden;

  .spacewrapper{
    padding: 40px 0;
  }
  .space-line {
    border: 2px solid $secondary-dark;
    margin: 0 auto;
    width: 50%;
    margin-bottom: 45px;
  }

  h3 {
    text-align: center;
    font: normal normal 600 26px/34px $font-secondary;
    letter-spacing: 0px;
    color: $light-grey;
    opacity: 1;
    margin-bottom: 45px;
  }

  .ankle-wrapper {

    mask-image: linear-gradient(transparent, $black 20%, $black 70%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, transparent, $black 20%, $black 70%, transparent 100%);
    width: 80%;
    margin: 0 auto;
    max-width: 100%;

    .logo-slider {
      display: inline-flex;
      animation: marquee 50s linear infinite;
      -webkit-animation: marquee 50s linear infinite;
      align-items: center;

      img {
        padding: 0 15px;
      }

      @keyframes marquee {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(-50%);
        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .ankle {
    width: $breakpoint-sm;
    height: 178px;
    margin: 0 auto 10px;

    .spacewrapper{
      padding: 0;
    }

    .space-line {
      border: 2px $bold;
      left: 23px;
      width: 382px;
      height: 0px;
      opacity: 0.48;
      margin: 10px auto;
    }

    .showonMobileView {
      display: block;
    }

    h3 {
      font-size: 20px;
    }

  }
}

 //When the screen width < 1280px and >= 768px, render the 768px display
 @include for-lg-only {
  .ankle {
    width: $breakpoint-md;
    height: auto;
    .spacewrapper{
      padding: 20px 0;
    }

    .space-line {
      margin-bottom: 20px;
      width: 80%;
    }

    .showonMobileView {
      display: block;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }

  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .ankle {
    width: $breakpoint-lg;
    height: auto;
    .spacewrapper{
      height: 198px;
    }
    .space-line {
      border: 2px solid $secondary-dark;
      margin: 0px auto 55px;
      width: 90%;
    }

    .showonMobileView {
      display: block;
    }

  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .ankle {
    width: 100%;
    height: 178px;
    margin: 0 auto 10px;

    .spacewrapper{
      padding: 0;
    }

    .space-line {
      border: 2px $bold;
      left: 23px;
      width: 100%;
      height: 0px;
      opacity: 0.48;
      margin: $base-margin auto;
    }

    .showonMobileView {
      display: block;
    }

    h3 {
      font-size: 20px;
    }

  }
}
