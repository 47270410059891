@mixin text-overflow-clip($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-overflow-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.article-details {
  .article-details-wrapper {

    width: $breakpoint-lg;
    margin: 0 auto;

    .articleImage {
      margin-bottom: 18px;
    }

    .article-details-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 36px;
      margin: 40px 0px;
      padding: 6px;

      .article-details-left {
        width: 898px;

        .article-details-left-content {
          .article-detail-header {
            h1 {
              text-align: left;
              font: normal normal bold 42px/56px $font-primary;
              letter-spacing: 0.13px;
              color: $text-color;
              margin: 0px;
            }

            .article-detail-sub-header {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: baseline;
              border-bottom: 2px solid $secondary-dark;
              padding-bottom: $base-padding - 4px;

              .article-date-icon {
                background: url("././images/calendar.svg") no-repeat;
                background-position: 0% 50%;
                padding-left: $base-padding * 3;
                padding-top: $base-padding * 2 + 1;
                vertical-align: -3px;
                display: inline-flex;
              }

              a span.icon-mail {
                display: none
              }

              .article-publication-details {
                float: left;
                width: 65%;

                .article-headTxt {
                  font: normal normal 600 20px/30px $font-secondary;
                  letter-spacing: 0px;
                  color: $mild-gray;
                }

                .article-subText {
                  font: normal normal normal 20px/30px $font-secondary;
                  letter-spacing: 0px;
                  color: $mild-gray;
                }
              }

              .article-share-btns {
                float: right;
                display: $grid;
                grid-template-columns: repeat(2, 0fr);
                margin-left: auto;
                grid-column-gap: 20px;

                .share-tools {
                  float: left;

                  .email-share {
                    display: none;
                  }

                  .facebook-share {
                    .icon-facebook {
                      display: $block;
                      width: 45px;
                      height: 45px;
                      background-size: 98%;
                      filter: none;
                    }
                  }

                  .x-share {
                    background-position: 50% 50%;
                    border-radius: 45px;
                    border: 2px solid $mild-gray;
                    padding: 8.4px;
                    width: 45px;
                    display: $block;
                    height: 45px;
                    vertical-align: -16px;
                    filter: none;

                    .icon-twitter {
                      vertical-align: -4px;
                    }
                  }
                }
              }
            }
          }

          .article-detail-desc {
            margin-top: 22px;
            margin-bottom: 10px;

            .story-body {

              p,
              table,
              aside,
              div {
                margin-bottom: $base-margin *2;

                br {
                  display: none;
                }
              }

              img {
                height: 100%;
                width: 100%;
                border-radius: 20px;
                object-fit: contain;
              }

              P {
                text-align: left;
                font: normal normal normal 18px/34px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-color;
              }

              h1 {
                font: normal normal 700 30px/29px $font-secondary
              }

              h2 {
                font: normal normal 400 29px/29px $font-secondary;
              }

              h3 {
                font: normal normal 400 27px/29px $font-secondary;
              }

              h4 {
                font: normal normal 400 25px/29px $font-secondary;
              }

              h2,
              h3,
              h4 {
                text-align: left;
                letter-spacing: 0.09px;
                color: $text-color;
                margin-bottom: 10px;
              }

              figcaption {
                border-bottom: 2px solid $secondary-dark !important;

                .caption-details {
                  span {
                    text-align: left;
                    font: normal normal 600 18px/29px $font-secondary;
                    letter-spacing: 0.05px;
                    color: $text-color;
                    padding: 18px 0px 0px 0px;
                    display: block;
                  }
                }

                .caption-details>* {
                  font: normal normal 600 18px/29px $font-secondary;
                  color: $text-color;
                }
              }

              div.articleImage {
                margin-bottom: 20px
              }

              table,
              tbody,
              thead,
              tr,
              td {

                border: none;
                padding: 4px;
                font: normal normal normal 18px/29px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-color;
              }

              table {
                width: 100% !important;
              }

              ul {
                list-style-type: disc;
              }

              ul,
              ol {
                margin: 20px 0px;
                padding-left: 50px;

                li,
                li>a {
                  margin-bottom: $base-margin*0.5;
                  word-break: break-word;
                  font: normal normal normal 18px/34px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }
              }

              p {
                a {
                  color: $primary;
                }
              }

              sub {
                top: .3em;
              }

              sup {
                top: -.3em;
              }

              blockquote {
                position: relative;
                font-family: $font-secondary;
                font-style: italic;
                font-size: $base-font-size;
                border-top: 4px solid $mild-gray;
                border-bottom: 4px solid $mild-gray;
                margin: 5.5em $base-margin;
                padding: 0.5em $base-padding;
                quotes: "\201C" "\201D" "\2018" "\2019";
                color: $grey-light;

                * {
                  font-family: $font-secondary;
                  font-size: $base-font-size + 6;
                  line-height: 1.76em;
                }
              }

              blockquote:before {
                font-style: normal;
                color: $grey-light;
                content: open-quote;
                font-size: 6em;
                line-height: 0.1em;
                position: absolute;
                top: -4px;
                left: 0;
              }

              blockquote:after {
                font-style: normal;
                color: $grey-light;
                content: close-quote;
                font-size: 6em;
                text-align: right;
                line-height: 0.1em;
                position: absolute;
                bottom: -.8em;
                right: 0;
              }

              blockquote p {
                display: inline;
              }

              .article-lead-image {
                .articleImage {
                  figure {
                    img {
                      width: 100%;
                      height: auto;
                      object-fit: contain;
                    }
                  }
                }

                .article-lead-video {
                  .caption-details {
                    padding-top: $base-padding * 0.5;
                  }
                }
              }

              figcaption {
                padding-bottom: $base-padding * 0.5;
                margin-bottom: $base-margin;
              }

              figure {
                overflow: hidden;

                img {
                  margin-bottom: $base-margin;
                }
              }

              .video-js {
                padding-bottom: 56.25%;
                padding-top: 25px;
                height: 0;
                width: 100%;
              }

              .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
              .video-js .vjs-modal-dialog,
              .vjs-button>.vjs-icon-placeholder:before,
              .vjs-modal-dialog .vjs-modal-dialog-content {
                position: relative;
              }

              #mobileAd3 {
                margin-top: $base-margin * 2;
              }

              .galleria-theme-classic .galleria-stage .galleria-fullScrBtn {
                z-index: 10;
              }

              .recurring-inline-full {
                iframe {
                  width: 100%;
                }
              }

              .videoInline iframe,
              .mainVideo iframe {
                width: 100%;
              }
            }
          }
        }
      }

      .article-details-right {
        width: 314px;

        .article-details-right-content {
          .hideOnMobileView {
            margin-bottom: $base-margin * 4 + 8px;
          }

          .article-latest-articles {
            h2 {
              font: normal normal bold 25px/33px $font-primary;
              letter-spacing: 0.07px;
              color: $text-color;
              border-bottom: 2px solid $lightest-brown;
              padding-bottom: $base-padding - 3px;
            }

            .latest-article-content {
              border-bottom: 2px solid $light-brown;

              a {
                display: $grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                padding: 12px 0px;

                .latest-article-text-content {
                  @include widthAndHeight(178px, auto, 0px, 0px, null, 1);

                  h3 {
                    font: normal normal 600 16px/25px $font-secondary;
                    letter-spacing: 0px;
                    color: $text-color;
                    @include text-overflow-clip(2);
                  }

                  p {
                    font: normal normal normal 16px/22px $font-secondary;
                    letter-spacing: 0.05px;
                    color: $text-color;
                    @include text-overflow-clip(4);
                  }
                }

                .latest-article-right-img {
                  @include widthAndHeight(126px, 142px, 0px, 0px, null, 1);
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    filter: none;
                    border-radius: unset;
                    object-fit: cover;

                    &:hover {
                      transform: scale(1.05);
                      transition: $allease-in-out;
                    }
                  }
                }
              }

              &:nth-last-child(2) {
                border: none;
              }

            }

            .view-more-article {
              margin: 21px auto;
              text-align: center;

              a.btn.primary.invert {
                width: 314px;
                height: 60px;
                border-radius: 34px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font: normal normal 600 18px/27px $font-secondary;
                margin: 0px;
                padding: 0px;
              }
            }
          }


        }
      }

    }
  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .article-details {
    .article-details-wrapper {
      width: $breakpoint-lg;
      margin: 0 auto;

      .article-details-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 32px;
        margin: 40px 0;
        padding: 6px 18px 15px;
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .article-details {
    .article-details-wrapper {
      margin: 0 auto;
      width: $breakpoint-md;

      .article-details-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 16px;
        margin: 20px 22px;
        padding: 5px;

        .article-details-left {
          width: 383px;

          .article-details-left-content {
            .article-detail-header {
              h1 {
                font: normal normal bold 30px/40px $font-primary;
                letter-spacing: 0.09px;
                color: $text-dark;
                margin-bottom: 13px;
              }

              .article-detail-sub-header {
                border-bottom: 2px solid $secondary-dark;
                padding-bottom: $base-padding - 2px;
                align-items: center;

                .article-publication-details {
                  float: left;
                  width: 65%;

                  .article-headTxt {
                    font: normal normal 600 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }

                  .article-subText {
                    font: normal normal normal 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }
                }

                .article-share-btns {
                  grid-column-gap: 17px;

                  .share-tools {
                    .facebook-share {
                      .icon-facebook {
                        width: 37px;
                        height: 37px;
                      }
                    }

                    .x-share {
                      padding: 5px;
                      vertical-align: -12px;
                      width: 37px;
                      height: 37px;

                      .icon-twitter {
                        background-size: 85%;
                        background-position: center;
                        vertical-align: -6px;
                        padding: $base-padding + 2px;
                        display: $flex;
                      }
                    }

                    .email-share {
                      display: none;
                    }

                  }
                }
              }
            }

            .article-detail-desc {
              margin-top: 15px;
              margin-bottom: 10px;

              .story-body {
                P {
                  text-align: left;
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                table,
                tbody,
                thead,
                tr,
                td {
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                figcaption {
                  .caption-details {
                    span {
                      text-align: left;
                      font: normal normal 600 16px/29px $font-secondary;
                    }
                  }

                  .caption-details>* {
                    font: normal normal 600 16px/29px $font-secondary;
                  }
                }

                ul,
                ol {

                  li,
                  li>a {
                    font: normal normal normal 16px/34px $font-secondary;

                  }
                }
              }
            }
          }
        }

        .article-details-right {
          width: 314px;

          .article-details-right-content {
            .hideOnMobileView {
              margin-bottom: $base-margin * 5 + 3px;
            }

            .article-latest-articles {
              h2 {
                font: normal normal bold 20px/27px $font-primary;
                letter-spacing: 0.06px;
                color: $text-color;
                border-bottom: 2px solid $lightest-brown;
                padding-bottom: $base-padding - 3px;
              }

              .latest-article-content {
                border-bottom: 2px solid $light-brown;

                a {
                  display: $grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-gap: 10px;
                  padding: 11px 0px;

                  .latest-article-text-content {
                    @include widthAndHeight(178px, auto, 0px, 0px, null, 1);


                    h3 {
                      font: normal normal 600 16px/25px $font-secondary;
                      letter-spacing: 0px;
                      color: $text-color;
                      @include text-overflow-clip(2);
                    }

                    p {
                      font: normal normal normal 16px/22px $font-secondary;
                      letter-spacing: 0.05px;
                      color: $text-color;
                      @include text-overflow-clip(4);
                    }
                  }

                  .latest-article-right-img {
                    @include widthAndHeight(126px, 142px, 0px, 0px, null, 1);

                    img {
                      width: 100%;
                      height: 100%;
                      filter: none;
                      border-radius: unset;
                      object-fit: contain;
                    }
                  }
                }

                &:nth-last-child(2) {
                  border: none;
                }

              }

              .view-more-article {
                margin: 25px auto;
                text-align: center;

                a.btn.primary.invert {
                  width: 314px;
                  height: 55px;
                  border-radius: 34px;
                  font: normal normal 600 16px/25px $font-secondary;
                  margin: 0px;
                  padding: 0px;
                }
              }
            }


          }
        }

      }
    }
  }
}



//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .article-details {
    .article-details-wrapper {
      width: $breakpoint-sm;
      margin: 0 auto;

      .article-details-container {
        display: block;
        margin: 30px 20px;
        padding: 3px;

        .article-details-left {
          width: 382px;

          .article-details-left-content {
            .article-detail-header {
              h1 {
                font: normal normal bold 30px/40px $font-primary;
                letter-spacing: -0.03px;
                color: $text-color;
                margin-bottom: 13px;
              }

              .article-detail-sub-header {
                border-bottom: 2px solid $secondary-dark;
                align-items: self-end;
                margin-bottom: 0px;
                padding-bottom: $base-padding - 4px;

                .article-publication-details {
                  float: left;
                  width: 65%;

                  .article-headTxt {
                    font: normal normal 600 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }

                  .article-subText {
                    font: normal normal normal 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }
                }

                .article-share-btns {
                  grid-column-gap: 17px;

                  .share-tools {
                    float: left;
                    padding: 0px;

                    .facebook-share {
                      .icon-facebook {
                        width: 38px;
                        height: 37px;
                        background-size: 100%;
                        padding: 0px;

                      }
                    }

                    .x-share {
                      padding: 5px;
                      vertical-align: -12px;
                      width: 38px;
                      height: 38px;

                      .icon-twitter {
                        background-size: 100%;
                        padding: $base-padding + 2px;
                        filter: none;
                        display: $flex;
                        background-position: 50%;
                      }
                    }

                    .email-share {
                      display: none;
                    }
                  }
                }
              }
            }

            .article-detail-desc {
              margin: 12px 0px;

              .story-body {
                P {
                  text-align: left;
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                table,
                tbody,
                thead,
                tr,
                td {
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                figcaption {
                  .caption-details {
                    span {
                      text-align: left;
                      font: normal normal 600 16px/29px $font-secondary;
                    }
                  }

                  .caption-details>* {
                    font: normal normal 600 16px/29px $font-secondary;
                  }
                }

                ul,
                ol {

                  li,
                  li>a {
                    font: normal normal normal 16px/34px $font-secondary;

                  }
                }
              }
            }
          }
        }

        .article-details-right {
          display: none;
        }
      }
    }
  }

}



//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .article-details {
    width: 100%;
    overflow: unset;

    .article-details-wrapper {
      margin: 0 auto;
      width: 100%;

      .article-details-container {
        display: block;
        margin: 30px 20px;
        padding: 3px;

        .article-details-left {
          width: 100%;

          .article-details-left-content {
            .article-detail-header {
              h1 {
                font: normal normal bold 30px/40px $font-primary;
                letter-spacing: -0.03px;
                color: $text-color;
                margin-bottom: 13px;
              }

              .article-detail-sub-header {
                border-bottom: 2px solid $secondary-dark;
                align-items: self-end;
                margin-bottom: 0px;
                padding-bottom: $base-padding - 4px;

                .article-publication-details {
                  float: left;
                  width: 65%;

                  .article-headTxt {
                    font: normal normal 600 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }

                  .article-subText {
                    font: normal normal normal 16px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $mild-gray;
                  }
                }

                .article-share-btns {
                  grid-column-gap: 17px;

                  .share-tools {
                    float: left;
                    padding: 0px;

                    .facebook-share {
                      .icon-facebook {
                        width: 38px;
                        height: 37px;
                        background-size: 100%;
                        padding: 0px;

                      }
                    }

                    .x-share {
                      padding: 5px;
                      vertical-align: -12px;
                      width: 38px;
                      height: 38px;

                      .icon-twitter {
                        background-size: 100%;
                        padding: $base-padding + 2px;
                        filter: none;
                        display: $flex;
                        background-position: 50%;
                      }
                    }

                    .email-share {
                      display: none;
                    }
                  }
                }
              }
            }

            .article-detail-desc {
              margin: 12px 0px;

              .story-body {
                P {
                  text-align: left;
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                table,
                tbody,
                thead,
                tr,
                td {
                  font: normal normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-color;
                }

                figcaption {
                  .caption-details {
                    span {
                      text-align: left;
                      font: normal normal 600 16px/29px $font-secondary;
                    }
                  }

                  .caption-details>* {
                    font: normal normal 600 16px/29px $font-secondary;
                  }
                }

                ul,
                ol {

                  li,
                  li>a {
                    font: normal normal normal 16px/34px $font-secondary;

                  }
                }
              }
            }
          }
        }

        .article-details-right {
          display: none;
        }
      }
    }
  }
}
