.navbar-search-filter {
  width: 100%;
  height: auto;
  background: $primary;

  select::-ms-expand {
    display: none;
  }

  .navbar-search-filter .container {
    width: $breakpoint-lg;
  }

  .textFormat {
    font: normal normal normal 18px/25px $font-secondary;
    letter-spacing: 1.08px;
    color: $dark-grey;
  }

  input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-image: url("./images/icon-clear.png");
    background-repeat: no-repeat;
    background-position: center;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .tt-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 99;
    height: 250px;
    background: $white;
    display: none;
  }

  .tt-dataset-location {
    height: 250px;
    overflow-y: auto;
  }

  .tt-dataset.tt-dataset-fd {
    position: relative;
    background: $white;
    top: -17px;
    top: 4px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    border-left: 5px solid transparent;
  }

  .tt-suggestion.Typeahead-selectable {
    border: none;
    padding: 10px 20px;
    font: normal normal 600 18px/27px $font-secondary;
  }

  .tt-suggestion.Typeahead-selectable:hover {
    background-color: $primary;
    color: $white;
  }

  #showFilterList {
    display: none;
  }

  .text-overflow-elipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .container {
    width: $breakpoint-lg;

    form {
      .clearable-input {
        position: relative;
      }

      .clearable-input [data-clear-input] {
        background: $grey-light;
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: none;
        font-size: 1em;
        font-weight: 100;
        line-height: 1em;
        padding: 0 0.2em;
        position: absolute;
        right: 0;
        top: 40%;
        transform: translate(-50%);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        z-index: 9;
      }

      .search-list {
        height: 116px;
        padding: 29px 12px 19px;
        display: flex;
        gap: 3px;

        .searchInut {
          @include widthAndHeight(336px, 68px, null, null, null, null);
          background: $white url("./images/search.svg") no-repeat;
          background-position: 30px 24px, 0px 0px;
          border-radius: 38px 0px 0px 38px;
          border: none;
          padding-left: 68px;
          padding-right: $base-padding + 5px;
          @extend .text-overflow-elipsis;
          @extend .textFormat;

          &::placeholder {
            @include widthAndHeight(auto, 25px, 2px, 0px, relative, 1);
          }
        }

        .locationDropDown {
          @include widthAndHeight(290px, 68px, null, null, null, null);
          background: url("./images/map-pin.svg") no-repeat;
          background-position: 20px, calc(100% - 20px);
          background-color: $white;
          padding-left: 60px;
          padding-right: 40px;
          outline: none;
          border: none;

          &::placeholder {
            padding-top: 2px;
            letter-spacing: 0.96px;
            color: $dark-grey;
            text-align: left;
          }

          @extend .textFormat;
          @extend .text-overflow-elipsis;
        }

        .categoryDropDown {
          @include widthAndHeight(270px, 68px, null, null, null, null);
          background: url("./images/search-category.svg") no-repeat, url('./images/search-down.svg') center no-repeat;
          background-position: 20px, calc(100% - 20px);
          background-color: $white;
          padding-right: 40px;
          padding-left: 60px;
          @extend .textFormat;
          @extend .text-overflow-elipsis;
        }

        .filterButton {
          @include widthAndHeight(118px, 68px, null, null, null, null);
          background: $white url("./images/search-filters.svg") no-repeat;
          background-position: calc(100% - 4.8rem);
          padding-left: 30px;
          border: none;
          @extend .textFormat;
          cursor: pointer;
          @extend .text-overflow-elipsis;
        }

        .navbar-radius-options {
          @include widthAndHeight(135px, 68px, null, null, null, null);
          background: $white url('./images/search-down.svg') center no-repeat;
          border: none;
          outline: none;
          background-position: calc(100% - 20px);
          padding-left: 10px;
          padding-right: 30px;
          @extend .textFormat;
          @extend .text-overflow-elipsis;
        }

        .goButton {
          @include widthAndHeight(91px, 68px, null, null, null, null);
          background: $light-secondary 0% 0% no-repeat padding-box;
          border-radius: 0px 38px 38px 0px;
          text-align: center;
          font: normal normal 600 18px/27px $font-secondary;
          color: $green-foreground-primary;
          border: none;
          cursor: pointer;
        }
      }

      .filter-list {
        display: flex;
        height: 86px;
        padding: 0px 12px 0px;
        gap: 14px;

        .funeral_director {
          @include widthAndHeight(308px, 68px, 0px, 0px, null, 1);
          border-radius: 38px;
          padding-left: 68px;
          background: url("./images/home.svg") no-repeat;
          background-color: $white;
          background-position: 30px 22px, 260px 28px;
          border: none;
          top: 0px;
          padding-right: 25px;
          @extend .textFormat;
          @extend .text-overflow-elipsis;
        }

        .funeral_publication {
          @include widthAndHeight(297px, 68px, 116px, 0px, null, null);
          border-radius: 38px;
          padding-left: 68px;
          background: url("./images/listing-publication.svg") no-repeat, url("./images/search-down.svg") center no-repeat;
          background-color: $white;
          background-position: 30px 22px, 250px 28px;
          padding-right: 50px;
          padding-top: 2px;
          @extend .textFormat;
          @extend .text-overflow-elipsis;
        }

        .daterange-dropdown {
          display: none;
        }

        .date-calendar-input {
          .from_calendar {
            @include widthAndHeight(142px, 68px, 116px, null, null, 1);
            border-radius: 34px 0px 0px 34px;
            border: none;
            padding-left: 10px;
            background: $white url("./images/filter-date.svg") no-repeat;
            background-position: calc(100% - 0.55rem);
            padding-right: 30px;
            @extend .text-overflow-elipsis;
            @extend .textFormat;
          }

          .to_calendar {
            @include widthAndHeight(142px, 68px, 116px, null, null, null);
            border-radius: 0px 34px 34px 0px;
            border: none;
            padding-left: 10px;
            background: $white url("./images/filter-date.svg") no-repeat;
            background-position: calc(100% - 0.55rem);
            padding-right: 30px;
            @extend .text-overflow-elipsis;
            @extend .textFormat;
          }
        }

        .funeral_clear_filter {
          letter-spacing: 0.96;
          margin: 20px 0 0 auto;
          font: normal normal 600 18px/25px $font-secondary;

          .clearFilter {
            color: $white;
            border-bottom: 3px solid $white;
          }
        }

        .funeral_director_clear_filter {
          margin: 20px 0 0 auto;
          letter-spacing: 1.08px;
          font: 600 20px/25px $font-secondary;

          .clearFilter {
            color: $white;
            border-bottom: 3px solid $white;
          }
        }
      }
    }
  }
}

// when the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .navbar-search-filter {
    .container {
      form {
        .search-list {
          padding: 29px 18px 19px;
        }

        .filter-list {
          gap: 12px;
          padding: 0px 18px;
        }
      }
    }
  }
}

// when the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .textFormatPlaceHolder {
    text-align: left;
    font: normal normal normal 16px/25px $font-secondary;
    letter-spacing: 0px;
  }

  .navbar-search-filter {
    margin-top: -1px;

    .container {
      width: $breakpoint-md;

      form {
        .search-list {
          height: 98px;
          padding: 22px 27px 21px;

          .searchInut {
            width: 175px;
            height: 55px;
            background-position: 15px, 18px, 0, 0;
            border-radius: 38px 0px 0px 38px;
            padding-left: 45px;
            padding-right: $base-padding - 4px;
            @extend .textFormatPlaceHolder;
            background-size: 19px 18px;
          }

          .locationDropDown {
            width: 154px;
            height: 55px;
            background-position: 11px 16px, 125px 17px;
            padding-left: 40px;
            padding-right: 28px;
            @extend .textFormatPlaceHolder;
            background-size: 18px 22px;
            outline: none;
            border: none;
          }

          .categoryDropDown {
            width: 167px;
            height: 55px;
            background-position: 11px 16px, 140px 17px;
            padding-left: 40px;
            padding-right: 27px;
            @extend .textFormatPlaceHolder;
            background-size: 19px 19px;
          }

          .filterButton {
            width: 112px;
            height: 55px;
            background-position: calc(100% - 4.0rem);
            padding-left: 34px;
            @extend .textFormatPlaceHolder;
            background-size: 18px 22px;
          }

          .navbar-radius-options {
            @include widthAndHeight(108px, 55px, null, null, null, null);
            background-size: 19px 19px
          }

          .goButton {
            width: 91px;
            height: 55px;
            @extend .textFormatPlaceHolder;
            font: normal normal 600 16px/25px $font-secondary;
            text-align: center;
            background-position: calc(100% - 4.99rem);
          }
        }

        .filter-list {
          display: flex;
          height: auto;
          padding: 0px 27px 21px;
          gap: 7px;
          flex-wrap: wrap;

          .funeral_director {
            width: 249px;
            height: 55px;
            border-radius: 38px;
            padding-left: 45px;
            padding-right: 15px;
            background: url("./images/home.svg") no-repeat;
            background-color: $white;
            background-position: 15px 16px, 210px 22px;
            top: 0px;
            border: none;
            @extend .textFormatPlaceHolder;
          }

          .funeral_publication {
            width: 217px;
            height: 55px;
            border-radius: 38px;
            padding-left: 60px;
            background: url("./images/listing-publication.svg") no-repeat,
              url("./images/search-down.svg") center no-repeat;
            background-color: $white;
            background-position: 21px 16px, 180px 22px;
            @extend .textFormatPlaceHolder;
          }

          .date-calendar-input {
            .from_calendar {
              width: 114px;
              height: 55px;
              border-radius: 34px 0px 0px 34px;
              padding-left: 6px;
              background: $white url("./images/filter-date.svg") no-repeat;
              background-position: calc(100% - 0.44rem);
              @extend .textFormatPlaceHolder;
              font: normal normal normal 13px/25px $font-secondary;

              &::placeholder {
                font: normal normal normal 16px/25px $font-secondary;
              }
            }

            .to_calendar {
              width: 114px;
              height: 55px;
              border-radius: 0px 34px 34px 0px;
              padding-left: 2px;
              background: $white url("./images/filter-date.svg") no-repeat;
              background-position: calc(100% - 0.66rem);
              @extend .textFormatPlaceHolder;
              font: normal normal normal 13px/25px $font-secondary;

              &::placeholder {
                font: normal normal normal 16px/25px $font-secondary;
              }
            }
          }

          .funeral_clear_filter {
            letter-spacing: 0.96;
            font: 600 16px/25px $font-secondary;

            .clearFilter {
              border-bottom: 2px solid $white;
            }
          }

          .funeral_director_clear_filter {
            letter-spacing: 0.96;
            font: 600 16px/25px $font-secondary;

            .clearFilter {
              border-bottom: 2px solid $white;
            }
          }
        }
      }
    }
  }
}

// when the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .textFormatMobile {
    font: normal normal 600 18px/27px $font-secondary;
    letter-spacing: 0px;
  }

  .navbar-search-filter {
    position: absolute;
    background: transparent;

    .container {
      display: none;
    }
  }
}

// when the screen width < 428px
@include for-sm-down {
  .textFormatMobile {
    font: normal normal 600 18px/27px $font-secondary;
    letter-spacing: 0px;
  }

  .navbar-search-filter {
    position: absolute;
    background: transparent;

    .container {
      display: none;
    }
  }
}