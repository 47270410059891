* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;
}


// height, width, absolute, top, right, position css
@import "component.mega-menu";


// Common css for all the Buttons
@mixin buttonCommon($width, $height, $top, $left, $position, $opacity, $bgColor, $borderClr, $borderRadius) {
  @include widthAndHeight($width, $height, $top, $left, $position, opacity);
  background: $bgColor 0% 0% no-repeat padding-box;
  border: 2px solid $borderClr;
  border-radius: $borderRadius;
}

@mixin buttonContent($name, $color, $fontSize) {
  content: $name;
  color: $color;
  text-align: center;
  font: 600 $fontSize/normal $font-secondary;
  cursor: pointer;
}

@mixin navFlexContent($flex, $space, $wrap, $grow) {
  display: $flex;
  justify-content: $space;
  flex-wrap: $wrap;
  flex-grow: $grow;
}

.navLinks {
  text-decoration: none;
  height: 25px;
  font: normal normal normal 18px/27px $font-secondary;
  letter-spacing: 0px;
  color: $text-color;
  opacity: 1;
  padding: 16px;
  padding-bottom: 11px;

  &:hover {
    background-color: $dark-green;
    color: $white;
  }
}

.bg-blur {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  transform: scale(2);
}

.navbackground {
  background: $secondary 0% 0% no-repeat padding-box;
  border: 1px solid $secondary-dark;
}

header {
  .container {
    height: 100%;
    padding: 0 138px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include widthAndHeight($breakpoint-xl, 140px, null, null, null, 1);
  background: $secondary-lightest 0% 0% no-repeat padding-box;

  a.logo {
    width: 202px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btnGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    .hamburger {
      display: none;
    }

    .btn-login-signup {
      width: 160px;
      height: 45px;
      background: $light-secondary;
      border-radius: 100px;
      border: 2px solid $primary;

      &.inactive {
        display: none;
      }

      &:hover {
        background: $primary;
      }

      a {
        height: 100%;
        width: 100%;
        color: $primary;
        font: 600 16px/1 $font-secondary;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: $secondary-lightest;
        }
      }
    }

    .profile-greeting {
      width: 45px;
      height: 45px;
      background: $primary;
      border-radius: 50%;
      position: relative;
      transition: all .25s ease;

      display: flex;
      justify-content: center;
      align-items: center;

      &.inactive {
        display: none;
      }

      &:hover {
        width: 160px;
        padding: 0 16px;
        justify-content: space-between;
        border-radius: 5px 5px 0 0;

        .authenticated-profile-greeting-text {
          display: block;
          width: 125px;
          color: #fff;
          text-align: center;
          font: 600 16px/1 $font-secondary;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .user-actions {
          width: 160px;
          background: $secondary-lightest;
          border-radius: 0 0 4px 4px;
          border: 1px solid $primary;
          position: absolute;
          top: 45px;
          right: 0;
          transition: opacity .25s ease;
          z-index: 1;

          display: flex;
          flex-direction: column;

          .btn-user-action {
            height: 35px;
            color: $text-dark;
            font: 400 16px/1 $font-secondary;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              color: $white;
              background: $primary;
            }
          }
        }
      }

      .authenticated-user-avatar {
        width: 24px;
        height: 24px;
        background: $primary url("./images/person-filled.svg") no-repeat center;
        border-radius: 50%;
        border: 2px solid $primary;
      }

      .authenticated-profile-greeting-text,
      .user-actions {
        display: none;
      }
    }

    .btn-view-notices {
      width: 160px;
      height: 45px;
      color: $primary;
      background: $secondary-lightest;
      font: 600 16px/1 $font-secondary;
      border-radius: 100px;
      border: 2px solid $primary;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $primary-lightest;
      }
    }

    .btn-place-a-notice {
      width: 160px;
      height: 45px;
      color: $white;
      background: $primary;
      font: 600 16px/1 $font-secondary;
      border-radius: 100px;
      border: 2px solid $primary;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $primary-light;
      }
    }
  }
}

.desktop-navbar {
  overflow: hidden;
  height: 53px;
}

.navbar {
  @include widthAndHeight(100%, 53px, null, null, absolute, null);
  @extend .navbackground;

  .nav-item {
    display: none;
    @include widthAndHeight($breakpoint-lg, 25px, 15px, 336px, relative, 1);

    ul {
      @include navFlexContent(flex, space-between, wrap, 1);

      li {
        list-style: none;

        >a {
          @extend .navLinks;

          &.active {
            background-color: $dark-green;
            color: $white;
          }
        }
      }
    }
  }
}

.navbar ul li:hover .menu-item {
  display: block;
}

.show {
  display: block;
}

.overlay {
  display: none;
  height: auto;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $secondary;
  overflow-x: hidden;
  transition: 0s;

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    height: 180vh;
    text-align: center;
    margin-top: 30px;
  }
}

.mb-search {
  display: none;
}

.refine-search-filter-mobile .refine-search-filter {
  display: none;
}

section.hero {
  height: 461px;
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  header {
    width: 100%;
  }

  .navbar {
    @include widthAndHeight(100%, 53px, null, null, absolute, null);

    .nav-item {
      @include widthAndHeight($breakpoint-lg, 25px, 15px, 0px, relative, 1);
      margin: 0px auto;
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  body #wrapper {
    margin: 0px auto;
    max-width: 100%;
  }

  .overlay {
    display: none;
  }

  header {
    @include widthAndHeight(100%, 140px, null, null, null, null);

    .container {
      padding: 0 18px;
    }
  }

  .navbar {
    @include widthAndHeight(100%, null, null, null, absolute, null);

    .nav-item {
      margin: 0px auto;
      @include widthAndHeight(1280px, 25px, 15px, 0px, relative, 1);

      ul {
        @include navFlexContent(flex, space-between, wrap, 1);
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  body #wrapper {
    margin: 0px auto;
    max-width: 100%;
  }

  .overlay {
    display: none;
  }

  header {
    margin: 0px auto;
    @include widthAndHeight($breakpoint-md, 135px, null, null, null, null);

    .container {
      padding: 0 27px;

      .btnGroup {
        gap: 10px;

        .profile-greeting {
          &:hover {
            width: 149px;

            .authenticated-profile-greeting-text {
              width: 115px;
            }

            .user-actions {
              width: 149px;
            }
          }
        }

        .btn-login-signup,
        .btn-view-notices,
        .btn-place-a-notice {
          width: 149px;
          height: 40px;
        }
      }
    }
  }

  .desktop-navbar {
    height: 53px;
    overflow: inherit;
  }

  .navbar {
    display: inline-block;
    margin: 0px auto;
    @include widthAndHeight(100%, 53px, 135px, 0px, inherit, 1);

    .nav-item {
      margin: 0px auto;
      @include widthAndHeight(714px, 20px, 15px, 0px, relative, 1);

      ul {
        @include navFlexContent(flex, space-between, wrap, 1);

        li {
          >a {
            padding: 17px 12px 15px;
            position: relative;
            bottom: 6px;
            font: normal normal normal 12px/36px $font-secondary;
          }
        }
      }
    }
  }
}

// For screen width less then 768px
@include for-md-down {
  body #wrapper {
    margin: 0 auto;
    max-width: $breakpoint-sm;
  }

  #header {
    height: 109px;
  }

  .desktop-navbar {
    display: none;
  }

  header {
    @include widthAndHeight($breakpoint-sm, 65px, null, null, fixed, null);
    border-bottom: 1px solid $secondary-dark;
    z-index: 99;

    .container {
      padding: 0 20px;
      align-items: center;

      a.logo {
        width: 138px;
        height: 33.62px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .btnGroup {
        align-items: flex-start;

        .hamburger {
          display: block;
          font-size: 24px;
          padding-bottom: 5px;
        }

        .btn-login-signup {
          width: 24px;
          height: 24px;
          background: url("./images/person.svg") no-repeat center;
          position: relative;

          &:hover {
            a {
              display: flex;
            }
          }

          a {
            height: 35px;
            width: 160px;
            color: $text-dark;
            background: $secondary-lightest;
            border-radius: 4px;
            border: 1px solid $secondary-dark;
            position: absolute;
            top: 35px;
            right: 0px;

            display: none;
            justify-content: center;
            align-items: center;

            &:hover {
              color: $secondary-lightest;
              background: $primary;
            }
          }
        }

        .profile-greeting {
          width: 24px;
          height: 24px;

          &:hover {
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: 50%;

            .authenticated-profile-greeting-text {
              display: none;
            }

            .user-actions {
              top: 35px;
              border-radius: 4px;
              border: 1px solid $secondary-dark;
            }
          }

          .authenticated-user-avatar {
            background-size: cover;
          }
        }

        .btn-view-notices,
        .btn-place-a-notice {
          display: none;
        }
      }
    }
  }

  .mb-search {
    @include widthAndHeight($breakpoint-sm, 43px, 65px, null, fixed, null);
    padding: 0 20px;
    background: $white;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: $text-dark;
      font: normal 400 16px/normal $font-secondary;
    }

    span.active {
      color: $text-color;
    }
  }

  .overlay {
    width: 100%;
    z-index: 9999999;

    .overlay-content {
      margin-top: 0;
      padding: 16px 20px;

      .overlay-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          height: 33.62px;
          width: 138px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .closeIcon {
          padding: 0 3px;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .mobile-navbar {
        margin-top: 35px;

        .overlay-btns {
          display: $flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;

          .btn-view-notices {
            width: 48%;
            height: 43px;
            border-radius: 21.5px;
            border: 1px solid $primary;

            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              @include buttonContent("View notices", $primary, 16px);
            }

            &:hover {
              background-color: $secondary-lightest;
            }
          }

          .btn-place-a-notice {
            width: 48%;
            height: 43px;
            background: $primary;
            border-radius: 21.5px;
            border: 1px solid $primary;

            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              @include buttonContent("Place a notice", $white, 16px);
            }

            &:hover {
              background-color: $primary-light;
            }
          }
        }

        .mobile-menu {
          height: calc(100vh - 195px);
          margin-top: 40px;
          overflow: auto;

          display: flex;
          flex-direction: column;
          gap: 20px;

          .menu-item-holder {
            border-bottom: 1px solid $secondary-dark;

            // placing funeral directors last in menu for mobile devices
            &:has(> a.menu-item-nav) {
              order: 5;
            }

            .menu-header-block {
              padding-bottom: 7px;

              display: flex;
              justify-content: space-between;
              align-items: center;

              h5 {
                text-align: left;
                color: $text-dark;
                font: 700 24px/normal $font-primary;
                cursor: pointer;
              }

              .arrow-down {
                width: 12px;
                height: 12px;
                margin-top: 7px;
                margin-right: 10px;
                border-top: 2px solid $secondary-dark;
                border-right: 2px solid $secondary-dark;
                -moz-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
                -webkit-transition: transform 0.5s;
                transform: rotate(135deg);
                transition: transform 0.5s;
              }
            }

            &.active {
              .menu-header-block {
                h5 {
                  border-bottom: none;
                }

                .arrow-down {
                  margin-top: 14px;
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
              }

              .menu-item-links {
                max-height: 10000px;
              }
            }

            .menu-item-links {
              max-height: 0;
              text-align: left;
              list-style: none;
              overflow: hidden;
              -webkit-transition: max-height 0.5s;
              transition: max-height 0.5s;

              li a {
                @include widthAndHeight (340px, 23px, 0, 0, relative, 1);
                font: normal 16px/36px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-color;
                opacity: 1;
              }
            }

            .menu-item-nav {
              color: $text-color;

              &::after {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }

            .submenu-item-angle {
              &::after {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg) !important;
              }
            }
          }
        }
      }
    }
  }
}

// For screen width less then 768px
@include for-sm-down {
  header {
    width: 100%;
  }

  .mb-search {
    width: 100%;
  }

  .overlay .overlay-content .mobile-navbar {
    .overlay-btns {

      .btn-view-notices,
      .btn-place-a-notice {
        width: 49%;

        &::after {
          font-size: 14px;
        }
      }
    }

    .mobile-menu .menu-item-holder .menu-header-block h5 {
      font-size: 20px;
    }
  }
}