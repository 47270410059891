.fd-recent-notices {
  width: 897px;
  padding: 10px;
  margin-bottom: 30px;
  position: relative;
  background: $secondary;
  border: 1px solid $secondary-dark;
  border-radius: $border-radius-sm;

  ::-webkit-scrollbar {
    width: 8px;
    height: 400px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $grey;
    border-radius: calc($border-radius - 2px);
  }

  ::-webkit-scrollbar-thumb {
    background: $grey;
    border-radius: calc($border-radius - 2px);
  }

  h3 {
    font: 700 calc($base-font-size + 8px)/34px $font-primary;
    color: $text-dark;
  }

  .h-line {
    border: 1px solid $secondary-dark;
  }

  .notices-carousel {
    height: 450px;
    margin-bottom: 30px;
    padding: 0 10px 10px;
    overflow: auto;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 25px;

    .notice-card {
      width: 22.5%;
      height: 268px;
      background: white;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

      .image-wrapper {
        height: 188px;
        background: white;
        overflow: hidden;

        .bg-blur {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: inherit;
        }

        img:hover {
          transform: scale(1.25);
          transition: transform .25s ease-in-out;
        }
      }

      h4 {
        width: 150px;
        font: 600 #{$base-font-size}/27px $font-secondary;
        color: $text-dark;
        margin-top: 5px;
        padding-left: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font: 400 #{$base-font-size}/27px $font-secondary;
        color: $secondary-dark;
        margin-top: 5px;
        padding-left: 15px;
      }
    }
  }

  .gradient-overlay {
    position: absolute;
    bottom: 40px;
    left: 16px;
    width: 95%;
    height: 25px;
    background: linear-gradient(to top, rgba(238, 229, 217, 1) 0%, rgba(238, 229, 217, 0) 100%);
  }
}

@include for-lg-down {
  .fd-recent-notices {
    width: 377px;
    padding-bottom: 0;

    .notices-carousel {
      display: block;
      height: auto;
      margin: 30px 20px;
      padding: 0;
      overflow: visible;

      .notice-card {
        min-width: 183px;
        max-width: 183px;
        height: 272px;
      }

      .card-prev {
        left: -18px;
        top: 100px;
        transform: none;
        border: 1px solid $primary;
        border-radius: 50%;
        z-index: 2;
      }

      .card-next {
        top: 100px;
        right: -18px;
        transform: none;
        border: 1px solid $primary;
        border-radius: 50%;
        z-index: 2;
      }
    }

    .gradient-overlay {
      width: 75%;
      bottom: 37%;
      left: 52%;
      transform: rotate(-90deg);
    }
  }
}

@include for-md-down {
  .fd-recent-notices {
    width: 390px;
  }
}

@include for-sm-down {
  .fd-recent-notices {
    width: 100%;
  }
}

@include for-xs-down {
  .fd-recent-notices {
    width: 100%;

    .gradient-overlay {
      display: none;
    }
  }
}