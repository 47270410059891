.funeral-director-info-premium {
    width: 860px;
    padding: 20px;
    background: $secondary 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin-bottom: 30px;


    .h-line {
        border: 0px;
        border-bottom: $bold 2px solid;
        opacity: 0.48;
        margin-top: 5px;
    }

    .ph-icon-premium {
        background: url("././images/phone.svg") no-repeat;
        padding-left: 30px;
        filter: invert(100%) sepia(94%) saturate(22%) hue-rotate(340deg) brightness(250%) contrast(106%);
        margin-left: 55px;
        background-size: 39px;
        padding: 30px;
    }

    .email-icon-premium {
        background: url("././images/share-envelope.svg") no-repeat;
        padding-left: 30px;
        filter: invert(100%) sepia(94%) saturate(22%) hue-rotate(340deg) brightness(250%) contrast(106%);
        margin-left: 55px;
        background-size: 45px;
        padding: 30px;

    }

    .home-icon-premium {
        background: url("././images/home.svg") no-repeat;
        padding-left: 30px;
        filter: invert(100%) sepia(94%) saturate(22%) hue-rotate(340deg) brightness(250%) contrast(106%);
        margin-left: 55px;
        background-size: 39px;
        padding: 30px;
    }



    h3 {

        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
    }


    .premium-info {
        padding: 15px 0px;

        .info-section-wrapper {

            display: $grid;
            grid-template-columns: repeat(4, 1fr);

            .info-section {
                border-radius: 8px;
                background: $dark-green 0% 0% no-repeat padding-box;
                width: 90%;
                padding: 15px;
                text-align: center;


                .info-headTxt {

                    text-align: center;
                    font: normal normal 600 18px/26px $font-secondary;
                    letter-spacing: 0px;
                    color: $white;

                }

                .info-subText1 {

                    text-align: center;
                    font: normal normal normal 18px/26px $font-secondary;
                    letter-spacing: 0px;
                    color: $white;
                    word-break: break-word;
                }

                .info-subText {

                    word-break: break-word;
                    text-align: center;
                    color: $white;

                    span {

                        color: $white;
                        font: normal normal normal 18px/26px $font-secondary;
                        letter-spacing: 0px;
                        cursor: pointer;

                    }
                }

            }

            .info-pic-content {
                text-align: right;
                .fd-website-logo{
                    cursor: pointer;
                    text-decoration: none;
                }
                img {
                    width: 100%;
                    height: 150px;
                    filter: none;
                    border-radius: unset;
                    object-fit: contain;
                }

                 
            }

        }

        .about-fd {

            padding: 10px 0px;

            h3 {
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
            }

            .about-fd-desc {
                margin-top: 30px;
                text-align: left;
                font: normal normal normal 18px/24px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                margin-bottom: 15px;
                word-wrap: break-word;
            }
        }
    }

}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {

    .funeral-director-info-premium {

        width: 380px;
        padding: $base-padding;

        .ph-icon-premium {
            background: url("././images/phone.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .email-icon-premium {
            background: url("././images/share-envelope.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 40px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .home-icon-premium {
            background: url("././images/home.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-bottom: 5px;
        }

        h3 {

            font: normal normal bold 24px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
        }

        .premium-info {
            .info-section-wrapper {

                width: 100%;
                height: auto;
                grid-template-columns: repeat(1, 1fr);

                .info-section {
                    width: 100%;
                    padding: 10px 20px 5px 20px;
                    margin-bottom: 15px;
                    border: 2px solid $dark-green;
                    border-radius: 40px;
                    text-align: left;

                    .info-headTxt {
                        width: 100%;
                        text-align: left;
                        font: normal normal 600 16px/22px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .info-subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 16px/22px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .info-subText {

                        text-align: left;
                        width: 100%;

                        span {
                            cursor: pointer;
                            text-align: left;
                            font: normal normal normal 16px/22px $font-secondary;
                            letter-spacing: 0px;
                        }


                    }


                }


                .info-pic-content {
                    float: none;
                    text-align: center;
                    .fd-website-logo{
                        cursor: pointer;
                        text-decoration: none;
                    }
                    img {
                        margin-left: 35%;
                        margin-top: 5%;
                        margin-bottom: 5%;
                        height: auto;
                        width: 60%;
                    }

                }


            }

            .about-fd {

                padding: 5px;

                .about-fd-desc {
                    margin-top: 25px;
                }

            }



        }

    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
    .funeral-director-info-premium {

        width: 380px;
        padding: 10px;

        .ph-icon-premium {
            background: url("././images/phone.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .email-icon-premium {
            background: url("././images/share-envelope.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 40px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .home-icon-premium {
            background: url("././images/home.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-bottom: 5px;
        }


        h3 {

            font: normal normal bold 24px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
        }


        .premium-info {
            .info-section-wrapper {

                width: 100%;
                height: auto;
                grid-template-columns: repeat(1, 1fr);

                .info-section {
                    width: 100%;
                    padding: 10px 20px 5px 20px;
                    margin-bottom: 15px;
                    border: 2px solid $dark-green;
                    border-radius: 40px;
                    text-align: left;

                    .info-headTxt {
                        width: 100%;
                        text-align: left;
                        font: normal normal 600 16px/22px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .info-subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 16px/22px $font-secondary;
                        letter-spacing: 0px;
                        word-break: break-word;
                    }

                    .info-subText {

                        text-align: left;
                        width: 100%;

                        span {
                            cursor: pointer;
                            text-align: left;
                            font: normal normal normal 16px/22px $font-secondary;
                            letter-spacing: 0px;
                            word-break: break-word;
                        }


                    }


                }


                .info-pic-content {
                    float: none;
                    text-align: center;
                    .fd-website-logo{
                        cursor: pointer;
                        text-decoration: none;
                    }
                    img {
                        margin-left: 0px;
                        margin-top: 5%;
                        margin-bottom: 10%;
                        height: auto;
                        width: 60%;
                    }

                }


            }

            .about-fd {

                padding: 5px;

                .about-fd-desc {
                    margin-top: 25px;
                }

            }



        }
    }
}



//When the screen width < 428px, render the 428px display
@include for-sm-down {
    .funeral-director-info-premium {

        width: 100%;
        padding: 10px;

        .ph-icon-premium {
            background: url("././images/phone.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .email-icon-premium {
            background: url("././images/share-envelope.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 40px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;

        }

        .home-icon-premium {
            background: url("././images/home.svg") no-repeat;
            padding-left: 30px;
            margin-left: 0px;
            background-size: 36px;
            padding: 20px;
            float: left;
            margin-right: 15px;
            margin-bottom: 5px;
        }


        h3 {

            font: normal normal bold 24px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
        }


        .premium-info {
            .info-section-wrapper {

                width: 100%;
                height: auto;
                grid-template-columns: repeat(1, 1fr);

                .info-section {
                    width: 100%;
                    padding: 10px 20px 5px 20px;
                    margin-bottom: 15px;
                    border: 2px solid $dark-green;
                    border-radius: 40px;
                    text-align: left;

                    .info-headTxt {
                        width: 100%;
                        text-align: left;
                        font: normal normal 600 16px/22px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .info-subText1 {
                        width: 100%;
                        text-align: left;
                        font: normal normal normal 16px/22px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .info-subText {

                        text-align: left;
                        width: 100%;

                        span {
                            cursor: pointer;
                            font: normal normal normal 16px/22px $font-secondary;
                            letter-spacing: 0px;
                        }


                    }


                }


                .info-pic-content {
                    float: none;
                    text-align: center;
                    .fd-website-logo{
                        cursor: pointer;
                        text-decoration: none;
                    }
                    img {
                        margin-left: 0px;
                        margin-top: 5%;
                        margin-bottom: 10%;
                        height: auto;
                        width: 60%;
                    }

                }


            }

            .about-fd {

                padding: 5px;

                .about-fd-desc {
                    margin-top: 25px;
                }

            }



        }

    }
}