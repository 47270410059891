/* Footer Scss */
footer.mt-footer {
  opacity: 1;
  margin: 0;
  font-family: $font-primary;
  text-align: left;
  width: $breakpoint-xl;
  background: $footer-bg-color 0% 0% no-repeat padding-box;
  border-top: $secondary-dark 2px solid;
  padding-bottom: 20px;

  .show-mbBtn-footer {
    display: none;
  }

  h4, h5 {
    font-size: 24px;
    color: $text-color;
    border-bottom: $secondary-dark 2px solid;
    margin: 0;
    padding: 25px 0 10px;
    border-bottom: $secondary-dark 2px solid;
    text-align: left;
    letter-spacing: 0.07px;
    color: $text-color;
    opacity: 1;
  }

  .centered {
    margin: 0px auto;
    display: $flex;
    justify-content: space-between;

    .footer-navigation {
      .partners-footer {
        width: 230px;

        li {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }

      .showOnTabView {
        display: none;
      }

      .showonMobileView {
        display: none;
      }

      .hideonMobileView {
        margin-bottom: $base-margin - 5;
      }

      input.email {
        background: $white;
        border-radius: 42px;
        height: 75px;
        padding: 6px 6px 6px 46px;
        width: 660px;
      }

      .showLegalMobileView {
        display: none;
      }

      input.btn.primary {
        position: absolute;
        top: 7px;
        right: 8px;
        margin: 0px;
        padding: 20px 55px;
        text-align: center;
      }

      img.slogo {
        margin-top: 15px;
      }

      .contact {
        width: 355px;

        .place-a-notice {
          width: 100%;
          padding-top: 26px;
          padding-left: 0px;

          .btn.primary {
            width: 100%;
            text-align: center;
            padding: 0 24px;
            cursor: pointer;
            &:hover{
              color: $white;
            }
          }
        }

        .newscorp-logo {
          position: relative;
          right: 11px;
          margin-bottom: -15px;
          img {
            &:hover {
              color:$primary-light;
            }
          }
        }

        .p_msg {
          border-top: 1px solid $bold;
          border-bottom: 1px solid $bold;
          font: normal normal normal 10px/17px $font-secondary;
          letter-spacing: 0.25px;
          padding: 6px 0;
          margin-bottom: $base-margin;
        }

        .showOnDtpView {
          margin-bottom: 5px;
          img {
            &:hover {
              color:$primary-light;
            }
          }
        }

        .copyrights {
          opacity: 1;
          font: normal normal normal 10px/17px $font-secondary;
          letter-spacing: 0.25px;
          color: $text-color;
          width: 290px;
        }

        .faq {
          letter-spacing: 0.03px;
          color: $text-color;
          opacity: 1;
          margin-bottom: 0;

          a {
            font: normal normal normal 16px/54px $font-secondary;
          }
        }

        .cont-phone {
          margin-bottom: $base-margin - 5;

          a {
            font: normal normal 600 26px/54px $font-secondary;
          }
        }
      }

      .footer-links-holder {
        /* width: 16.93%;*/
        position: relative;
        float: left;
        margin: 0;
        padding: 20px;
        min-width: 220px;

        img.faq-icon {
          width: 19px;
          height: 19px;
          vertical-align: $base-margin - 15;
          margin-right: $base-margin - 2;
        }

        img.ph-icon {
          width: 17px;
          height: 17px;
          vertical-align: $base-margin - 10;
          margin-right: $base-margin - 2;
        }

        .footer-links {
          margin: 0;
          padding: 0;
          list-style: none;
          -webkit-transition: max-height 0.5s;
          transition: max-height 0.5s;

          .no-lspace {
            line-height: 22px;
          }

        }
      }
    }
  }

}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  footer.mt-footer {
    width: 100%;
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  footer.mt-footer {
    width: $breakpoint-sm;
    height: auto;
    padding: 10px 0px 77px;

    .show-mbBtn-footer {
      display: block;

      a {
        @include widthAndHeight(382px, 65px, null, null, null, null);
        margin: 23px auto;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 0px;
        text-align: center;
        &:hover {
          color: $white;
        }
      }
    }

    .centered {
      width: 100%;

      .footer-navigation {
        .showLegalMobileView {
          display: block;
        }

        .partners-footer {
          width: 100%;

          li {
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }

        .contact {
          width: 270px;

          .showonMobileView {
            display: block;

            .clearfix {
              .place-a-notice {
                padding: 20px;

                .btn.primary {
                  margin: 30px;
                  width: 80%;
                  display: inline-block;
                  text-align: center;
                }
              }
            }
          }

          .place-a-notice {
            display: none;
          }

          .p_msg {
            border-top: 2px solid $secondary-dark;
            border-bottom: 2px solid $secondary-dark;
            opacity: 0.8;
            font-size: 10px;
            letter-spacing: 0.25px;
            margin: 0px;
            padding: 17px 0px 5px 0px;
          }

          .copyrights {
            opacity: 0.8;
            font-size: 10px;
            letter-spacing: -0.22px;
            color: $text-color;
            opacity: 1;
            width: 100%;
          }

          .faq {
            text-align: left;
            font: normal normal 600 20px/54px $font-secondary;
            letter-spacing: 0.04px;
            color: $text-color;
            margin-bottom: $base-margin - 10;

            a {
              font: normal normal 600 20px/54px $font-secondary;
            }
          }

          img.faq-icon {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            vertical-align: $base-margin - 17;
          }

          .cont-phone {
            a {
              text-align: left;
              letter-spacing: 0.06px;
              color: $text-color;
              font: normal normal bold 20px/54px $font-secondary;
            }
          }

          img.ph-icon {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            vertical-align: $base-margin - 15;
          }
        }

        img.slogo {
          width: 34px;
          height: 34px;
          margin-right: 14px;
        }

        .footer-links-holder {
          width: 100%;
          margin-top: 7px;
          padding: 0px 25px;
          position: relative;
          top: 10px;

          img.m-slogo {
            display: block;
            width: 34px;
            height: 34px;
          }

          .footer-links {
            margin: 0;
            padding: 0;
            list-style: none;
            -webkit-transition: max-height 0.5s;
            transition: max-height 0.5s;
            max-height: 0;
            overflow: hidden;
            padding: 0px 20px;

            .no-lspace {
              line-height: 18px;
            }

            a {
              font: normal normal 300 16px/36px $font-secondary;
            }
          }

          h4, h5 {
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: 0.08px;
            color: $text-color;
            border-bottom: $secondary-dark 2px solid;
            margin: 0;
            padding: 25px 0 10px;
            cursor: pointer;
            text-align: left;

            /* Down Arrows */
            &::after {
              float: right;
              margin-right: 10px;
              content: '';
              display: inline-block;
              vertical-align: middle;
              margin-top: 7px;
              width: 14px;
              height: 13px;
              border-top: 3px solid $secondary-dark;
              border-right: 3px solid $secondary-dark;
              opacity: 1;
              -moz-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              -webkit-transition: transform 0.5s;
              transition: transform 0.5s;
            }
          }

          .hideonMobileView {
            position: absolute;
            display: none;
            clear: both;
          }

          &.active {
            h4, h5 {
              &::after {
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }

            li.partner_flink {
              width: 190px;
            }

            .footer-links {
              max-height: 10000px;
            }
          }
        }
      }
    }
  }
}

/* Typography */
footer.mt-footer {
  p {
    text-align: left;
    font: normal normal normal 12px/17px $font-secondary;
    letter-spacing: -0.22px;
    color: $text-color;
    opacity: 1;
  }

  p,
  li {
    letter-spacing: 0.05px;
  }

  a {
    letter-spacing: 0px;
    color: $text-color;
    font: normal normal normal 18px/54px $font-secondary;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      color:$primary-light;
    }
  }

  .partners-footer {
    li {
      a {
       line-height: 22px !important;

      }
    }

  }
}

/* Misc CSS */
.clearfix {

  &:before,
  &:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    clear: both;
  }

  .btn {
    padding: 0 12px;
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  footer.mt-footer {
    width: 100%;

    .centered {
      flex-wrap: wrap;

      .footer-navigation {
        .partners-footer {
          width: 210px;

          li {
            margin-top: 20px;
            margin-bottom: 30px;
          }
        }

        .contact {
          width: 292px;

          .p_msg,
          .copyrights {
            font: normal normal normal 10px/17px $font-secondary;
            letter-spacing: -0.22px;
            width: 272px;
          }

          .faq {
            a {
              font: normal normal normal 16px/54px $font-secondary;
            }
          }

          img.slogo {
            width: 25px;
            height: 25px;
          }

          img.faq-icon {
            width: 17px;
            height: 17px;
            margin-right: $base-margin - 6;
            vertical-align: $base-margin - 12;
          }

          img.ph-icon {
            width: 17px;
            height: 17px;
            margin-right: $base-margin - 8;
            vertical-align: $base-margin - 10;
          }

          img.newscorp-logo {
            margin-left: 0px;
            width: 261px;
          }

          .showOnTabView {
            display: none;
          }

          .showonMobileView {
            display: none;
          }
        }

        .footer-links-holder {
          padding: 0 5px;

          h4, h5 {
            font: normal normal bold 25px/33px $font-primary;
          }

          .footer-links {
            a {
              font: normal normal 300 18px/54px $font-secondary;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  footer.mt-footer {
    width: 100%;

    .centered {
      flex-wrap: wrap;

      .footer-navigation {
        .partners-footer {
          width: 140px;

          li {
            margin-top: 10px;
            margin-bottom: 15px;
          }
        }

        .contact {
          width: 165px;

          .p_msg,
          .copyrights {
            font: normal normal normal 10px/17px $font-secondary;
            letter-spacing: -0.22px;
            width: 160px;
            padding: 6px 0px 15px;
            margin: 0px;
          }

          .p_msg {
            border-bottom: 2px solid $secondary-dark;
            border-top: 2px solid $secondary-dark;
          }

          img.newscorp-logo {
            margin-left: 0px;
            width: 159px;
            right: 5px;
          }

          .place-a-notice {
            padding-top: 10px;

            .btn.primary {
              font: normal normal 600 14px/21px $font-secondary;
              padding: 10px;
            }
          }

          .showOnTabView {
            display: block;
            margin-top: 18px;

            .btn {
              width: 100%;
              font: normal normal 600 14px/21px $font-secondary;
              text-align: center;
              padding: 10px 55px;
            }
          }

          .cont-phone {
            margin-top: $base-margin + 11;

            a {
              img {
                width: 18px;
                height: 18px;
                vertical-align: $base-margin - 13;
              }

              font: normal normal bold 15px/31px $font-secondary;
              letter-spacing: 0.04px;
            }
          }

          .showOnDtpView {
            display: none;
          }

          .showonMobileView {
            display: none;
          }
        }

        .footer-links-holder {
          padding: 0 5px;
          min-width: 0px;

          h4, h5 {
            font: normal normal bold 16px/21px $font-secondary;
          }

          .footer-links {
            a {
              font: normal normal 600 12px/34px $font-secondary;
            }
          }
        }
      }
    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  footer.mt-footer {
    width: 100%;
    height: auto;
    padding: 10px 0px 100px;

    .show-mbBtn-footer {
      display: block;

      a {
        @include widthAndHeight(88%, 65px, null, null, null, null);
        display: flex;
        justify-content: center;
        align-items: center;
        margin:  $base-margin * 2 + 3 auto;
        padding: 0px;
        text-align: center;
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }

    .centered {
      width: 100%;

      .footer-navigation {
        .showLegalMobileView {
          display: block;
        }

        .partners-footer {
          width: 100%;

          li {
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }

        .contact {
          width: 270px;

          .showonMobileView {
            display: block;

            .clearfix {
              .place-a-notice {
                padding: 20px;

                .btn.primary {
                  margin: 30px;
                  width: 80%;
                  display: inline-block;
                  text-align: center;
                }
              }
            }
          }

          .place-a-notice {
            display: none;
          }

          .p_msg {
            border-top: 2px solid $secondary-dark;
            border-bottom: 2px solid $secondary-dark;
            opacity: 0.8;
            font-size: 10px;
            letter-spacing: 0.25px;
            margin: 0px;
            padding: 17px 0px 5px 0px;
          }

          .copyrights {
            opacity: 0.8;
            font-size: 10px;
            letter-spacing: -0.22px;
            color: $text-color;
            opacity: 1;
            width: 100%;
          }

          .faq {
            text-align: left;
            font: normal normal 600 20px/54px $font-secondary;
            letter-spacing: 0.04px;
            color: $text-color;
            margin-bottom: $base-margin - 10;

            a {
              font: normal normal 600 20px/54px $font-secondary;
            }
          }

          img.faq-icon {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            vertical-align: $base-margin - 17;
          }

          .cont-phone {
            a {
              text-align: left;
              letter-spacing: 0.06px;
              color: $text-color;
              font: normal normal bold 20px/54px $font-secondary;
            }
          }

          img.ph-icon {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            vertical-align: $base-margin - 15;
          }
        }

        img.slogo {
          width: 34px;
          height: 34px;
          margin-right: 14px;
        }

        .footer-links-holder {
          width: 100%;
          margin-top: 7px;
          padding: 0px 25px;
          position: relative;
          top: 10px;

          img.m-slogo {
            display: block;
            width: 34px;
            height: 34px;
          }

          .footer-links {
            margin: 0;
            padding: 0;
            list-style: none;
            transition: max-height 0.5s;
            max-height: 0;
            overflow: hidden;
            padding: 0px 20px;

            .no-lspace {
              line-height: 18px;
            }

            a {
              font: normal normal 300 16px/36px $font-secondary;
            }
          }

          h4, h5 {
            font: normal normal bold 26px/35px $font-primary;
            letter-spacing: 0.08px;
            color: $text-color;
            border-bottom: $secondary-dark 2px solid;
            margin: 0;
            padding: 25px 0 10px;
            cursor: pointer;
            text-align: left;

            /* Down Arrows */
            &::after {
              float: right;
              margin-right: 10px;
              content: '';
              display: inline-block;
              vertical-align: middle;
              margin-top: 7px;
              width: 14px;
              height: 13px;
              border-top: 3px solid $secondary-dark;
              border-right: 3px solid $secondary-dark;
              opacity: 1;
              transform: rotate(135deg);
              transition: transform 0.5s;
            }
          }

          .hideonMobileView {
            position: absolute;
            display: none;
            clear: both;
          }

          &.active {
            h4, h5 {
              &::after {
                transform: rotate(-45deg);
              }
            }

            li.partner_flink {
              width: 190px;
            }

            .footer-links {
              max-height: 10000px;
            }
          }
        }
      }
    }
  }
}


