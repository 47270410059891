.container{
  margin: 0 auto;


  &.col-2{
    display:flex;
    &>*{width:50%;}
    .content{h3{text-align: left;
      font: normal normal bold 40px/53px $font-primary;
      letter-spacing: 0.12px;
      color:$text-color;
      opacity: 1;
      margin-left: 100px;
    margin-top: 145px;}
    p{text-align: left;
      font: normal normal normal 18px/31px $font-secondary;
      letter-spacing: 0.05px;
      color:$text-color;
      opacity: 1;
    margin-left: 100px;}
  a{margin-left: 100px;}}
  }
}

.centered {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.split-header{
  display: flex;
  justify-content: space-between;
  h1,h2,h3{margin-right:auto;}
}

.container1{
  padding: 0 336px;
  margin: 0 auto;
  padding-bottom: 136px;
}
