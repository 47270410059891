.pagination {
  flex: 1;

  display: flex;
  justify-content: space-around;
  align-items: center;

  ul {
    display: inline-block;

    .pagination-gap {
      width: 22px;
      padding-top: 26px;
      border: none;
      font-size: 30px;

      &:hover {
        background-color: $white;
        color: $primary
      }
    }

    li {
      height: 52px;
      list-style: none;
      float: left;
      text-decoration: none;
      transition: .3s;
      border: 3px solid $primary;
      margin: 0 5px;
      display: block;
      text-align: center;
      color: $primary;

      &.active {
        background: $primary;
        color: $white;
      }

      &:hover {
        color: $white;
        background-color: $primary;
      }

      a {
        font-weight: bold;
        font-family: $font-secondary;
        font-size: 25px;
        letter-spacing: 0px;
        text-decoration: none;
        color: inherit;
        display: block;
        min-width: 46px;
        padding: 10px 8px;

        span {
          &:first-child {
            font-size: 52px;
            font-weight: 100;
            line-height: 0.9
          }

          &:last-child {
            font-size: 60px;
            font-weight: 100;
            line-height: 0.7;
          }
        }
      }

      &:first-child {
        padding-top: 0px;

        height: 52px;
        border-radius: 62px 0px 0px 62px;

        a {
          padding: 0px 10px;
        }
      }

      &:last-child {
        height: 52px;
        border-radius: 0px 62px 62px 0px;
        font-size: 28px;
        font-weight: normal;

        a {
          padding: 0px 10px;
        }
      }
    }
  }

  .result-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .resultDropDown {
      width: 179px;
      height: 50px;
      padding-left: 15px;
      color: $text-dark;
      font: 400 $base-font-size/normal $font-secondary;
      background: $secondary url("./images/search-down.svg") no-repeat calc(100% - 15px);
      border-radius: 100px;
      border: 1px solid $secondary-dark;
      outline: none;

      &::after {
        position: relative;
        top: 10px;
        color: $text-dark;
        font: 400 $base-font-size/normal $font-secondary;
      }
    }

    .backto-Top {
      transform: rotate(-90deg);
      cursor: pointer;

      &:hover {
        filter: opacity(0.7);
      }
    }
  }
}

#article-listing-pagination {
  .container {
    margin-top: 36px;
    margin-bottom: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px $base-padding;

    a.newer {
      display: inline-flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: $secondary-lightest;
      border-radius: 62px 0px 0px 62px;
      border: 3px solid $primary;
      width: 148px;
      height: 52px;
      font: normal normal 600 25px/38px $font-secondary;
      color: $primary;
      padding-top: 2px;
      margin-right: auto;
      order: 1;

      .newer-lt {
        font-size: 50px;
        font-weight: 100;
        padding-left: 5px;
      }

      &:hover {
        background-color: $primary-lightest;
      }
    }

    h2 {
      font: normal normal 600 25px/38px $font-secondary;
      color: $primary;
      text-align: center;
      display: inline;
      order: 2;
      flex: 1;

    }

    a.older {
      display: inline-flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: $secondary-lightest;
      border-radius: 0px 62px 62px 0px;
      border: 3px solid $primary;
      width: 148px;
      height: 52px;
      padding-top: 2px;
      order: 3;
      margin-left: auto;
      font: normal normal 600 25px/38px $font-secondary;
      color: $primary;

      .older-gt {
        font-size: 50px;
        font-weight: 100;
        padding-right: 5px;
      }

      &:hover {
        background-color: $primary-lightest;
      }
    }
  }
}

@include for-lg-only {
  #article-listing-pagination {
    .container {
      padding: 0px $base-padding * 2 + 7px;
    }
  }
}

@include for-md-only {
  .pagination {
    .page-navigation {
      left: 0px;

      .pagination-gap {
        width: 18px;
      }

      li {
        margin: 0 4px;

        &:first-child {
          min-width: 61px;
        }

        &:last-child {
          min-width: 61px;
        }
      }
    }

    .result-controls {
      display: none;
    }
  }

  #article-listing-pagination {
    .container {
      margin-top: 36px;
      margin-bottom: 69px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px $base-padding * 2;

      a.newer {
        width: 100px;
        height: 42px;
        font: normal normal 600 18px/38px $font-secondary;
        color: $primary;
        padding-top: 2px;

        .newer-lt {
          font-size: 40px;
          font-weight: 100;
          padding-left: 0px;
        }
      }

      h2 {
        font: normal normal 600 18px/38px $font-secondary;
        color: $primary;
        text-align: center;
        display: inline;
      }

      a.older {
        width: 100px;
        height: 42px;
        font: normal normal 600 18px/38px $font-secondary;
        color: $primary;
        padding-top: 2px;

        .older-gt {
          font-size: 40px;
          font-weight: 100;
          padding-right: 0px;
        }
      }
    }
  }
}

@include for-sm-down {
  .pagination {
    ul li {
      margin: 0 4px;
    }

    .result-controls {
      display: none;
    }
  }
}