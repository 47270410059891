@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$card-font-path: "fonts/" !default;
$card-font-family: "slick" !default;
$card-loader-path: "fonts/" !default;
$card-arrow-color: red !default;
$card-dot-color: red !default;
$card-dot-color-active: $card-dot-color !default;
$card-prev-character: "<" !default;
$card-next-character: ">" !default;
$card-dot-character: "\2022" !default;
$card-dot-size: 50px !default;
$card-opacity-default: 0.75 !default;
$card-opacity-on-hover: 1 !default;
$card-opacity-not-active: 0.25 !default;
 

/* Arrows */

.card-prev,
.card-next {
    position: absolute;
    bottom: -80px;
    display: block;
    height: 52px;
    width: 52px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer; 
    color:$black;
     
     
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        outline: none; 
        color:$black;
        &:before {
            opacity: $card-opacity-on-hover;
        }
    }
    &.card-disabled:before {
        opacity: $card-opacity-not-active;
    }
    &:before {
        font-family: $card-font-family;
        font-size: 20px;
        line-height: 1;
        color: $card-arrow-color;
        opacity: $card-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.card-prev {
    left: 30%;
    [dir="rtl"] & {
        left: auto;
        right: 30%;
    }
   
}

.card-next {
    
      right: 30%;
    [dir="rtl"] & {
        left: 30%;
        right: auto;
    }
     
}

/* Dots */

.card-dotted.card-slider {
    margin-bottom: 30px;
}

.card-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $card-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $card-dot-character;
                width: 20px;
                height: 20px;
                font-family: $card-font-family;
                font-size: $card-dot-size;
                line-height: 20px;
                text-align: center;
                color: $card-dot-color;
                opacity: $card-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.card-active button:before {
            color: $card-dot-color-active;
            opacity: $card-opacity-default;
        }
    }
}
