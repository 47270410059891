section {
  height: auto;
}

section.place-a-notice {
  background: $primary;
  text-align: center;
  color: $white;
  padding: 40px 0;
  width: 100%;
  height: 527px;

  h2 {
    position: relative;
    height: 100px;
    text-align: center;
    font: normal normal bold 40px/51px $font-primary;
    letter-spacing: 0px;
    color: $white;
    opacity: 1;
    margin-top: 160px;
  }

  a {
    font: normal normal 600 18px/27px $font-secondary;
    text-align: center;
    margin: 0 auto;
    background: $primary-lightest 0% 0% no-repeat padding-box;
    border-radius: 38px;
    opacity: 1;
    width: 254px;
    height: 65px;
    cursor: pointer;


    &:hover {
      background-color: $primary-light;
      color: $white;
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{

}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.place-a-notice {
    background: $primary;
    text-align: center;
    color: $white;
    width: $breakpoint-sm;
    height: 412px;

    h2 {
      @include widthAndHeight($breakpoint-sm, 43px, null, null, null, null);
      text-align: center;
      font: normal normal bold 32px/51px $font-primary;
      letter-spacing: 0px;
      color: $white;
      margin-top: 103px;
    }

    a {
      @include widthAndHeight (360px, 65px, null, null, null, null);
      text-align: center;
      font: normal normal 600 18px/27px $font-secondary;
      letter-spacing: 0px;
      color: $primary;
      border-radius: 38px;
      cursor: pointer;
      margin-top: 21px;
      &:hover {
        background-color: $primary-light;
        color: $white;
      }
    }
  }


}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.place-a-notice {
    h2 {
      height: 80px;
    }
  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  section.place-a-notice {
    h2 {
      height: 80px;
    }

  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.place-a-notice {
    background: $primary;
    text-align: center;
    color: $white;
    width: 100%;
    height: 412px;

    h2 {
      @include widthAndHeight(100%, 43px, null, null, null, null);
      text-align: center;
      font: normal normal bold 32px/51px $font-primary;
      letter-spacing: 0px;
      color: $white;
      margin-top: 103px;
    }

    a {
      @include widthAndHeight (90%, 65px, null, null, null, null);
      text-align: center;
      font: normal normal 600 18px/27px $font-secondary;
      letter-spacing: 0px;
      color: $primary;
      border-radius: 38px;
      cursor: pointer;
      margin: $base-margin * 2 + 1 auto;
      &:hover {
        background-color: $primary-light;
        color: $white;
      }
    }
  }
}
