.fd-slide-up {
  animation: slideUp .25s ease-out forwards;
}

.fd-slide-down {
  animation: slideDown .25s ease-out forwards;
}

@keyframes mapSlideInFromRight {
  0% {
    transform: translateX(80%) translateY(-20%);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes mapSlideOutFromRight {
  0% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(80%) translateY(-20%);
  }
}

@keyframes mapSlideUp {
  0% {
    opacity: 1;
    height: 610px;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes mapSlideDown {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 610px;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(305px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateX(0);
  }
}

.fd-listing-header-wrapper #pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 300px;
}

.fd-listing-wrapper {
  width: $breakpoint-lg;
  margin: 0 auto;
  padding: 30px 12px;
  overflow: unset;

  display: $flex;
  justify-content: space-between;
  gap: 30px;

  .hideElements {
    visibility: hidden;
  }

  .no-results-found {
    p {
      line-height: 1.3em;
      margin-top: 0.2em;
    }

    a {
      display: inline;
      text-decoration: none;
      color: $font-primary;
      font-family: $font-bold;
      font-weight: 500;
    }
  }

  .fd-left-column {
    width: 315px;

    #fd-desktop-ad {
      .container {
        svg {
          width: 315px;
          height: 600px;
          background: $gray-triplet 0% 0% no-repeat padding-box;
          padding: 10px;

          rect {
            width: 310px;
            height: 600px;
            fill: $background-pink;
          }
        }
      }
    }

    .refine-search-filter {
      margin-bottom: 20px;
    }
  }

  .fd-right-column {
    flex: 1;

    .fd-right-header {
      margin-bottom: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0;
        color: $text-dark;
        font: bold 40px/1 $font-primary;
      }

      .fd-sort-icon-groups {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .location-sort-icon {
          background: url("./images/location-sort.svg") no-repeat center;
        }

        .calender-atoz-sort-icon {
          background: url("./images/a-z.svg") no-repeat center;
        }

        .calender-ztoa-sort-icon {
          background: url("./images/z-a.svg") no-repeat center;
        }

        .location-sort-icon,
        .calender-atoz-sort-icon,
        .calender-ztoa-sort-icon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid $primary;

          &.active {
            background-color: $light-secondary;
          }
        }

        .map-show-button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 15px;

          width: 204px;
          height: 50px;
          color: $primary;
          background: inherit;
          font: 600 18px/normal $font-secondary;
          border-radius: 100px;
          border: 1px solid $primary;
          cursor: pointer;

          &.active {
            background: $light-secondary;
          }

          .map-icon {
            width: 18px;
            height: 18px;
            background: url("././images/map-show-icon.svg") no-repeat center / contain;
          }
        }
      }
    }

    .map-container {
      position: relative;
      height: 305px;
      margin-bottom: 30px;

      &.open-map-animation {
        animation: mapSlideInFromRight .25s ease-out forwards;
      }

      &.close-map-animation {
        animation: mapSlideOutFromRight .25s ease-out forwards;
      }

      &.hide {
        display: none;
      }

      .map {
        height: 100%;
        border-radius: $border-radius-sm;
        border: 1px solid $slate-gray;
      }

      .active-funeral-director {
        width: 364px;
        height: 89px;
        padding: 5px;
        overflow: hidden;
        border-radius: $border-radius-sm;
        background: $secondary-lightest;
        border: 1px solid $secondary-dark;

        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        align-items: center;
        gap: 7px;

        .active-fd-logo {
          width: 79px;
          height: 79px;
          border-radius: $border-radius-sm;
          border: 1px solid $secondary-dark;
        }

        .active-funeral-director-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 5px;

          h6 {
            width: 265px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $text-dark;
            font: 600 18px/normal $font-primary;
          }

          .active-fd-location {
            color: $secondary-dark;
            font: 400 14px/normal $font-secondary;

            display: flex;
            align-items: center;
            gap: 3px;

            .active-fd-location-icon {
              display: inline-block;
              height: 15px;
              width: 12.352px;
              background: url("./images/map-pin.svg") no-repeat center/contain;
            }

            .active-fd-location-text {
              width: 245px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .active-fd-view-profile {
            cursor: pointer;
            color: $primary;
            text-decoration: underline;
            font: 400 14px/normal $font-secondary;

            display: flex;
            align-items: center;
            gap: 5px;

            .right-arrow-icon {
              display: inline-block;
              height: 8px;
              width: 12px;
              background: url("./images/right-arrow.svg") no-repeat center/cover;
            }
          }
        }
      }
    }

    .fd-header {
      display: grid;
      margin-bottom: 30px;
      grid-template-columns: 350px 165px 380px;
      z-index: 0;

      .tributes-sort {
        display: block;
        margin-top: 4px;
      }

      h2 {
        font: normal normal bold 40px/53px $font-primary;
        letter-spacing: 0.12px;
        color: $text-color;
        text-align: left;
      }

      span {
        @include widthAndHeight(134px, 28px, 0px, 0px, null, 1);
        text-align: center;
        color: $text-dark;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: 16px;
        padding-top: 6px;
        margin: 18px 10px 0px 0px;
        font: normal normal 300 18px/54px $font-secondary;
        line-height: unset;
      }
    }

    .fd-feature-content {
      margin-bottom: 20px;
      padding: 20px;
      background: $secondary;
      border-radius: $border-radius-sm;
      border: 1px solid $secondary-dark;

      h3 {
        height: 45px;
        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
        border-bottom: 2px solid $lightest-brown;
      }

      .featured-list {
        padding: 20px 0;

        display: $grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        row-gap: 40px;

        .fd-list-item {
          width: 270px;
          position: relative;

          display: flex;
          flex-direction: column;
          gap: 20px;

          &:has(.afda-member-badge) .fd-item-banner {
            border: 1px solid $primary;
          }

          .fd-item-banner {
            width: 100%;
            height: 270px;
            background: $white;
            border-radius: 5px;
            border: 1px solid $secondary-dark;
            box-shadow: 0 1px 3px $light-black;
            overflow: hidden;

            a {
              .image-wrapper {
                border-radius: 5px;
                overflow: hidden;

                .featured-banner-img-card {
                  height: 0;
                  margin: -6px;
                  padding-top: calc(100% + 12px);
                  border-radius: 3px 3px 0 0;
                  position: relative;
                  overflow: hidden;

                  display: $flex;
                  align-items: center;

                  img {
                    width: 100%;
                    height: auto;
                    margin-top: -100%;
                    border-radius: 5px;
                    object-fit: contain;
                    mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                    transition: transform .25s ease-in-out;

                    &:hover {
                      transform: scale(1.04);
                      transition: $allease-in-out;
                    }
                  }
                }
              }
            }
          }

          .afda-member-badge {
            width: 103px;
            height: 22px;
            color: $primary;
            background: $light-secondary;
            text-align: center;
            font: 600 12px/22px $font-secondary;
            border-radius: 100px;

            position: absolute;
            top: 5px;
            right: 5px;
          }

          .fd-item-details {
            flex: 1;
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 15px;

            h5 {
              color: $text-dark;
              font: 600 18px/1 $font-secondary;
            }

            .fd-location {
              padding-left: 25px;
              color: $light-grey;
              font: 400 18px/1 $font-secondary;
              background: url("././images/map-pin.svg") no-repeat 0 center / 15px auto;
              filter: brightness(0) saturate(100%) invert(56%) sepia(20%) saturate(256%) hue-rotate(184deg) brightness(93%) contrast(87%);
            }
          }
        }
      }
    }

    .fd-std-content {
      margin-bottom: 30px;
      border-radius: $border-radius-sm;

      h3 {
        height: 45px;
        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
      }

      .standard-list {
        display: $grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        row-gap: 40px;

        .std-list-item {
          width: 270px;
          position: relative;

          display: flex;
          flex-direction: column;
          gap: 20px;

          &:has(.afda-member-badge) .std-item-banner {
            border: 1px solid $primary;
          }

          .std-item-banner {
            width: 100%;
            aspect-ratio: 1 / 1;
            background: $white;
            border-radius: 5px;
            border: 1px solid $secondary-dark;
            box-shadow: 0 1px 3px $light-black;
            overflow: hidden;

            a {
              .image-wrapper {
                border-radius: 5px;
                overflow: hidden;

                .std-banner-img-card {
                  height: 0;
                  margin: -6px;
                  padding-top: calc(100% + 12px);
                  border-radius: 3px 3px 0 0;
                  overflow: hidden;
                  position: relative;

                  display: $flex;
                  align-items: center;

                  img {
                    width: 100%;
                    height: auto;
                    margin-top: -100%;
                    border-radius: 5px;
                    mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                    object-fit: contain;
                    transition: transform .25s ease-in-out;

                    &:hover {
                      transform: scale(1.04);
                      transition: $allease-in-out;
                    }
                  }
                }
              }
            }
          }

          .afda-member-badge {
            width: 103px;
            height: 22px;
            color: $primary;
            background: $light-secondary;
            text-align: center;
            font: 600 12px/22px $font-secondary;
            border-radius: 100px;

            position: absolute;
            top: 5px;
            right: 5px;
          }

          .std-item-details {
            flex: 1;
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 15px;

            h5 {
              color: $text-dark;
              font: 600 18px/1 $font-secondary;
            }

            .std-location {
              padding-left: 25px;
              color: $light-grey;
              font: 400 18px/1 $font-secondary;
              background: url("././images/map-pin.svg") no-repeat 0 center / 15px auto;
              filter: brightness(0) saturate(100%) invert(56%) sepia(20%) saturate(256%) hue-rotate(184deg) brightness(93%) contrast(87%);
            }
          }
        }
      }
    }
  }
}

// when the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  .fd-listing-wrapper .fd-right-column {
    .fd-header {
      grid-template-columns: 356px 195px 388px;
    }

    .fd-feature-content .featured-list,
    .fd-std-content .standard-list {
      &:has(> div:nth-child(2):last-child) {
        &::after {
          content: '';
          width: 270px;
        }
      }
    }
  }
}

// when the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .fd-listing-wrapper {
    padding: 30px 18px;

    .fd-right-column {

      .fd-feature-content .featured-list,
      .fd-std-content .standard-list {
        &:has(> div:nth-child(2):last-child) {
          &::after {
            content: '';
            width: 270px;
          }
        }
      }
    }
  }
}

// when the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .fd-listing-header-wrapper #pagination {
    margin-left: 0;
  }

  .fd-listing-wrapper {
    width: 714px;
    padding: $base-padding * 3 0;
    gap: 18px;

    .fd-left-column #fd-desktop-ad {
      .container svg {
        width: 310px;

        rect {
          width: 305px;
        }
      }
    }

    .fd-right-column {
      .fd-right-header {
        margin-bottom: 16px;

        flex-direction: column;
        gap: 24px;

        div:has(h1) {
          align-self: flex-start;
        }

        h1 {
          font: 700 32px/1 $font-primary;
        }

        .fd-sort-icon-groups {
          width: 100%;

          .map-show-button {
            align-self: flex-end;
            width: 153px;
          }
        }
      }

      .map-container {
        height: 305px;
      }

      .fd-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 15px;

        .tributes-sort {
          display: block;
          margin-top: 4px;
        }

        h2 {
          @include widthAndHeight(291px, 40px, 0px, 0px, null, 1);
          font: normal normal bold 30px/53px $font-primary;
          letter-spacing: 0.09px;
          grid-column: 1/span 3;
        }

        span {
          @include widthAndHeight(134px, 28px, 0px, 0px, null, 1);
          border-radius: 13px;
          padding-top: 6px;
          margin: 15px 0px 0px 0px;
          font: normal normal 300 16px/54px $font-secondary;
          line-height: unset;
        }
      }

      .fd-feature-content {
        padding: 10px;
        background: $secondary;
        border-radius: $border-radius-sm;

        h3 {
          height: 45px;
          font: normal normal bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
        }

        .featured-list {
          grid-template-columns: repeat(2, 0fr);

          .fd-list-item {
            width: 175px;
            gap: 18px;

            .fd-item-banner {
              height: 175px;
            }
          }
        }
      }

      .fd-std-content .standard-list {
        grid-template-columns: repeat(2, auto);

        .std-list-item {
          width: 185px;
          gap: 18px;
        }
      }
    }
  }
}

// for screen width less than 768px
@include for-md-down {
  .fd-listing-header-wrapper #navbar-search-filter {
    display: $block;
  }

  .fd-listing-header-wrapper #pagination {
    margin-left: 0;

    .pagination ul {
      position: relative;
      left: 0;
    }
  }

  .fd-listing-header-wrapper .fd-listing-wrapper .mb-header-mt,
  .fd-listing-header-wrapper .fd-listing-wrapper .fd-right-column .mb-header-mt {
    margin-top: 0;
  }

  .fd-listing-wrapper {
    display: block;
    width: $breakpoint-sm;
    padding: 0;
    overflow: hidden;

    #navbar-search-filter .navbar-search-filter .container {
      display: none;
    }

    .no-results-found {
      padding: 10px;
    }

    .fd-left-column {
      display: block;
      width: 100%;
      margin: 0;

      #fd-desktop-ad {
        display: none;
      }
    }

    .fd-right-column {
      order: 1;
      width: 100%;
      margin-top: 25px;
      padding: 0 20px;

      .fd-right-header {
        margin-bottom: 30px;

        gap: 20px;
        flex-direction: column;

        h1 {
          text-align: center;
        }

        .fd-sort-icon-groups {
          width: 100%;
          height: 43px;
          overflow: hidden;
          border-radius: 32px;
          border: 1px solid $primary;

          gap: 0;
          justify-content: space-between;

          .location-sort-icon,
          .calender-atoz-sort-icon,
          .calender-ztoa-sort-icon {
            flex: 1;
            border-radius: 0;
            border: none;
            border-right: 1px solid $primary;
          }

          .map-show-button {
            flex: 1;
            border: none;
            border-radius: 0;

            span {
              display: none;
            }
          }
        }
      }

      .map-container {
        height: 530px;

        &.open-map-animation {
          animation: mapSlideDown .25s ease-out forwards;
        }

        &.close-map-animation {
          animation: mapSlideUp .25s ease-out forwards;
        }

        .map {
          height: 100%;
        }

        .active-funeral-director {
          width: 372px;
          bottom: 90px;
        }
      }

      .fd-header {
        @include widthAndHeight(382px, 43px, 0, 0, null, 1);
        margin-top: 70px;
        padding: 10px;

        display: grid;
        grid-template-columns: auto auto;

        h2 {
          @include widthAndHeight(257px, 43px, 0, 20px, null, 1);
          font: normal normal bold 32px/53px $font-primary;
        }

        span {
          @include widthAndHeight(125px, 24px, 0, 0, null, 1);
          font: normal 16px/24px $font-secondary;
          border-radius: 13px;
          padding-top: 2px;
          margin: 17px 15px 0;
        }
      }

      .fd-feature-content {
        padding: 10px;
        background: $secondary;

        h3 {
          height: 45px;
          font: bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
        }

        .featured-list {
          grid-template-columns: repeat(2, 0fr);

          .fd-list-item {
            width: 175px;
            gap: 18px;

            .fd-item-banner {
              height: 175px;
            }
          }
        }
      }

      .fd-std-content {
        .standard-list {
          grid-template-columns: repeat(2, auto);

          .std-list-item {
            width: 185px;
            gap: 18px;
          }

          .mobile-ad {
            grid-column: 1 / -1;
            width: 100%;

            div iframe {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// for screen width less than 428px
@include for-sm-down {
  .fd-listing-wrapper {
    width: 100%;

    .fd-right-column {
      .fd-right-header h1 {
        font-size: 32px;
      }

      .map-container .active-funeral-director {
        width: 95%;

        .active-funeral-director-info {
          gap: 1px;

          h6 {
            width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .active-fd-location .active-fd-location {
            width: 150px;
          }
        }
      }

      .fd-header {
        width: 100%;
      }

      .fd-feature-content {
        padding: 5px;

        .featured-list {
          row-gap: 25px;

          .fd-list-item {
            width: 131px;
            gap: 10px;

            .fd-item-banner {
              height: 131px;
            }

            .fd-item-details {
              gap: 10px;

              h5 {
                font-size: 14px;
              }

              .fd-location {
                padding-left: 18px;
                font-size: 14px;
                background-size: 12px auto;
              }
            }
          }
        }
      }

      .fd-std-content .standard-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px 20px;

        .std-list-item {
          width: auto;
          gap: 10px;

          .std-item-details {
            gap: 10px;

            h5 {
              font-size: 14px;
            }

            .std-location {
              padding-left: 18px;
              font-size: 14px;
              background-size: 12px auto;
            }
          }
        }
      }
    }
  }
}