// !important is used in this component for the form elements
// Because the form is generated by Marketo
// and it happens to have !important all through it's css sadly

section {
  height: auto;
}
section.newsletter {
  background-image: url('./images/subscribe-background.jpg');
  background-position: 50% 50%;
  background-size: cover;
  @include widthAndHeight ($breakpoint-xl, 228px, null, null, null, null);

  .container {
    display: $flex;
    align-items: center;
    position: relative;
    height: 228px;

    h2 {
      color: $white;
      text-align: left;
      font: normal normal normal 40px/51px $font-primary;
      letter-spacing: 0;
      color: $white;
      opacity: 1;
      width: 520px;
      height: 104px;
      margin-left: 337px;
      margin-right: 46px;
    }

    .form-control {

      display: $flex;
      border-radius: 42px;
      padding: 6px;
      background: $white;
      padding-left: 46px;
      height: 75px;
      width: 660px;

      input {
        border: none;
      }

      div.thankyou {
        font: normal normal normal 18px/64px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
        display: none;
        text-align: center;
      }

      // #ID to override Marketo
      input#Email {
        text-align: left;
        top: 26px;
        overflow: visible;
        width: 350px !important;
        white-space: nowrap;
        text-align: left;
        font-family: $font-secondary;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: rgba(16, 16, 16, 1);
        letter-spacing: 0.6px;
        background: $white !important;
        border-radius: 34px !important;
        line-height: 44px;
        outline: none;
      }

      button[type=submit] {
        position: relative;
        left: 188px;
        top: -128px;
        overflow: visible;
        width: 247px;
        height: 62px;
        border-radius: 34px;
        background: $primary !important;
        box-shadow: none;
        color: $white !important;
        font-family: $font-secondary;
        &:hover {
          background-color: $primary-light !important;
        }
      }
    }
  }

  // Override styles for the Marketo form
  .mktoForm {

    padding-left: 0;

    div.mktoOffset {
      display: none;
    }

    div.mktoError {
      // Is an inline style so needs !important
      right: 0 !important;
      bottom: -38px !important;
    }

    div.mktoHtmlText span {
      color: $white;
      a {
        color: $white;
      }
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  section.newsletter {
    width: 100%;
    .container {
      width: $breakpoint-xl;
    }
  }
}
//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.newsletter {

    @include widthAndHeight(100%, 230px, null, null, null, null);

    .container {

      display: $block;

      @include widthAndHeight($breakpoint-md, 225px, 0px, 0px, relative, 1);

      h2 {

        @include widthAndHeight(392px, 104px, 0px, 0px, relative, 1);
        color: $white;
        font: normal normal normal 30px/37px $font-primary;
        margin: 0 80px;
        padding: 10px;
      }

      .form-control {
        @include widthAndHeight(590px, 65px, 0px, 0px, relative, 1);
        display: block;
        border-radius: 38px;
        padding: 6px;
        background: $white;
        padding-left: 46px;
        margin: 0px auto;

        input {
          border: none;
          border-radius: 34px;
        }

        input#Email {
          font: normal normal normal 16px/25px $font-secondary;
          letter-spacing: 0.96px;
          @include widthAndHeight(249px !important, 50px, 0px, 0px, relative, 1);
        }

        button[type=submit] {
          @include widthAndHeight(203px, 50px, -110px, 175px, relative, 1);
          overflow: visible;
          border: none;
          border-radius: 34px;
        }
      }
    }
    .mktoForm {
      .mktoHtmlText {
        margin-top: -5px;
        width: 235% !important;
        margin-left: -45px !important;
      }
    }

   .mktoForm * {
      padding: 0;
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {

  section.newsletter {

    @include widthAndHeight (100%, 245px, null, null, null, null);

    .container {
      display: $flex;
      align-items: center;
      position: relative;
      height: 228px;
      width: $breakpoint-lg;
      margin: 0px auto;
      h2 {
        color: $white;
        text-align: left;
        font: normal normal normal 40px/51px $font-primary;
        color: $white;
        opacity: 1;
        width: 430px;
        height: 104px;
        margin-left: 55px;
        margin-right: 90px;
      }

    }

  }

}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.newsletter {
    @include widthAndHeight($breakpoint-sm, 419px, null, null, null, null);
    background-image: url('./images/subscribe-background-square.jpg');
    background-size: cover;

    .container {
      @include widthAndHeight (382px, 365px, 27px, 23px, relative, 1);
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px $light-black;
      display: block;
      margin: 0px;

      h2 {
        @include widthAndHeight (293px, 145px, 10px, 45px, relative, 1);
        text-align: center;
        font: normal normal bold 30px/40px $font-primary;
        color: $text-dark;
        margin: 0px;
      }

      input:focus {
        outline: none;
      }

      .form-control {
        position: relative;
        display: block;
        width: 100%;
        top: 15px;
        padding: 0px;
        margin: 0px;

        input#Email {
          @include widthAndHeight (287px !important, 23px, -30px, 47px, relative, 1);
          border: none;
          border-radius: 0px !important;
          border-bottom: 1px solid $dark-green !important;
          padding-left: 35px !important;
          padding: 20px !important;

          &::placeholder {
            @include widthAndHeight (221px, 23px, 0px, 20px, relative, 1);
            text-align: left;
            font: normal normal normal 16px/25px $font-secondary;
            letter-spacing: 0.96px;
            color: $dark-green;
            opacity: 1;

          }
        }

        button[type=submit] {
          @include widthAndHeight (247px, 60px, -100px, 10px, relative, 1);
          text-align: center;
        }
      }
    }
    .mktoForm {
      div.mktoHtmlText {

        left: 35px;
        position: relative;
        top: 53px;
        width: 87% !important;
        text-align: center !important;
        padding: 0px !important;
        margin: 0;
        letter-spacing: 0;

        span {
          color: $text-dark;
          font-family: $font-secondary;
          a {
            color: $text-dark;
          }
        }
      }
    }

    .mktoForm * {
      padding: 0;
      text-align: center !important;
    }

  }
}


//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.newsletter {
    @include widthAndHeight(100%, 419px, null, null, null, null);
    background-image: url('./images/subscribe-background-square.jpg');
    background-size: cover;

    .container {
      @include widthAndHeight (90%, 365px, 27px, 23px, relative, 1);
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px $light-black;
      display: block;
      margin: 0px;

      h2 {
        @include widthAndHeight (100%, 145px, 10px, 0%, relative, 1);
        text-align: center;
        font: normal normal bold 30px/40px $font-primary;
        color: $text-dark;
        margin: 0px;
      }

      input:focus {
        outline: none;
      }

      .form-control {
        position: relative;
        display: block;
        width: 100%;
        top: 15px;
        padding: 0px;
        margin: 0px;

        input#Email {
          @include widthAndHeight (95% !important, 23px, -30px, 6%, relative, 1);
          border: none;
          border-radius: 0px !important;
          border-bottom: 1px solid $dark-green !important;
          padding-left: 35px !important;
          padding: 20px 0px !important;

          &::placeholder {
            @include widthAndHeight (221px, 23px, 0px, 20px, relative, 1);
            text-align: left;
            font: normal normal normal 16px/25px $font-secondary;
            letter-spacing: 0.96px;
            color: $dark-green;
            opacity: 1;

          }
        }

        button[type=submit] {
          @include widthAndHeight (247px, 60px, -100px, 10px, relative, 1);
          text-align: center;
        }
      }
    }
    .mktoForm {
      div.mktoHtmlText {

        left: 35px;
        position: relative;
        top: 53px;
        width: 87% !important;
        text-align: center !important;
        padding: 0px !important;
        margin: 0;
        letter-spacing: 0;

        span {
          color: $text-dark;
          font-family: $font-secondary;
          a {
            color: $text-dark;
          }
        }
      }
    }

    .mktoForm * {
      padding: 0;
      text-align: center !important;
    }

  }
}

@media only screen and (max-width: 390px) {
  section.newsletter .container .form-control button[type=submit] {
    top: -114px;
  }
}

@media only screen and (max-width: 320px) {
  section.newsletter .container{
    height: 375px;
    left: 0px;
    top: 0px;
    position: unset;
    margin: $base-margin * 2 auto;
  }
  section.newsletter .container .form-control button[type=submit] {
    top: -132px;
  }
}
