section {
  height: auto;
}
.featured-directors {
  background: $secondary;
  @include widthAndHeight($breakpoint-xl, null, null, null, null, null);
  min-height: 330px;
  max-height: 990px;
  height: auto;

  .containerfd {
    .split-header {

      .mb-content {
        display: none;
      }
      display: $grid;
      grid-auto-columns: auto auto;
      justify-content: space-between;
      grid-auto-flow: column;
      margin: 0px auto;
      width: $breakpoint-lg;

      h2 {
        font: normal normal bold 40px/53px $font-primary;
        letter-spacing: .12px;
        margin-top: 114px;
        text-align: right;
        width: 675px;
      }
      .split-header-right {
        margin-top: 114px;
        padding-left: 50px;
        display: grid;
        grid-gap: 3px;
        grid-auto-flow: column;
        justify-content: end;
        position: relative;

        a.btn {
          width: 239px;
          height: 62px;
          padding: 19px 27px;
          margin-right: 25px;
          text-align: center;
          font-family: $font-secondary;
        }

        a.primary{
          font-family: $font-secondary;
        }
      }
      .state-dropdown{
        margin-top: 114px;
        padding-left: 60px;
        display: grid;
        grid-gap: 3px;
        grid-auto-flow: column;
        justify-content: end;
        position: relative;
        &::after {
          position: absolute;
          right: 34%;
          top: 13px;
          margin-right: 10px;
          content: "";
          display: inline-block;
          vertical-align: middle;
          margin-top: 7px;
          width: 15px;
          height: 15px;
          border-top: 3px solid $secondary-dark;
          border-right: 3px solid $secondary-dark;
          opacity: 1;
          transform: rotate(135deg);
          transition: transform 0.5s;
        }
        .fd-state-dropdown{
          width: 279px;
          height: 64px;
          background: $white;
          border-radius: 32px 0px 0px 32px;
          border: none;
          padding: 20px 20px 20px 30px;
          outline: none;
          appearance: none;
          font: normal normal normal 16px/25px $font-secondary;
          &:active{
            border-radius: 32px 0px 0px 0px;
          }
        }
        .fd-view-all{
          width: 128px;
          height: 64px;
          border-radius: 0px 32px 32px 0px;
          padding: 19px 10px;
          margin-right: 35px;
          text-align: center;
          font: normal normal 600 16px/25px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }
      &.active {
        .state-dropdown {
          &::after {
            margin-top: 14px;
            transform: rotate(-45deg);
          }
        }
      }

    }

    .directors {
      padding-bottom: 124px;
      padding-left: 345px;
      display: $grid;
      grid-template-columns: 321px 900px;

      .directors-nav {
        float: left;
        margin-right: 29px;

      }


      .features {
        overflow-x: hidden;
        overflow-y: hidden;
        width: 901px;
        max-height: 630px;
        margin-top: 27px;
        margin-left:29px;
        .mb-split-header-right{
          display: none;
        }
        .column {
          display: $grid;
          grid-template-columns: repeat(4, 1fr);
          margin-top: 10px;
          overflow-y: auto;
          height: 630px;


          .fd-card{
            height: 265px;
            margin-bottom: 20px;
            margin-top: 20px;
            opacity: 1;
            overflow: hidden;
            text-align: center;
            width: auto;
            background: transparent;
            box-shadow: none;

            .fd-banner{
              height: 205px;
              width: 203px;
              position: relative;
              display: block;
              overflow: hidden;
              border-radius: 20px;
              .fd-banner-item{
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                width: 203px;
                height: 205px;
              }
              .fd-banner-view {
                display: inline-block;
              }

            }
            img {
              -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
              mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
              -o-object-fit: contain;
              object-fit: contain;
              transition: transform .25s ease-in-out;
              width: 100%;
              height: auto;
              z-index: 1;
            }

            img:hover {
              transform: scale(1.09)
            }


            .fd-card-details {
              padding-top: 8px;
              margin-right: 20px;

              h4 {
                text-align: center;
                font: normal normal 600 18px/16px $font-secondary;
                letter-spacing: 0px;
                color: $text-dark;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-bottom: 8px;

              }

              img.fd-locationImg {
                width: 15px;
                height: 15px;
                box-shadow: none;
                background: transparent;
                vertical-align: $baseline;
                top: 4px;
                position: relative;
              }

              .fd-location-name {
                text-align: left;
                font: normal normal normal 16px/25px $font-secondary;
                letter-spacing: 0px;
                color: $grey;
              }
            }
          }


        }

      }



      .vewAll {
        grid-column: 2;
        text-align: center;

        a {
          text-align: center;
          font: normal normal 600 18px/27px $font-secondary;
          letter-spacing: 0px;
          color: $primary;
          opacity: 1;
          padding-bottom: 1px;
          border-bottom: 2px solid $primary;
          text-decoration: none;
        }
      }
      .vewAll:hover {
        a {
          border-bottom: 2px solid $primary-light;
          color: $primary-light;
        }
      }
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  .featured-directors {
    width: 100%;
    .containerfd{
      .split-header{
        h2{
          margin-bottom: 43px;
          margin-left: 20px;
        }
      }
      .directors {
        padding-left: 0px;
        width: 1248px;
        margin: 0px auto;
        .features{
          margin-top: 0px;
          margin-left:29px;
          .column{
            margin-top: 0px;
          }
        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .featured-directors {
    z-index: 1;
    @include widthAndHeight($breakpoint-sm, auto, null, null, null, null);
    overflow: hidden;
    .containerfd {
      height: auto;
      overflow: hidden;
      margin: 23px 0px 65px;
      .split-header {
        width: 100%;
        display: block;
        h2 {
          display: none;
        }

        .mb-content {
          @include widthAndHeight(376px, 43px, null, null, null, null);
          display: block;
          text-align: left;
          font: normal normal bold 32px/43px $font-primary;
          letter-spacing: 0.1px;
          color: $text-color;
          margin: 51px 24px 24px;
        }

        .split-header-right{
          display: none;
          margin-top: 30px;
          padding-right: 0;
          right: 23px;
          a.btn {
            height: 65px;
            margin-left: 15px;
            padding: 22px 27px;
            text-align: center;
            width: 186px;
            z-index: 1;
          }
        }
        .state-dropdown{
          width: 90%;
          margin: 30px 21px 38px;
          padding: 0;
          left: 0px;
          top: 0px;
          z-index: 1;
          justify-content: start;
          &::after {
            right: 30%;
          }
          .fd-state-dropdown{
            width: 285px;
          }
          .fd-view-all{
            left: 0px;
            top: 0px;
            width: 96px;
          }
        }

      }

      .directors {
        width: $breakpoint-sm;
        height: auto;
        padding: 0px;
        display: block;
        .directors-nav {
          margin: 0px auto;
          float: none;
        }

        .features {
          @include widthAndHeight(100%, auto, null, null, initial, null);
          margin: 0px 0px 50px 0px;
          .column {
            display: block;
            margin-top: 0px;
            height: auto;
            .card-prev {
              top: 100px;
              left: 5%;
              opacity: 1;
              z-index: 1;
            }
            .card-next {
              top: 74px;
              right: 5%;
              margin: 0px;
              opacity: 1;
              z-index: 1;
            }
            .card-list{
              .card-track{
                .card-slide {
                  display: block;
                  width: 156px;
                }
              }
            }

            .fd-card {
              margin: 0px;
              border-radius: 20px;

              .fd-card-details {
                margin: 0px;
                h4 {
                  text-align: center;
                  font: normal normal 600 16px/19px $font-secondary;
                  letter-spacing: 0px;
                  color: $text-dark;
                  max-height: 40px;
                  padding-bottom: 8px;
                  overflow: hidden;
                  text-overflow: initial;
                  white-space: normal;
                }

                img.fd-locationImg {
                  width: 16px;
                  height: 16px;
                  box-shadow: none;
                  display: inline-block;
                  background: transparent;
                  vertical-align: $baseline;
                  position:relative;
                  top:2px;
                }

                .fd-location-name {
                  width: 118px;
                  height: 23px;
                  opacity: 1;
                  text-align: left;
                  font: normal normal normal 16px/25px $font-secondary;
                  letter-spacing: 0px;
                  color: $grey;
                }
              }
            }

            .fd-card .fd-banner {
              @include widthAndHeight(156px, 156px, null, null, null, null);
              border-radius: 20px;
              opacity: 1;
              margin:0;

              img {
                box-shadow: none;
                border: none;
                background: none;
                position: relative;
                top:-20px;
              }
            }
          }
          .mb-split-header-right{
            display: $flex;
            flex-direction: row-reverse;
            justify-content: space-evenly;
            a {
              @include widthAndHeight(187px, 65px, null, null, null, null);
              border-radius: 34px;
              padding: 20px 0px 0px 0px;
              text-align: center;
              margin-right: 0px;
              font-size: 16px;
              font-family:  $font-secondary;
            }
            .btn{
              font-family:  $font-secondary;
            }
          }
        }

        .vewAll {
          display: none;
        }
      }
    }
  }
}

 //When the screen width < 1280px and >= 768px, render the 768px display
 @include for-lg-only {
  .featured-directors {
    @include widthAndHeight(100%, null, null, null, null, null);
    min-height: 330px;
    max-height: 838px;
    .containerfd {
      @include widthAndHeight($breakpoint-md, null, 0px, 0px, relative, 1);
      padding: 15px;
      margin: 0px auto;
      min-height: 330px;
      max-height: 830px;
      .split-header {
        display: block;
        height: 100px;
        justify-content: space-between;
        opacity: 1;
        position: absolute;
        width: 738px;

        .split-header-right {
          margin-top: 0;
          padding-right: 0;
          position: absolute;
          right: -10px;
          top: 98px;
          a.btn {
            height: 55px;
            margin-right: 20px;
            padding: 16px 25px;
            text-align: center;
            width: 184px;
            font: normal normal 600 15px/23px $font-secondary;
          }

        }

        .state-dropdown{
          margin-top: 0;
          padding-right: 0;
          position: absolute;
          right: -3px;
          top: 98px;
          &::after {
            right: 33%;
          }
          a.btn {
            margin-right: 24px;
            width: 115px;
          }
          .fd-state-dropdown{
            width: 260px;
          }
        }
        h2 {
          font: normal normal 700 30px/50px $font-primary;
          height: 40px;
          letter-spacing: .09px;
          margin: 45px 0 13px 320px;
          opacity: 1;
          position: absolute;
          width: 272px;
        }

      }

      .directors {
        margin-top: 85px;
        padding: 0px;
        display: $grid;
        grid-template-columns: 300px auto;
        grid-gap: 37px;

        .directors-nav {
          margin: 13px 37px 0px 0px;
        }

        .features {
          overflow-x: hidden;
          margin-top: 85px;
          margin-left:0px;
          @include widthAndHeight(401px, null, null, null, null, null);
          max-height: 525px;
          .column {
            display: $grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 0px;
            width: 400px;
            height: 524px;

            .fd-card {
              background: transparent;
              box-shadow: none;
              height: 230px;
              margin-bottom: 20px;
              margin-right: 20px;
              margin-top: 10px;
              opacity: 1;
              overflow: hidden;
              text-align: center;
              width: 178px;
              padding: 2px;

              .fd-banner{
                height: 174px;
                width: 174px;
                .fd-banner-item{
                  height: 174px;
                  width: 174px;

                }
                .fd-banner-view{
                  height: 174px;
                  width: 174px;
                  display: $flex;
                }
                img {
                  -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
                  mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
                  -o-object-fit: contain;
                  object-fit: contain;
                  transition: transform .25s ease-in-out;
                  width: 100%;
                  height: auto;
                  z-index: 1;
                }
              }

              .fd-card-details {
                padding-top: 15px;

                h4 {
                  text-align: center;
                  font: normal normal 600 16px/16px $font-secondary;
                  letter-spacing: 0px;
                  color: $text-dark;
                  padding-bottom: 3px;
                }

                img.fd-locationImg {
                  width: 13px;
                  height: 18px;
                  top: 4px;
                  box-shadow: none;
                  background: transparent;
                  vertical-align: $baseline;
                }

                .fd-location-name {
                  text-align: left;
                  font: normal normal normal 14px/21px $font-secondary;
                  letter-spacing: 0px;
                  color: $grey;
                }
              }
            }
          }
        }

        .vewAll {
          a {
            text-align: center;
            font: normal normal 600 16px/25px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
            opacity: 1;
          }
        }
      }
    }

  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .featured-directors {
    @include widthAndHeight(100%, null, null, null, null, null);
    min-height: 330px;
    max-height: 990px;
    .containerfd {
      @include widthAndHeight($breakpoint-lg, null, 0px, 0px, relative, 1);
      padding: 15px;
      min-height: 330px;
      max-height: 990px;
      margin: 0px auto;
      .split-header {
        height: 103px;
        margin-top: 62px;
        margin-bottom: 37px;
        justify-content: end;
        width: 100%;
        a.btn {
          margin-right: 10px;
        }

        h2 {
          margin: 0;
          font: normal normal bold 40px/50px $font-primary;
          letter-spacing: 0.09px;
          height: 103px;
          opacity: 1;
          width: 176px;
          position: absolute;
          margin-left: 408px;
          text-align:left;
        }

        .split-header-right {
          margin-top: 0;
          padding: 22px 0px;
          right: 4px;
          position: absolute;
          a.btn {
            height: 62px;
            padding: 19px 27px;
            text-align: center;
            width: 247px;
          }
          a.primary{
            margin-right: 0px;
          }
        }
        .state-dropdown{
          margin-top: 0;
          padding: 22px 0px 22px 60px;
          .fd-view-all{
            margin-right: 12px;
          }
          &::after {
            right: 32%;
            top: 35px;
          }
        }
      }

      .directors {
        display: block;
        margin-top: 0px;
        padding-left: 0;
        padding-right: 10px;
        overflow: hidden;
        display: $grid;
        grid-template-columns: repeat(2,1fr);
        .directors-nav {
          margin-right: 47px;
          margin-left:45px;
        }

        .features {
          margin-top:0px;
          margin-left:0px;
          .column {
            display: $grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 0px;

            .fd-card {
              height: 295px;
              width: 235px;
              margin-bottom: 20px;
              margin-right: 20px;
              margin-top: 0px;
              opacity: 1;
              overflow: hidden;
              text-align: center;
              background: transparent;
              box-shadow: none;

              .fd-banner{
                height: 232px;
                width: 235px;
              }
              .fd-banner-item{
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                height: 232px;
                width: 235px;
              }
              .fd-banner-view {
                width: 235px;
              }

              img {
                -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
                mask-image: linear-gradient(180deg,rgba(0,0,0,.1),#000 5%,#000 50%,#000 95%,rgba(0,0,0,.1));
                -o-object-fit: contain;
                object-fit: contain;
                transition: transform .25s ease-in-out;
                width: 100%;
                height: auto;
                z-index: 1;
              }
              .fd-card-details {
                padding-top: 11px;

                h4 {
                  text-align: center;
                  font: normal normal 600 18px/16px $font-secondary;
                  letter-spacing: 0px;
                  color: $text-dark;
                  padding-bottom: 7px;
                }

                img.fd-locationImg {
                  width: 13px;
                  height: 18px;
                  box-shadow: none;
                  background: transparent;
                  vertical-align: text-top;
                }

                .fd-location-name {
                  text-align: left;
                  font: normal normal normal 16px/25px $font-secondary;
                  letter-spacing: 0px;
                  color: $grey;
                }
              }
            }
          }
        }

        .vewAll {


          a {
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
            opacity: 1;
          }
        }
      }
    }

  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .featured-directors {
    z-index: 1;
    @include widthAndHeight(100%, auto, null, null, null, null);
    overflow: hidden;
    .containerfd {
      height: auto;
      overflow: hidden;
      margin: 23px 0px 65px;

      .split-header {
        width: 100%;
        display: block;
        h2 {
          display: none;
        }

        .mb-content {
          @include widthAndHeight(100%, 43px, null, null, null, null);
          display: block;
          text-align: left;
          font: normal normal bold 32px/43px $font-primary;
          letter-spacing: 0.1px;
          color: $text-color;
          margin: 51px 24px 24px;
        }

        .split-header-right{
          display: none;
          margin-top: 30px;
          padding-right: 0;
          right: 23px;
          a.btn {
            height: 65px;
            margin-left: 15px;
            padding: 22px 27px;
            text-align: center;
            width: 186px;
            z-index: 1;
          }
        }
        .state-dropdown{
          margin: 30px 21px 38px;
          padding: 0;
          left: 0px;
          top: 0px;
          z-index: 1;
          justify-content: start;
          grid-template-columns: 72% 27%;
          &::after {
            right: 30%;
          }
          .fd-state-dropdown{
            width: 100%;
          }
          .fd-view-all{
            left: 0px;
            top: 0px;
            width: 96px;
          }
        }
      }

      .directors {
        width: 100%;
        height: auto;
        padding: 0px;
        display: block;
        .directors-nav {
          margin: 0px auto;
          float: none;
        }

        .features {
          @include widthAndHeight(100%, auto, null, null, initial, null);
          margin: 0px 0px 50px 0px;
          .column {
            display: block;
            margin-top: 0px;
            height: auto;

            .card-prev {
              top: 100px;
              left: 5%;
              opacity: 1;
              z-index: 1;
            }
            .card-next {
              top: 74px;
              right: 5%;
              margin: 0px;
              opacity: 1;
              z-index: 1;
            }
            .card-list{
              .card-track{
                .card-slide {
                  display: block;
                  width: 156px;
                }
              }
            }


            .fd-card{
              margin: 0px;
              border-radius: 20px;
              .fd-card-details {
                h4 {
                  width: 100%;
                  text-align: center;
                  font: normal normal 600 16px/19px $font-secondary;
                  letter-spacing: 0px;
                  color: $text-dark;
                  max-height: 40px;
                  padding-bottom: 8px;
                  overflow: hidden;
                  text-overflow: initial;
                  white-space: normal;
                }

                img.fd-locationImg {
                  width: 16px;
                  height: 16px;
                  box-shadow: none;
                  display: inline-block;
                  background: transparent;
                  vertical-align: $baseline;
                  position:relative;
                  top:2px;
                }

                .fd-location-name {
                  width: 118px;
                  height: 23px;
                  opacity: 1;
                  text-align: left;
                  font: normal normal normal 16px/25px $font-secondary;
                  letter-spacing: 0px;
                  color: $grey;
                }
              }
            }

            .fd-card .fd-banner {
              @include widthAndHeight(156px, 156px, null, null, null, null);
              border-radius: 20px;
              opacity: 1;
              margin:0;

              img {
                box-shadow: none;
                border: none;
                background: none;
                position: relative;
                top: -20px;
              }
            }
          }

          .mb-split-header-right{
            display: $flex;
            flex-direction: row-reverse;
            justify-content: space-evenly;
            a {
              @include widthAndHeight(187px, 65px, null, null, null, null);
              border-radius: 34px;
              padding: 21px 0px 0px 0px;
              text-align: center;
              margin-right: 0px;
              font-size: 16px;
              font-family:  $font-secondary;
            }
          }
        }

        .vewAll {
          display: none;
        }
      }
    }
  }
}
