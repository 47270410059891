.modal-launch {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $white-lighter;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $modal-primary;
    width: 1023px;
    height: 572px;
    box-shadow: 0px 3px 6px $light-black;
    border-radius: 8px;
    .close-button {
      width: 37px;
      display: block;
      height: 37px;
      position: absolute;
      right: 40px;
      top: 30px;
      color: $white;
      border-radius: 20px;
      text-align: center;
      font: normal normal normal 24px/38px $font-secondary;
      background: $modal-primary;
      cursor: pointer;
    }
    .close-button:hover {
      background-color: $grey-light;
    }
    .modal-content-body{
      display: grid;
      grid-template-columns: auto auto;
      .body-content-left{
        width: 537px;
        display: block;
        overflow: hidden;
        padding: 55px 92px 55px 72px;
        .logo-white{
          img{
            height: 43px;
          }
          margin-bottom: $base-margin * 2;
        }
        .modal-heading{
          h2{
            width: 244px;
            font: normal normal bold 42px/54px $font-primary;
            letter-spacing: 0px;
            color: $white-chocolate;
            .modal-sub-heading{
              span{
                width: 62px;
                height: 5px;
                background: $white-chocolate;
                display: block;
              }
              margin-top: $base-margin + 4;
            }
          }
          p{
            width: 390px;
            font: normal normal normal 16px/32px $font-secondary;
            letter-spacing: 0px;
            color: $white-chocolate;
            margin: 24px 0px 38px;
            text-align: left;
            a{
              color: $secondary;
            }
          }
          .modal-learn-more{
            width: 218px;
            height: 48px;
            font: normal normal normal 19px/29px $font-secondary;
            background: $white-chocolate;
            border-radius: 8px;
            letter-spacing: 0px;
            color: $modal-primary;
            display: grid;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }
        }
      }
      .body-content-right{
          width: 486px;
          height: 572px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0px 8px 8px 0px;
          }
      }
    }
  }
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  z-index: 99999;
}

@include for-lg-down {
  .modal-launch{
    .modal-content {
      top: 47%;
      max-width: 334px;
      min-width: 320px;
      width: auto;
      border-radius: 0px;
      .close-button {
        top: 23px;
        right: 29px;
        background: none;
      }
      .modal-content-body{
        display: block;
        width: 100%;
        height: 570px;
        .body-content-left{
          text-align: center;
          padding: 63px 45px 29px;
          width: 100%;
          .modal-heading{
            h2{
              text-align: center;
              font: normal normal bold 32px/42px $font-primary;
              width: 204px;
              margin: 0px auto;
              .modal-sub-heading{
                text-align: center;
                display: grid;
                justify-content: center;
                margin: 14px auto 0px;
              }
            }
            p{
              width: 100%;
              text-align: center;
              font: normal normal normal 12px/32px $font-secondary;
              margin: $base-margin * 2 + 4 0px $base-margin * 2 - 3;
            }
          }
          .modal-learn-more{
            margin: 0px auto;
          }
        }
        .body-content-right{
          display: none;
        }
      }
    }
  }
}
