// Settings
@import "settings.theme";

// Generic
@import "normalize.css";
@import "generic.fonts";

// Mixins
@import "tools.mixins";

// Objects
@import "object.button";
@import "object.card";
@import "object.layout";
@import "object.icon";
@import "object.alerts";

// Components
@import "component.header";
@import "component.hero";
@import "component.recent-notices";
@import "component.newsletter";
@import "component.featured-notices";
@import "component.obituaries";
@import "component.featured-articles";
@import "component.featured-partners";
@import "component.featured-directors";
@import "component.about-us";
@import "component.place-a-notice";
@import "component.ankle";
@import "component.footer";
@import "component.standardalone-articles";
@import "component.our-partners";
@import "component.enquire-information";
@import "component.listing-premium-info";
@import "component.listing-std-info";
@import "component.related-articles.scss";


@import "~/common/styles/component.ads";
@import "~/common/styles/component.redesign-modal";
@import "~/common/styles/component.video";

// Profile
@import "component.myaccount.scss";

// details screen
@import "component.listing-detail";

//Funeral Director
@import "component.fd-listing";
@import "component.fd-director-details";
@import "component.fd-recent-notices";
@import "component.listing-premium-plus-info";


//Articles
@import "component.articles-listing";
@import "component.article-details";

//Obituaries
@import "component.obituaries-listing";
@import "component.obituaries-details";


@import "image-gallery";
@import "flatpages";
@import "component.funeral-listing";
@import "component.navbar-search-filter";
@import "component.refine-search-filter";
@import "component.standard-article";
@import "component.pagination";
@import "component.date-picker";

// 3rd Party froala
@import "vendor/froala";

//Slick Slider
@import "slick-theme";

@import "component.page-not-found";

@import "component.flowers-listing";

@import "component.flowers-listing-details";

@import "component.animation";

@import "component.modal-launch";

// safari hacks
@import "only-safari-hacks";

//Temp
body {
  background: $background;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

#wrapper {
  margin: 0 auto;
  max-width: 1920px;
}
