.card{
  background: white;
  border-radius: $border-radius-sm;
  overflow:hidden;
  width: 224px;
  height: 297px;
  img{display:block;margin:0;border-radius: 20px 20px 0 0;height:204px;}
box-shadow: 0px 3px 6px $light-black;
border-radius: 20px;
opacity: 1;
margin-top: 20px;
margin-bottom: 20px;
margin-right: 32px;
    text-align: center;
    font-family: $font-secondary;

h4{margin-left:16px;
text-align:left;}
p{margin-left:-60px;}

}

.cards-4{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-top: 56px;
  position: relative;
  left: 327px;
  width: 1270px;
  height: 694px;
}

// .column{
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);

// }

.card1{
  width: 203px;
  height: 270px;
  margin-right: 22px;
}

.card-3  {

  background: white;
  overflow:hidden;
  width: 103px;
  height: 103px;
  box-shadow: 0px 3px 6px $light-black;
  border-radius: 8px;
  img{width: 103px;
    height: 103px;}

}


