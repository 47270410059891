.h-line {
    border: 0px;
    border-bottom: 2px solid $secondary-dark;
    opacity: 0.48;
    margin: $base-margin - 5 0px $base-margin * 2 + 2;
    clear: both;
}

.ob-date-icon {
    background: url("././images/calendar.svg") no-repeat;
    background-position: 0% 60%;
    padding-left: $base-padding * 3;
    padding-top: $base-padding * 2 + 1;
    vertical-align: -3px;
    display: inline-flex;
}

a span.icon-facebook {
    background: url("././images/share-facebook.svg") no-repeat;
    background-position: 0% 60%;
    padding-left: 25px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

a span.icon-twitter {
    background: url("././images/share-twitter.svg") no-repeat;
    background-position: 0% 60%;
    padding-left: 25px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

a span.icon-mail {
    background: url("././images/share-envelope.svg") no-repeat;
    background-position: 0% 60%;
    padding-left: 25px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.text-article {
    text-align: left;
    font: normal normal bold 42px/56px $font-primary;
    letter-spacing: 0.13px;
    color: $text-color;
}

@mixin text-overflow-clip($lines) {
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.text-overflow-elipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.obituaries-detail-wrapper {
    .container {
        padding: $base-padding * 4 $base-padding * 2 - 1 $base-padding * 10 + 19;
        height: auto;
        width: $breakpoint-lg;
        overflow: hidden;

        .obituaries-details-section {
            display: $grid;
            grid-template-columns: auto auto;
            overflow: hidden;
            grid-gap: $gap + 10;

            .obituaries-details-left {
                width: 899px;

                .obituaries-details-left-content {
                    overflow: hidden;

                    .obituaries-detail-header {
                        h2 {
                            font: normal normal bold 42px/56px $font-primary;
                            letter-spacing: 0.13px;
                            color: $text-dark;
                        }

                        h1 {
                            font: normal normal bold 42px/56px $font-primary;
                            letter-spacing: 0.13px;
                            color: $text-dark;
                            margin: 0px;
                        }

                        .obituaries-detail-sub-header {
                            .obituaries-publication-details {
                                float: left;
                                width: 85%;
                                margin-top: 25px;

                                .ob-headTxt {
                                    font: normal normal 600 20px/30px $font-secondary;
                                    letter-spacing: 0px;
                                    color: $mild-gray;
                                }

                                .ob-subText {
                                    font: normal normal normal 20px/30px $font-secondary;
                                    letter-spacing: 0px;
                                    color: $mild-gray;
                                }
                            }

                            .obituaries-share-btns {
                                width: 15%;
                                display: $grid;
                                grid-template-columns: repeat(3, 0fr);

                                .share-tools {
                                    float: left;
                                    padding: 10px 10px 10px 11px;

                                    .facebook-share {
                                        .icon-facebook {
                                            display: $block;
                                            width: 45px;
                                            height: 45px;
                                            background-size: 98%;
                                        }
                                    }

                                    .x-share,
                                    .email-share {
                                        border-radius: 45px;
                                        border: 2px solid $mild-gray;
                                        padding: $base-padding - 2;
                                        vertical-align: -15px;
                                        width: 45px;
                                        height: 45px;
                                        display: $flex;
                                    }

                                    .email-share {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .obituaries-detail-desc {
                        overflow: hidden;

                        .videoInline iframe,
                        .mainVideo iframe {
                            width: 100%;
                        }

                        figure figcaption {
                            .caption-details {
                                padding-bottom: $base-padding - 2;

                                span {
                                    display: block;
                                }
                            }

                            border-bottom: 2px solid $secondary-dark;
                            font: normal normal 600 18px/29px $font-secondary;
                            margin-bottom: $base-margin * 3+5;

                            .caption-details>* {
                                font: normal normal 600 18px/29px $font-secondary;
                            }
                        }

                        .mb-show-obituaries {
                            display: none;
                            background: $secondary 0% 0% no-repeat padding-box;
                            border-radius: $border-radius-sm;
                            margin-bottom: $base-margin * 3;

                            .mb-show-obituaries-img {
                                height: 225px;
                                width: 383px;

                                img {
                                    margin: 0px;
                                    border-radius: $border-radius-sm $border-radius-sm 0px 0px;

                                    &:hover {
                                        transform: scale(1.04);
                                        transition: $allease-in-out;
                                    }
                                }
                            }

                            h3 {
                                margin: $base-margin + 3;
                                font: normal normal bold 25px/33px $font-primary;
                                letter-spacing: 0.07px;
                                color: $text-color;
                            }

                            p {
                                margin: 0px $base-margin + 3;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                color: #101010;
                                display: -webkit-box;
                                font: normal normal normal 16px/30px $font-secondary;
                                letter-spacing: 0.05px;
                                color: $text-color;
                                padding-bottom: $base-padding * 2;
                                overflow: hidden;
                            }
                        }

                        .in-between {
                            width: 463px;
                            height: 640px;
                            margin: 50px auto 25px;
                            display: $block;
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            margin-bottom: $base-margin * 2 + 5;
                            border-radius: $border-radius * 2;
                            filter: none;
                            object-fit: contain;
                        }

                        P {
                            text-align: left;
                            font: normal normal normal 18px/34px $font-secondary;
                            letter-spacing: 0.05px;
                            color: $text-dark;
                            margin-bottom: $base-margin * 5;
                            word-wrap: break-word;
                        }

                        h3 {
                            text-align: left;
                            font: normal normal 600 18px/29px $font-secondary;
                            letter-spacing: 0.05px;
                            color: $text-dark;
                            margin-bottom: $base-margin * 2 + 2;
                            border-bottom: 2px solid $secondary-dark;
                        }
                    }
                }
            }

            .obituaries-details-right {
                width: 314px;

                .obituaries-details-right-content {
                    .obituaries-latest-articles {
                        h2 {
                            font: normal normal bold 25px/33px $font-primary;
                            letter-spacing: 0.07px;
                            color: $text-color;
                        }

                        .latest-article-content {
                            border-bottom: 2px solid $secondary-dark;
                            margin-bottom: $base-margin + 2;
                            margin-top: $base-margin;

                            a {
                                display: $grid;
                                grid-template-columns: repeat(2, 1fr);
                                grid-gap: 0;
                                margin-bottom: 10px;


                                .latest-article-text-content {
                                    @include widthAndHeight(183px, auto, 0px, 0px, null, 1);
                                    margin-right: 6px;

                                    h3 {
                                        font: normal normal 600 16px/25px $font-secondary;
                                        letter-spacing: 0px;
                                        color: $text-color;
                                    }

                                    p {
                                        font: normal normal normal 16px/22px $font-secondary;
                                        letter-spacing: 0.05px;
                                        color: $text-color;
                                        margin-top: $base-margin - 1;
                                        @include text-overflow-clip(4);
                                    }
                                }

                                .latest-article-right-img {
                                    @include widthAndHeight(126px, 142px, 0px, 0px, null, 1);
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        filter: none;
                                        border-radius: unset;
                                        object-fit: cover;

                                        &:hover {
                                            transform: scale(1.04);
                                            transition: $allease-in-out;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }

                    .view-more-article {
                        margin: $base-margin * 2 + 3 auto;
                        text-align: center;
                        margin-bottom: 10px;
                        width: 314px;
                        overflow: hidden;

                        a {
                            width: 298px;
                            display: $block;
                            margin: 0px auto;
                            height: 60px;
                            text-align: center;
                            padding: $base-padding + 9px;
                        }
                    }
                }
            }
        }
    }
}

.find-out-more {
    background: $secondary 0% 0% no-repeat padding-box;
    border: 2px solid $secondary-dark;
    border-radius: 8px;
    text-align: center;
    padding: $base-padding * 2;
    margin: $base-margin * 2 0px;

    hr {
        display: none;
    }

    strong,
    em {
        font-style: normal;
        font-weight: 600;
    }

    p {
        text-align: center;
        font: normal normal 600 18px/29px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
        margin-bottom: 5px;
        padding: 0 10%;
    }

    a {
        width: 314px;
        height: 60px;
        text-align: center;
        margin: 0 auto;
        color: $primary;
    }
}

.obituaries-promotions {
    margin: 0px;
}

#obituaries-image-gallery {
    margin: 40px auto 0px;

    .galleryDisplay {
        height: 710px !important;
    }

    .galleria-theme-classic {
        background: transparent;
        overflow: hidden;
        position: relative;
    }

    .galleria-theme-classic .galleria-thumbnails-container {
        background: transparent;
        margin-bottom: unset;
    }

    .galleria-theme-classic.galleria-container {
        border-radius: 0px;
        height: 710px !important;
    }

    .galleria-theme-classic .galleria-info {
        display: none !important;
    }

    .galleria-theme-classic .galleria-carousel .galleria-thumb-nav-left,
    .galleria-theme-classic .galleria-carousel .galleria-thumb-nav-right {
        display: none;
    }

    .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
        margin-left: 73px;
        margin-right: 75px;
    }

    .galleria-theme-classic .galleria-thumbnails-container .galleria-thumbnails-list {
        margin-bottom: unset;
    }

    .galleria-theme-classic .galleria-thumbnails-container .galleria-thumbnails-list .galleria-thumbnails {
        margin: 0px auto;
    }

    .galleria-theme-classic .galleria-thumbnails-container .galleria-thumbnails-list .galleria-thumbnails .galleria-image img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: unset;
    }
}

.show-on-md {
    display: none;
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
    .obituaries-detail-wrapper {
        .container {
            width: $breakpoint-lg;
        }

        .hide-on-md {
            display: $block;
        }

        .show-on-md {
            display: none;
        }

        .obituaries-details-section {
            .obituaries-details-right {
                .obituaries-details-right-content {
                    .view-more-article a {
                        width: 298px;
                        margin: 0px auto;
                    }
                }
            }
        }
    }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
    .obituaries-detail-wrapper {
        .container {
            padding: $base-padding * 2 $base-padding * 2 + 7;
            width: $breakpoint-md;

            .obituaries-details-section {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $gap - 3;

                .obituaries-details-left {
                    width: 383px;

                    .obituaries-details-left-content {
                        width: 100%;

                        .obituaries-detail-header {

                            h1,
                            h2 {
                                font: normal normal bold 30px/40px $font-primary;
                            }

                            .obituaries-detail-sub-header {
                                .obituaries-publication-details {
                                    width: 74%;
                                    margin-top: 18px; //whitelabel loading $basemargin as 15px
                                }

                                .obituaries-share-btns {
                                    .share-tools {
                                        float: left;
                                        padding: $base-padding $base-padding - 2;

                                        .facebook-share {
                                            .icon-facebook {
                                                width: 36px;
                                                height: 36px;
                                            }
                                        }

                                        .x-share {
                                            padding: 3px;
                                            vertical-align: -10px;
                                            width: 36px;
                                            height: 36px;
                                            display: $flex;
                                        }

                                        .email-share {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .obituaries-detail-desc {
                            overflow: hidden;

                            figure figcaption {
                                .caption-details {
                                    padding-bottom: $base-padding - 2;

                                    span {
                                        display: block;
                                    }
                                }

                                border-bottom: 2px solid $secondary-dark;
                                font: normal normal 600 16px/29px $font-secondary;
                                margin-bottom: $base-margin * 2 - 2;

                                .caption-details>* {
                                    font: normal normal 600 16px/29px $font-secondary;
                                }
                            }

                            .in-between {
                                width: 100%;
                                height: auto;
                                margin: 50px auto 25px;
                                display: $block;
                            }
                        }
                    }
                }
            }
        }
    }

    .hide-on-md {
        display: none;
    }

    .show-on-md {
        display: $block;
    }

    #obituaries-image-gallery {
        margin: 40px auto 0px;
        width: 383px;
        height: auto;

        .galleryDisplay {
            height: 345px !important;
        }

        .galleria-theme-classic.galleria-container {
            height: 345px !important;
            width: 383px;
        }

        .galleria-theme-classic .galleria-stage {
            height: 245px;
            width: 383px;
        }

        .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
            margin-left: 72px;
            margin-right: 72px;
        }

        .galleria-theme-classic .galleria-thumbnails .galleria-image {
            height: 40px !important;
            width: 40px !important;
        }

        .galleria-theme-classic .galleria-thumbnails-container {
            height: 100px;
        }
    }
}

// For screen width >=768px
@include for-md-up {
    .articleImage {
        &.small {
            width: 25%;

            &.left {
                float: left;
                margin-right: 10px;
            }

            &.right {
                float: right;
            }
        }

        &.medium {
            width: 50%;
        }

        &.large {
            width: 75%;
        }

        &.full-width {
            width: 100%;
        }
    }

    .article-lead-image .articleImage {
        width: 100%;
    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
    .obituaries-detail-wrapper {
        .container {
            padding: $base-padding * 2;
            width: $breakpoint-sm;

            .obituaries-details-section {
                grid-template-columns: 1fr;
                grid-gap: $gap - 3;

                .obituaries-details-left {
                    width: 383px;

                    .obituaries-details-left-content {
                        width: 383px;

                        .obituaries-detail-header {

                            h1,
                            h2 {
                                font: normal normal bold 30px/40px $font-primary;
                            }

                            .obituaries-detail-sub-header {
                                .obituaries-publication-details {
                                    width: 74%;
                                    margin-top: 18px; //whitelabel loading $basemargin as 15px
                                }

                                .obituaries-share-btns {
                                    .share-tools {
                                        float: left;
                                        padding: $base-padding $base-padding - 2;

                                        .facebook-share {
                                            .icon-facebook {
                                                width: 36px;
                                                height: 36px;
                                                background-size: 100%;
                                                padding: 0px;
                                                filter: none;
                                            }
                                        }

                                        .x-share {
                                            padding: $base-padding - 6;
                                            vertical-align: -10px;
                                            width: 36px;
                                            height: 36px;
                                            display: $flex;

                                            .icon-twitter {
                                                filter: none;
                                            }
                                        }

                                        .email-share {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .obituaries-detail-desc {
                            overflow: hidden;

                            figure figcaption {
                                .caption-details {
                                    padding-bottom: $base-padding - 2;

                                    span {
                                        display: block;
                                    }
                                }

                                border-bottom: 2px solid $secondary-dark;
                                font: normal normal 600 16px/30px $font-secondary;
                                margin-bottom: $base-margin * 2+4;

                                .caption-details>* {
                                    font: normal normal 600 16px/30px $font-secondary;
                                }
                            }

                            .mb-show-obituaries {
                                display: $block;
                            }

                            .in-between {
                                width: 100%;
                                height: auto;
                                margin: 50px auto 25px;
                                display: $block;
                            }
                        }
                    }
                }

                .obituaries-details-right {
                    display: none;
                }
            }
        }
    }

    .hide-on-md {
        display: none;
    }

    .show-on-md {
        display: $block;
    }

    #obituaries-image-gallery {
        margin: 26px auto 55px;
        width: 383px;
        height: auto;

        .galleryDisplay {
            height: 350px !important;
        }

        .galleria-theme-classic.galleria-container {
            height: 350px !important;
            width: 383px;
        }

        .galleria-theme-classic .galleria-stage {
            height: 245px;
            width: 383px;
        }

        .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
            margin: 0px;
        }

        .galleria-theme-classic .galleria-thumbnails .galleria-image {
            height: 88px !important;
            width: 88px !important;
        }

        .galleria-theme-classic .galleria-thumbnails-container {
            height: 105px;
            padding: $base-padding - 2 0px;
        }
    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-sm-down {
    .obituaries-detail-wrapper {
        width: 100%;

        .container {
            padding: $base-padding * 2;
            width: 100%;

            .obituaries-details-section {
                grid-template-columns: 1fr;
                grid-gap: $gap - 3;

                .obituaries-details-left {
                    width: 100%;

                    .obituaries-details-left-content {
                        width: 100%;

                        .obituaries-detail-header {

                            h1,
                            h2 {
                                font: normal normal bold 30px/40px $font-primary;
                            }

                            .obituaries-detail-sub-header {
                                .obituaries-publication-details {
                                    width: 65%;
                                    margin-top: 18px;

                                    .ob-headTxt,
                                    .ob-subText {
                                        font: normal normal 600 16px/30px $font-secondary;
                                    }

                                    .ob-date-icon {
                                        vertical-align: -4px;
                                    }
                                }

                                .obituaries-share-btns {
                                    float: right;
                                    width: 29%;

                                    .share-tools {
                                        float: left;
                                        padding: $base-padding $base-padding - 2;

                                        .facebook-share {
                                            .icon-facebook {
                                                width: 36px;
                                                height: 36px;
                                                background-size: 100%;
                                                padding: 0px;
                                                filter: none;
                                            }
                                        }

                                        .x-share {
                                            padding: $base-padding - 6;
                                            vertical-align: -10px;
                                            width: 36px;
                                            height: 36px;
                                            display: $flex;

                                            .icon-twitter {
                                                filter: none;
                                            }
                                        }

                                        .email-share {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .obituaries-detail-desc {
                            overflow: hidden;

                            figure figcaption {
                                .caption-details {
                                    padding-bottom: $base-padding - 2;

                                    span {
                                        display: block;
                                    }
                                }

                                border-bottom: 2px solid $secondary-dark;
                                font: normal normal 600 16px/30px $font-secondary;
                                margin-bottom: $base-margin * 2+4;

                                .caption-details>* {
                                    font: normal normal 600 16px/30px $font-secondary;
                                }
                            }

                            .mb-show-obituaries {
                                display: $block;
                            }

                            .in-between {
                                width: 100%;
                                height: auto;
                                margin: 50px auto 25px;
                                display: $block;
                            }
                        }
                    }
                }

                .obituaries-details-right {
                    display: none;
                }
            }
        }
    }

    .hide-on-md {
        display: none;
    }

    .show-on-md {
        display: $block;
    }

    #obituaries-image-gallery {
        margin: 26px auto 55px;
        width: 100%;
        height: auto;

        .galleryDisplay {
            height: 350px !important;
        }

        .galleria-theme-classic.galleria-container {
            height: 350px !important;
            width: 100%;
        }

        .galleria-theme-classic .galleria-stage {
            height: 245px;
            width: 100%;
        }

        .galleria-theme-classic .galleria-carousel .galleria-thumbnails-list {
            margin: 0px;
        }

        .galleria-theme-classic .galleria-thumbnails .galleria-image {
            height: 88px !important;
            width: 88px !important;
        }

        .galleria-theme-classic .galleria-thumbnails-container {
            height: 105px;
            padding: $base-padding - 2 0px;
        }
    }

    .find-out-more {
        a {
            width: 100%;
            padding: $base-padding + 8px $base-padding;
        }
    }
}


@media only screen and (max-width: 390px) {
    .obituaries-detail-wrapper .container .obituaries-details-section .obituaries-details-left .obituaries-details-left-content .obituaries-detail-header .obituaries-detail-sub-header .obituaries-share-btns {
        float: right;
        width: 29%;
    }
}

@media only screen and (max-width: 360px) {
    .obituaries-detail-wrapper .container .obituaries-details-section .obituaries-details-left .obituaries-details-left-content .obituaries-detail-header .obituaries-detail-sub-header .obituaries-share-btns {
        float: right;
        width: 30%;
    }
}

@media only screen and (max-width: 320px) {
    .obituaries-detail-wrapper .container .obituaries-details-section .obituaries-details-left .obituaries-details-left-content .obituaries-detail-header .obituaries-detail-sub-header .obituaries-share-btns {
        float: right;
        width: 35%;
    }
}