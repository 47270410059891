.page-section {
  width: 100%;
  height: auto;

  .main-page{
    width: $breakpoint-xl;
    height:526px;
    overflow: hidden;
    background-image: url('./images/404.svg');
    background-repeat: no-repeat;
    background-position: 52%;
    background-size: 34%;
    margin: 0px auto;
  }

  .page-content{
    overflow: hidden;
    text-align: center;
    width: $breakpoint-xl;
    margin:0px auto;

    h1 {
      font: normal normal bold 42px/56px $font-primary;
      letter-spacing: 0px;
      color: $text-color;
      margin: 204px 0px 24px 0px;
    }
  
    p{
      font: normal normal normal 18px/29px $font-secondary;
      letter-spacing: -0.04px;
      color: $text-color;
      margin: 24px 0px 35px 0px;
    }
    .page-btn{
      width: 226px;
      height: 59px;
      padding: 16px 20px;
      margin: 0px;
    }
  }
}
//When the screen width >= 1920px, render the 1920px display
@include for-xl-up{
  .page-section{
    .main-page{
      width: $breakpoint-xl;
      .page-content {
        width: $breakpoint-xl;
        margin: 0px auto;
      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .page-section{
    .main-page{
      width: $breakpoint-lg;
      margin: 0px auto;
      .page-content {
        width: $breakpoint-lg;
        margin: 0px auto;
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .page-section{
    .main-page{
      width: $breakpoint-md;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 80%;
      margin: 0px auto;
      .page-content {
        width: $breakpoint-md;
        margin: 0px auto;
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  #details-page-wrapper{
    .place-a-notice .btn{
      padding: 5px 20px;
    }
    .page-content .btn{
      background: $primary;
      width: 338px;
      height: 70px;
      padding: 23px 106px;
      margin: 0px 45px;
    }
  }
  .page-section {
    width: 100%;
    height: auto;
  
    .main-page{
      width: 100%;
      background-size: 85% 85%;
      height: 566px;
      background-position-x: 60%;
      background-position-y: -100%;
      margin: 0px auto;
      .page-content{
        width: 100%;
        text-align: center;
        margin: 0px auto;
        h1 {
          font: normal normal bold 30px/40px $font-primary;
          letter-spacing: -0.03px;
          margin: 114px 37px 27px;
        }
        p{
          font: normal normal normal 16px/30px $font-secondary;
          letter-spacing: 0.05px;
          padding: 0px 45px;
        }
      }
    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  #details-page-wrapper{
    .place-a-notice .btn{
      padding: 5px 20px;
    }
    .page-content .btn{
      background: $primary;
      width: 338px;
      height: 70px;
      padding: 23px 106px;
      margin: 0px 45px;
    }
  }
  .page-section {
    width: 100%;
    height: auto;
  
    .main-page{
      width: 100%;
      background-size: 85% 85%;
      height: 566px;
      background-position-x: 60%;
      background-position-y: -100%;
      margin: 0px auto;
      .page-content{
        width: 100%;
        text-align: center;
        margin: 0px auto;
        h1 {
          font: normal normal bold 30px/40px $font-primary;
          letter-spacing: -0.03px;
          margin: 114px 37px 27px;
        }
        p{
          font: normal normal normal 16px/30px $font-secondary;
          letter-spacing: 0.05px;
          padding: 0px 45px;
        }
      }
    }
  }
}


