.text-article {
  text-align: left;
  font: normal normal bold 42px/56px $font-primary;
  letter-spacing: 0.13px;
  color: $text-color;
}

@mixin text-overflow-clip($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-overflow-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.standard-article {
  @include widthAndHeight(100%, auto, null, null, null, null);
  box-shadow: 0px -3px 6px $light-black;
  background: $white 0% 0% no-repeat padding-box;
  overflow: hidden;
  display: block;

  .container {
    margin: 0px auto;
    width: $breakpoint-lg;

    .md-read-articles {
      display: none;
    }

    .articles-header {
      display: $grid;
      margin-top: 92px;
      margin-bottom: 46px;
      grid-template-columns: repeat(2, 1fr);

      h2 {
        @include widthAndHeight(auto, 56px, null, null, null, null);
        @extend .text-article;
      }

      a {
        @include widthAndHeight(260px, 65px, null, null, null, null);
        background: $light1-grey 0% 0% no-repeat padding-box;
        border: 2px solid $primary;
        border-radius: 34px;
        text-align: center;
        display: $block;
        align-items: center;
        padding: 16px 20px;
        color: $primary;
        cursor: pointer;
        margin-top: 2px;
        float: right;
        font: normal normal 600 18px/27px $font-secondary;

        &:hover {
          background-color: $primary-lightest;
        }

        &::after {
          content: 'Read articles'
        }
      }
    }

    .article-grid {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: $gap + 11px;
      margin-bottom: $base-margin * 5 + 7;

      .article-left {
        width: 592px;
        border-bottom: 2px solid $secondary-dark;

        .article-left-img {
          @include widthAndHeight(592px, 448px, 0px, 0px, null, 1);
          overflow:hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:hover {
              transform: scale(1.04);
              transition: $allease-in-out;
            }
          }
        }

        .content {
          width: 592px;
          height: 165px;

          h3 {
            margin: 16px 0px 20px 0px;
            font: normal normal bold 29px/38px $font-primary;
            letter-spacing: 0.09px;
            color: $dark-grey;
            @extend .text-overflow-elipsis;
          }

          p {
            font: normal normal 300 18px/24px $font-secondary;
            letter-spacing: 0.05px;
            color: $dark-grey;
            @include text-overflow-clip(3);

          }
        }
      }

      .article-right {
        width: 657px;
        border-bottom: 2px solid $secondary-dark;

        #showInMobileView {
          display: none;
        }

        overflow: hidden;

        .article-content {
          border-bottom: 2px solid $secondary-dark;
          margin-bottom: $base-margin + 2;

          a {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 19px;
            height: 196px;

            margin-bottom: 12px;

            .article-right-img {
              @include widthAndHeight(178px, 180px, 0px, 0px, null, 1);
              overflow:hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &:hover {
                  transform: scale(1.04);
                  transition: $allease-in-out;
                }
              }
            }

            .text-content {
              width: 460px;

              h3 {
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                margin: 18px 0px;
                @extend .text-overflow-elipsis;
              }

              p {
                font: normal normal 18px/25px $font-secondary;
                letter-spacing: 0.05px;
                color: $dark-grey;
                @include text-overflow-clip(4);

              }
            }
          }

          &:last-child {
            border: none;
          }
        }

      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .standard-article {
    height: auto;

    .container {
      padding: 0px $base-padding * 3;

      .articles-header {
        display: $grid;

        h2 {
          font: normal normal 700 42px/40px $font-primary;
          letter-spacing: 0.12px;
        }

        a {
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          color: $primary;
          font: normal normal 600 18px/27px $font-secondary;
        }
      }

      .article-grid {
        .article-left {
          a {
            .article-left-img {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .content {
              h3 {
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              p {
                font: normal normal 300 18px/24px $font-secondary;
                letter-spacing: 0.05px;
                color: $dark-grey;
                @include text-overflow-clip(3);
              }
            }
          }
        }

        .article-right {
          width: 610px;

          .article-content {
            border-bottom: 2px solid $secondary-dark;

            a {
              display: grid;
              margin-bottom: 12px;

              .article-right-img {
                @include widthAndHeight(178px, 180px, 0px, 0px, null, 1);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .text-content {
                width: 400px;

                h3 {
                  font: normal normal bold 29px/38px $font-primary;
                  letter-spacing: 0.09px;
                  color: $dark-grey;
                  margin: 18px 0px;
                }

                p {
                  font: normal normal 18px/25px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(4);

                }
              }

            }
          }
        }
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .standard-article {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;

    .container {
      width: $breakpoint-md;
      padding: 0px 27px 50px;

      .articles-header {
        display: $grid;
        align-items: center;
        margin-top: 46px;

        h2 {
          @include widthAndHeight(auto, 40px, null, null, null, null);
          font: normal normal bold 30px/40px $font-primary;
          letter-spacing: 0.09px;
          color: $text-color;
        }

        a {
          @include widthAndHeight(201px, 55px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          color: $primary;
          padding: 14px 20px;
          font: normal normal 600 18px/27px $font-secondary;
        }
      }

      .article-grid {
        display: $grid;
        grid-template-columns: auto;
        margin: 0px;

        .article-left {
          width: 714px;
          border-bottom: 2px solid $secondary-dark;

          a {
            height: 196px;
            margin-bottom: 12px;
            display: $grid;
            grid-gap: $gap - 1;
            grid-template-columns: repeat(2, 1fr);

            .article-left-img {
              @include widthAndHeight(178px, 180px, null, null, null, null);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;

              }
            }

            .content {
              width: 522px;
              border-bottom: none;

              h3 {
                font: normal normal bold 20px/27px $font-primary;
                letter-spacing: 0.06px;
                color: $dark-grey;
              }

              p {
                font: normal normal 16px/24px $font-secondary;
                letter-spacing: 0.05px;
                color: $dark-grey;
                @include text-overflow-clip(4);
              }

            }
          }
        }

        .article-right {
          width: 714px;
          border: none;

          #showInMobileView {
            display: flex;
          }

          .article-content {
            border-bottom: 2px solid $secondary-dark;

            a {
              display: grid;
              margin-bottom: $base-margin + 2;
              height: 196px;

              .article-right-img {
                @include widthAndHeight(178px, 180px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .text-content {
                width: 522px;

                h3 {
                  font: normal normal bold 20px/27px $font-primary;
                  letter-spacing: 0.06px;
                  color: $dark-grey;
                }

                p {
                  font: normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(4);
                }

              }
            }

            &:last-child {
              border-bottom: 2px solid $secondary-dark;
            }
          }
        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .standard-article {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;

    .container {
      width: $breakpoint-sm;
      padding: 0px 22px 50px;

      .md-read-articles {
        display: block;

        a {
          @include widthAndHeight(382px, 65px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          display: $block;
          align-items: center;
          padding: 16px 20px;
          color: $primary;
          cursor: pointer;
          margin: 33px 0px 46px;
          float: right;
          font: normal normal 600 18px/27px $font-secondary;

          &:hover {
            background-color: $primary-lightest;
          }

          &::after {
            content: 'Read articles'
          }
        }
      }

      .articles-header {
        @include widthAndHeight(382px, 40px, null, null, null, null);
        border-bottom: 2px solid $lightest-brown;
        margin: 40px 0px 10px;

        .read-articles {
          display: none;
        }

        h2 {
          @include widthAndHeight(auto, 33px, null, null, null, null);
          font: normal normal bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
          margin-bottom: 5px;
        }

        a {
          @include widthAndHeight(382px, 72px, null, null, null, null);
          border: 2px solid $primary;
          border-radius: 34px;

          &::after {
            content: 'Read more articles';
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
          }
        }
      }

      .article-grid {
        display: $grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
        margin: 0px;

        .article-left {
          width: 382px;
          border: none;

          a {
            .article-left-img {
              @include widthAndHeight(382px, 220px, null, null, null, null);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .content {
              width: 382px;
              height: 168px;
              border-bottom: 2px solid $lightest-brown;
              margin-bottom: $base-margin;

              h3 {
                @include widthAndHeight(382px, 28px, null, null, null, null);
                font: normal normal bold 25px/29px $font-primary;
                letter-spacing: 0.07px;
                color: $text-dark;
              }

              p {
                @include widthAndHeight(382px, auto, null, null, null, null);
                font: normal normal 300 18px/24px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-dark;
                @include text-overflow-clip(3);
              }
            }
          }
        }

        .article-right {
          width: 382px;
          border: none;

          .article-content {
            a {
              display: block;
              height: 381px;
              width: 382px;
              margin-bottom: 20px;

              .article-right-img {
                @include widthAndHeight(382px, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .text-content {
                width: 382px;

                h3 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
      }
    }
  }

}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-sm-down {
  .standard-article {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;
    width: 100%;
    .container {
      width: 100%;
      padding: 0px 22px 50px;

      .md-read-articles {
        display: block;

        a {
          @include widthAndHeight(100%, 65px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          display: $block;
          align-items: center;
          padding: 16px 20px;
          color: $primary;
          cursor: pointer;
          margin: 33px 0px 46px;
          float: right;
          font: normal normal 600 18px/27px $font-secondary;

          &:hover {
            background-color: $primary-lightest;
          }

          &::after {
            content: 'Read articles'
          }
        }
      }

      .articles-header {
        @include widthAndHeight(100%, 40px, null, null, null, null);
        border-bottom: 2px solid $lightest-brown;
        margin: 40px 0px 10px;

        .read-articles {
          display: none;
        }

        h2 {
          @include widthAndHeight(90px, 33px, null, null, null, null);
          font: normal normal bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
          margin-bottom: 5px;
        }

        a {
          @include widthAndHeight(100%, 72px, null, null, null, null);
          border: 2px solid $primary;
          border-radius: 34px;

          &::after {
            content: 'Read more articles';
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
          }
        }
      }

      .article-grid {
        display: $block;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
        margin: 0px;

        .article-left {
          width: 100%;
          border: none;

          a {
            .article-left-img {
              @include widthAndHeight(100%, 220px, null, null, null, null);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .content {
              width: 100%;
              height: 168px;
              border-bottom: 2px solid $lightest-brown;
              margin-bottom: $base-margin;

              h3 {
                @include widthAndHeight(100%, 28px, null, null, null, null);
                font: normal normal bold 25px/29px $font-primary;
                letter-spacing: 0.07px;
                color: $text-dark;
              }

              p {
                @include widthAndHeight(100%, auto, null, null, null, null);
                font: normal normal 300 18px/24px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-dark;
                @include text-overflow-clip(3);
              }
            }
          }
        }

        .article-right {
          width: 100%;
          border: none;

          .article-content {
            a {
              display: block;
              height: 381px;
              width: 100%;
              margin-bottom: 20px;

              .article-right-img {
                @include widthAndHeight(100%, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .text-content {
                width: 100%;

                h3 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
      }
    }
  }

}
