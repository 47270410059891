@import "pikaday/scss/pikaday.scss";

.pika-table tr th {
  color:$primary;
}

.pika-button {
  background-color:$primary-darkest;
  color:$text-color;
}

.pika-button:hover{
  background-color: $primary;
  color:$white;
}

.pika-prev {
  float: left;
  background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
  left: 0;
  background-size:25px;
  filter: invert(74%) sepia(4%) saturate(2121%) hue-rotate(327deg) brightness(86%) contrast(81%);
}

.pika-next {
  float: right;
  background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
  right: 0;
  background-size:25px;

  filter: invert(74%) sepia(4%) saturate(2121%) hue-rotate(327deg) brightness(86%) contrast(81%);

}

.pika-single.is-bound {
  position: absolute !important; // Have to override pikaday.js which adds an inline style
  box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
  z-index: 99999999;
}

.pika-next:hover, .pika-prev:hover {
  opacity: 1;
  color:$secondary;
  transform: scale(1.2);
}

.is-today .pika-button {
  color:$primary;
  font-weight: bold;
  background-color: $primary-lightest;
}

.is-selected .pika-button, .has-event .pika-button{
  background: $primary;
  box-shadow: inset 0 1px 3px $primary;
  color:$white;
}

.is-disabled .pika-button {
  background-color: transparent;
  color:$text-color;
}


.displayHidden {
    display: none;
}

.calendarWrapper {
  position: relative;
}

#refinement-filters {
  #date-selector-from, #date-selector-to {
    position: relative;
    .calendar-icon {
      position: absolute;
      cursor: pointer;
      right: 0px;
      top: 13px;
    }
    input {
      width: 100%;
      // we have to reduce slightly the font for it to fit
      font-size: 13px;
      display: none;
    }
  }
}



.refinement-filter {
    .date-ico-bg:before {
        transform: translateY(-75%);
    }
}

// Mobile
@media only screen and (max-width: 428px) {
  .refinement-filter {
    .date-ico-bg:before {
        transform: translateY(-90%);
    }

    .calendar-icon {
        // not enough room on mobile to show the calendar-icon
        display: none;
    }
  }
  #refinement-filters {
    #date-selector-from, #date-selector-to {
      input {
        // not enough room on smaller viewports
        padding-left: $base-padding*0.5;
        &[type="date"] {
          display: inline-flex;
        }
      }
    }
  }
}
// Desktop
@media only screen and (min-width: 1280px) {
  #refinement-filters {
    #date-selector-from, #date-selector-to {
      input[type="text"] {
          display: inline-flex;
      }
    }
  }
}
