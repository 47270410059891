@mixin obituaries-overflow-clip($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}


.obituaries-listing {
  width: $breakpoint-lg;
  height: auto;
  overflow: hidden;
  margin: 0px auto;

  .obituaries-extend-search{
    overflow: hidden;
    background: $secondary;
    border-radius: $border-radius;
    padding:$base-padding * 2 $base-padding * 3 $base-padding * 3;
    margin: $base-margin * 3 + 5 $base-margin 0px;
    .obituaries-search-head{
      overflow: hidden;
      h2{
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        color: $text-dark;
        margin: 0px;
        line-height: 52px;
        margin-bottom: $base-margin + 4;
      }
    }
    .obituaries-extended-search{
      overflow: hidden;
      .obituaries-extend-form{
        position: relative;
        display: grid;
        grid-template-columns: 90.8% auto;
        grid-gap :4px;
      }
      .search-extended-block{
       overflow: hidden;
       display: block;
       position: relative;
        .searchImg{
          position: relative;
          left: 25px;
          z-index: 1;
          top: 25px;
        }
        .extendsearch{
          background: $white 0 0 no-repeat padding-box;
          border-radius: 38px 0 0 38px;
          height: 68px;
          padding-left: 61px;
          width: 100%;
          left: 0px;
          font: normal normal normal 18px/27px $font-secondary;
          letter-spacing: 1.08px;
          color: $dark-grey;
          position: absolute;
          border: 1px solid $white;
          &:focus-visible{
            outline: none;
          }
        }
        input[type="search"]::-webkit-search-cancel-button {
          cursor: pointer;
          -webkit-appearance: none;
          height: 20px;
          width: 20px;
          background-image: url("./images/icon-clear.png");
          background-repeat: no-repeat;
          background-position: center;

        }
      }
      .lg-go{
        display: block;
      }
      .mb-go{
        display: none;
      }
      .goButton{
        height: 68px;
        width: 105px;
        background: $primary 0 0 no-repeat padding-box;
        border: none;
        border-radius: 0 38px 38px 0;
        color: $white;
        cursor: pointer;
        font: normal normal 600 18px/27px $font-secondary;
      }
    }
  }

  .obituaries-wrapper {
    overflow: hidden;
    padding: 0px $base-padding;
    .no-results-found{
      h1{
        color: $text-color;
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        line-height: 52px;
        margin: $base-margin * 5 0px $base-margin * 4 + 4 0px;
      }
      p{
        font: normal normal normal 18px/31px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
      }
      .results-sub-head{
        text-align: left;
        font: normal normal 600 24px/35px $font-secondary;
        letter-spacing: 0px;
        color: $text-color;
      }
      .btn-home-primary{
        width: 226px;
        height: 59px;
        color: $white;
        display: block;
        background: $primary 0% 0% no-repeat padding-box;
        border-radius: 34px;
        margin-top: $base-margin * 3 + 4;
        padding: 16px 20px;
        text-align: center;
        cursor: pointer;
        font: normal normal 600 18px/27px $font-secondary;
      }
    }
    .obituaries-header-wrap {
      overflow: hidden;
      display: $flex;
      justify-content: space-between;
      margin: 41px 0px 44px 0px;

      h1 {
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        color: $text-color;
        line-height: 52px;
        margin: 0px;
      }
    }

    .obituaries-grid-tiles {
      display: $grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 0.8fr;
      grid-gap: $gap - 4px;

      .obit-advt-container {
        padding: 15px;
      }

      .obituaries-grid-item {

        width: 295px;
        height: 480px;

        .obit-image-wrapper {
          overflow: hidden;
          width: 295px;
          height: 284px;
          border-radius: 19px;
          margin-bottom: 10px;

          .obit-banner-img-card {
            align-items: center;
            border-radius: 3px 3px 0 0;
            display: $flex;
            height: 0;
            margin: -6px;
            overflow: hidden;
            padding-top: calc(100% + 12px);
            position: relative;

            img {
              margin-top: -100%;
              mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
              object-fit: contain;
              transition: transform .25s $allease-in-out;
              width: 100%;
              height: auto;
              display: $block;
              &:hover {
                transform: scale(1.15);
                transition: $allease-in-out;
              }
            }
          }
        }

        .obit-details {
          color: $text-color;
          width: 290px;

          .obit-title {
            font: normal normal bold 24px/32px $font-primary;
            margin-bottom: 8px;
            padding-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-bottom: 2px solid $lightest-brown;
          }

          .obit-desc {
            font: normal normal normal 18px/31px $font-secondary;
            letter-spacing: 0.05px;
            @include obituaries-overflow-clip(4);
          }
        }

      }

    }

    .obituaries-grid-tiles>.obit-promotion-activity {
      grid-column: 1;
      grid-row: 3;
      height: fit-content;
      margin: auto;
    }

    .obituaries-grid-tiles .obit-promotion-activity {
      grid-row: 1;
      grid-column: 4;
      width: 300px;
      max-width: 300px;
      box-sizing: content-box;

      h2 {
        text-align: center;
        font: normal normal bold 42px/95px $font-primary;
      }
    }

    .obituaries-grid-tiles .obituaries-grid-item:hover {
      box-shadow: $box-shadow-hover;
    }


  }
}

.pagination-wrapper {
  display: block;
  overflow: hidden;
  margin: 97px 0px 53px 0px;
}

.promotion-wrapper {
  display: block;
  overflow: hidden;
  margin: 50px 0px 44px 0px;
}

//When the screen width > 1920px, render the 1920px display
@include for-xl-up {
  .obituaries-listing {
    width: $breakpoint-lg;

    .obituaries-wrapper {
      .obituaries-grid-tiles>.obit-promotion-activity {
        grid-column: 4;
        grid-row: 1;
      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .obituaries-listing {
    width: $breakpoint-md;
    .obituaries-extend-search{
      border-radius: none;
      margin: $base-margin * 3 + 5 $base-margin * 2 + 7 0px;
      padding:20px 25px;
      .obituaries-search-head{
        display: none;
      }
      .obituaries-extended-search{
        .obituaries-extend-form{
          grid-template-columns: 83% auto;
          grid-gap :4px;
          .search-extended-block{
            .searchImg{
              left: 25px;
            }
          }
        }
      }
    }
    .obituaries-wrapper {
      padding: 0px $base-padding * 2 + 7px;

      .obituaries-header-wrap {
        margin: 25px 0px 15px 0px;

        h1 {
          height: 56px;
          font: normal normal bold 30px/95px $font-primary;
          line-height: 52px;
        }
      }

      .obituaries-grid-tiles {
        grid-template-columns: repeat(2, 1fr);

        .obit-advt-container {
          padding: 30px 0px;
        }

        .obituaries-grid-item {
          height: 381px;
          width: 346px;
          margin-bottom: $base-margin * 2 + 4px;

          .obit-image-wrapper {
            overflow: hidden;
            width: 345px;
            height: 236px;
          }

          .obit-details {

            width: 350px;

            .obit-title {
              font: normal normal bold 20px/27px $font-primary;
              letter-spacing: 0px;
            }

            .obit-desc {
              font: normal normal normal 16px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }


        }

      }

      .obituaries-grid-tiles>.obit-promotion-activity {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .obituaries-listing {
    width: $breakpoint-sm;
    .obituaries-extend-search{
      border-radius: 0px;
      margin: 0px;
      z-index: 9;
      padding:$base-padding * 2 + 5 $base-padding * 2 + 3;
      width: 428px;
      .obituaries-search-head{
        display: none;
      }
      .obituaries-extended-search{
        .obituaries-extend-form{
          grid-template-columns: auto;
          grid-gap :0px;
          height: 53px;
          width: 383px;
          .search-extended-block{
            height: 53px;
            .searchImg{
              left: 25px;
              top: 17px;
            }
            .extendsearch{
              border-radius: $border-radius * 3 + 4;
              height: 53px;
            }
          }
          .lg-go{
            display: none;
          }
          .mb-go{
            display: block;
          }
          .goButton{
            z-index: 9;
            position: absolute;
            right: 5px;
            bottom: 4px;
            height: 46px;
            top:4px;
            width: 46px;
            text-align: center;
            border-radius: $border-radius * 2 + 5;
            visibility: hidden;
            transform: translate3d(100px, 0, 0);
            cursor: pointer;
            transition: 0.30s all ease-in-out;
            opacity: 0;
            .goImg{
              margin-top: 7px;
              filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(108%) contrast(100%);
            }
          }
          .goButton-visible {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
    .obituaries-wrapper {
      padding: 0px $base-padding * 2;
      .no-results-found{
        margin-top: 40px;
        h1{
          margin: 0px 0px $base-margin * 2 + 2 0px;
        }
        .results-sub-head{
          margin-bottom: $base-margin + 5;
        }
      }
      .obituaries-header-wrap {

        h1 {
          letter-spacing: -0.03px;
          font: normal normal bold 30px/40px $font-primary;
          margin-bottom: $base-margin * 2 + 2;
        }
      }

      .obituaries-grid-tiles {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: $gap + 8px;

        .obit-advt-container {
          display: none;
        }

        .obituaries-grid-item {

          width: 383px;
          height: 522px;

          .obit-image-wrapper {
            width: 382px;
            height: 354px;
          }

          .obit-details {
            padding: 15px;
            width: 388px;

            .obit-title {
              font: normal normal bold 26px/35px $font-primary;
            }

            .obit-desc {
              font: normal normal normal 18px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }

        }

      }

      .obituaries-grid-tiles>.obit-promotion-activity {
        display: block;
        grid-column: 1;
        grid-row: 5;
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .obituaries-listing {
    width: 100%;
    .obituaries-extend-search{
      border-radius: 0px;
      margin: 0px;
      z-index: 9;
      padding:$base-padding * 2 + 5 $base-padding * 2 + 3;
      width: 100%;
      .obituaries-search-head{
        display: none;
      }
      .obituaries-extended-search{
        .obituaries-extend-form{
          grid-template-columns: auto;
          grid-gap :0px;
          height: 53px;
          .search-extended-block{
            height: 53px;
            .searchImg{
              left: 25px;
              top: 17px;
              position: absolute;
            }
            .extendsearch{
              border-radius: $border-radius * 3 + 4;
              height: 53px;
              position: relative;
            }
          }
          .lg-go{
            display: none;
          }
          .mb-go{
            display: block;
          }
          .goButton{
            z-index: 9;
            position: absolute;
            right: 5px;
            bottom: 4px;
            height: 46px;
            top:4px;
            width: 46px;
            text-align: center;
            border-radius: $border-radius * 2 + 5;
            visibility: hidden;
            transform: translate3d(100px, 0, 0);
            cursor: pointer;
            transition: 0.30s all ease-in-out;
            opacity: 0;
            .goImg{
              margin-top: 7px;
              filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(108%) contrast(100%);
            }
          }
          .goButton-visible {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
    .obituaries-wrapper {
      padding: 0px $base-padding * 2;
      .no-results-found{
        margin-top: 40px;
        h1{
          margin: 0px 0px $base-margin * 2 + 2 0px;
        }
        .results-sub-head{
          margin-bottom: $base-margin + 5;
        }
      }
      .obituaries-header-wrap {

        h1 {
          letter-spacing: -0.03px;
          font: normal normal bold 30px/40px $font-primary;
          margin-bottom: $base-margin * 2 + 2;
        }
      }

      .obituaries-grid-tiles {
        display: block;

        .obit-advt-container {
          display: none;
        }

        .obituaries-grid-item {

          width: 100%;
          height: auto;
          margin-bottom: $base-margin * 2 + 8px;
          display: block;

          .obit-image-wrapper {
            width: 100%;
            height: auto;
          }

          .obit-details {
            padding: 15px;
            width: 100%;

            .obit-title {
              font: normal normal bold 26px/35px $font-primary;
            }

            .obit-desc {
              font: normal normal normal 18px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }

        }

      }

      .obituaries-grid-tiles>.obit-promotion-activity {
        display: block;
        grid-column: 1;
        grid-row: 5;
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }
}
