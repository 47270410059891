select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

section {
  height: auto;

  .hero {
    height: 461px;
  }
}

.hero-banner {
  display: block;
  height: 461px;
  width: $breakpoint-xl;
  position: relative;
  left: 0;
  top: 0;
}

.banner {
  @include widthAndHeight($breakpoint-xl, 461px, 0px, 0px, absolute, 1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/banner_hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    background: $dark-color1 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 0.83;
  }

  .search {
    z-index: 9;

    h1 {
      @include widthAndHeight(955px, 56px, 95px, 452px, absolute, 1);
      text-align: left;
      font: normal normal bold 33px/51px $font-primary;
      letter-spacing: 0px;
      color: $white;
      text-shadow: 0px 3px 6px $light-black;
      margin: 0px;
    }

    .form {
      .searchBox {
        @include widthAndHeight(546px, 75px, 200px, 412px, absolute, 1);
        background: $white 0% 0% no-repeat padding-box;
        border-radius: 38px 0px 0px 38px;
        border: none;
        outline: none;
        padding-left: 80px;

        &::placeholder {
          @include widthAndHeight (74px, 23px, 0px, 0px, relative, 1);
          font: normal normal normal 16px/25px $font-secondary;
          letter-spacing: 0.96px;
          color: $dark-grey;
          text-align: left;
        }
      }

      .home-location {
        position: absolute;
        display: block;
        left: 961px;
        top: 200px;

        .tt-menu {
          position: absolute;
          top: 100%;
          left: 0px;
          z-index: 101;
          height: 250px;
          background: $white;
          display: none;
        }

        .tt-dataset-location {
          height: 250px;
          overflow-y: auto;
        }

        .tt-dataset.tt-dataset-fd {
          position: relative;
          background: $white;
          top: -17px;
          top: 4px;
          box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
          border-left: 5px solid transparent;
        }

        .tt-suggestion.Typeahead-selectable {
          border: none;
          padding: 10px 20px;
          font: normal normal 600 18px/27px $font-secondary;
        }

        .tt-suggestion.Typeahead-selectable:hover {
          background-color: $primary;
          color: $white;
        }

        .searchdropdown {
          @include widthAndHeight (287px, 75px, null, null, null, null);
          background: $white 0% 0% no-repeat padding-box;
          opacity: 1;
          border: none;
          outline: none;
          padding: 3px 30px 0px 55px;
          font-family: $font-secondary;
          text-overflow: ellipsis;

          &::placeholder {
            font: normal normal normal 16px/25px $font-secondary;
            letter-spacing: 0.96px;
            color: $dark-grey;
            text-align: left;
          }
        }
      }

      .clearable-input [data-clear-input] {
        background: #cacabd;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 1em;
        font-weight: 100;
        line-height: 1em;
        padding: 0 0.2em;
        position: absolute;
        right: 0;
        top: 40%;
        transform: translate(-50%);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        z-index: 999;
      }

      .radius-options {
        @include widthAndHeight (130px, 75px, 200px, 1251px, absolute, 1);
        background: $white 0% 0% no-repeat padding-box;
        border: none;
        outline: none;
        padding-left: 20px;
        padding-top: 3px;
        font-family: $font-secondary;
      }

      .goButton {
        @include widthAndHeight(124px, 75px, 200px, 1384px, absolute, 1);
        background: $primary 0% 0% no-repeat padding-box;
        border-radius: 0px 38px 38px 0px;
        text-align: center;
        font: normal normal normal 16px/25px $font-secondary;
        letter-spacing: 0px;
        color: $white;
        opacity: 1;
        border: none;
        cursor: pointer;

      }

      .searchImg {
        @include widthAndHeight(19px, 18px, 227px, 451px, absolute, 1);
        border-color: $light-brown;
      }


      .locationImg {
        @include widthAndHeight(18px, 22px, 227px, 981px, absolute, 1);
        border-color: $light-brown;
      }

      .dropdownImg {
        @include widthAndHeight(19px, 9px, 235px, 1351px, absolute, 1);
      }

    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  body #wrapper {
    margin: 0;
    max-width: 100%;
  }

  .hero-banner {
    display: block;
    width: 100%;
    height: 461px;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .banner {
    @include widthAndHeight(100%, 461px, 0px, 0px, absolute, 1);

    .search {
      width: $breakpoint-xl;
      height: 461px;
      margin: 0px auto;
      position: relative;
      z-index: 9;
    }
  }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  section.hero {
    height: 461px;
  }

  .hero-banner {
    display: block;
    width: 100%;
    height: 461px;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .banner {
    @include widthAndHeight(100%, 461px, 0px, 0px, absolute, 1);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/banner-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 20%;

    &:after {
      background: $dark-color1 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.83;
    }

    .search {
      width: 1280px;
      margin: 0px auto;
      display: block;
      height: 461px;
      position: relative;

      h1 {
        @include widthAndHeight(950px, 56px, 90px, 125px, absolute, 1);

      }

      .form {
        .searchBox {
          @include widthAndHeight(546px, 75px, 180px, 92px, absolute, 1);

          &::placeholder {
            @include widthAndHeight (74px, 23px, 0px, 0px, relative, 1);
          }
        }

        .home-location {
          position: absolute;
          display: block;
          left: 641px;
          top: 180px;

          .searchdropdown {
            @include widthAndHeight (287px, 75px, null, null, null, null);
          }
        }

        .radius-options {
          @include widthAndHeight (130px, 75px, 180px, 931px, absolute, 1);
        }


        .goButton {
          @include widthAndHeight(124px, 75px, 180px, 1064px, absolute, 1);
          background: $primary 0% 0% no-repeat padding-box;
          border-radius: 0px 38px 38px 0px;
          text-align: center;
          font: normal normal normal 16px/25px $font-secondary;
          letter-spacing: 0px;
          color: $white;
          opacity: 1;
          border: none;
          cursor: pointer;

        }

        .searchImg {
          @include widthAndHeight(17px, 16px, 210px, 131px, absolute, 1);
          border-color: $light-brown;
        }

        .locationImg {
          @include widthAndHeight(16px, 17px, 210px, 665px, absolute, 1);
          border-color: $light-brown;
        }

        .dropdownImg {
          @include widthAndHeight(16px, 17px, 210px, 1032px, absolute, 1);
        }
      }

    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  section.hero {
    height: 400px;
  }

  .hero-banner {
    display: block;
    width: 100%;
    height: 461px;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .banner {
    @include widthAndHeight(100%, 400px, 0px, 0px, absolute, 1);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/banner-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
      background: $dark-color1 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.83;
    }

    .search {
      position: relative;
      width: $breakpoint-md;
      height: 400px;
      margin: 0px auto;
      display: block;

      h1 {
        @include widthAndHeight(720px, 40px, 74px, 42px, absolute, 1);
        font: normal normal bold 26px/51px $font-primary
      }

      .form {
        .searchBox {
          @include widthAndHeight(322px, 55px, 151px, 27px, absolute, 1);
          background: $white 0% 0% no-repeat padding-box;
          border-radius: 38px 0px 0px 38px;
          padding-left: 70px;

          &::placeholder {
            @include widthAndHeight (74px, 23px, 0px, 5px, relative, 1);
          }
        }

        .home-location {
          position: absolute;
          display: block;
          left: 351px;
          top: 151px;

          .searchdropdown {
            @include widthAndHeight (203px, 55px, null, null, null, null);
            padding-left: 50px;
            font: normal normal normal 16px/25px $font-secondary;
            color: $black;

          }
        }

        .radius-options {
          @include widthAndHeight (100px, 55px, 151px, 556px, absolute, 1);
          background: $white 0% 0% no-repeat padding-box;
          border: none;
          padding-left: 12px;
          font: normal normal normal 16px/25px $font-secondary;
          padding-top: 3px;
          padding-right: 30px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .goButton {
          @include widthAndHeight(83px, 55px, 151px, 658px, absolute, 1);
        }

        .searchImg {
          @include widthAndHeight(19px, 18px, 170px, 51px, absolute, 1);
          border-color: $light-brown;
        }

        .locationImg {
          @include widthAndHeight(18px, 22px, 170px, 371px, absolute, 1);
          border-color: $light-brown;
        }

        .dropdownImg {
          @include widthAndHeight(16px, 17px, 171px, 631px, absolute, 1);
        }

      }

    }

  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  section.hero {
    height: 301px;
    overflow: hidden;
  }

  .hero-banner {
    display: block;
    width: $breakpoint-sm;
    height: 303px;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .banner {
    @include widthAndHeight(100%, 100%, 0px, 0px, absolute, 1);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/banner_hero.png') center/cover no-repeat;

    &:after {
      background: $dark-color1 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.83;
    }

    .search {
      h1 {
        @include widthAndHeight(98%, 145px, 90px, 5px, absolute, 1);
        font: 700 33px/normal $font-primary;
        color: $white;
        padding: 0 20px;
        text-align: center;
      }

      .form {
        display: none;
      }
    }
  }

}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  section.hero {
    height: 301px;
    overflow: hidden;
    width: 100%;
  }

  .hero-banner {
    display: $block;
    width: 100%;
    height: 303px;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .banner {
    @include widthAndHeight(100%, 100%, 0px, 0px, absolute, 1);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/banner_hero.png') center/cover no-repeat;

    &:after {
      background: $dark-color1 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.83;
    }

    .search {
      width: 100%;
      height: 301px;

      display: $flex;
      align-items: center;

      h1 {
        @include widthAndHeight(100%, 145px, null, null, unset, null);
        font: 700 33px/normal $font-primary;
        padding: 8px;
        text-align: center;
        color: $white;
      }

      .form {
        display: none;
      }
    }

  }
}