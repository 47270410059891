.enquire-abt-funeral {
    width: 897px;
    padding: $base-padding;
    background: $secondary 0% 0% no-repeat padding-box;
    border-radius: $border-radius-sm;
    margin-bottom: 50px;
    height: auto;
    overflow: hidden;

    .h-line {
        border: 0px;
        border-bottom: $bold 2px solid;
        opacity: 0.48;
        margin-top: 5px;
    }

    .enquire-location {
        width: 50%;
        float: left;
        padding: 12px 19px 12px 11px;

        .map-wrapper {
            border-radius: 10px;
            border: 1px solid $lightest-gray;
            overflow: hidden;

            #classifiedDetailMapAside {
                filter: drop-shadow(0 0 1px rgba(155, 155, 155, .6));
                height: 510px;
            }
        }
    }

    .enquire-funeral-location {
        .map-wrapper {
            height: 306px;
        }
    }

    .enquire-funeral-form {
        width: 50%;
        padding: 0px 10px;
        float: left;

        .alert-block {
            width: 100%;
        }

        h3 {
            margin-left: 0px;
            font: normal normal bold 25px/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
            width: 100%;

            .h-line {
                border-bottom: $secondary-dark 2px solid;
                opacity: 0.48;
            }

        }

        .eqform {
            p {
                color: $primary;
            }

            padding: $base-padding 0px;

            .fd-input {

                height: 45px;
                width: 390px;
                padding: 0px 25px;
                background: $white 0% 0% no-repeat padding-box;
                border-radius: 23px;
                margin-bottom: 10px;
                border: none;
                font: normal normal normal 18px/54px $font-secondary;
            }

            .fd-input-submit {
                background: $dark-green 0% 0% no-repeat padding-box;
                height: 45px;
                width: 390px;
                padding: 0px 25px;
                border-radius: 23px;
                margin-bottom: 10px;
                border: none;
                color: $white;
                cursor: pointer;
                font: normal normal normal 18px/27px $font-secondary;

            }

            .fd-cmsg-lbl {
                width: 269px;
                height: 25px;
                text-align: left;
                font: normal normal normal 18px/54px $font-secondary;
                letter-spacing: 0.05px;
                color: $text-dark;

            }

            .fd-comments {
                padding: 0px 25px;
                background: $white 0% 0% no-repeat padding-box;
                border-radius: 23px;
                margin-bottom: 10px;
                border: none;
                max-width: 390px;
            }

        }

        p {
            margin: 20px 0px 0px 0px;
            font: normal normal normal 18px/24px $font-secondary;
        }

        .funeral-submit-btn {
            background: $dark-green 0% 0% no-repeat padding-box;
            width: 100%;
            height: 59px;
            border-radius: 34px;
            opacity: 1;
            padding: 0px 25px;
            margin: 31px 0px 0px;
            border: none;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font: normal normal 600 18px/27px $font-secondary;
        }

        .funeral-submit-invert {
            width: 100%;
            height: 59px;
            border-radius: 34px;
            background: $secondary 0% 0% no-repeat padding-box;
            font: normal normal 600 18px/27px $font-secondary;
            border: 2px solid $dark-green;
            color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-top: 30px;
        }
    }

    .enquiry-fd {
        h3 {
            padding-top: 12px;
        }

    }
}


//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
    .enquire-abt-funeral {
        margin-bottom: 30px;
    }
}



//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
    .enquire-abt-funeral {
        width: 380px;
        padding: $base-padding + 3px;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 50px;
        height: auto;
        margin-top: 30px;

        .enquire-location {
            width: 100%;
            float: none;
            padding: 0px;

            h3 {
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                margin-bottom: 20px;
                width: 100%;

                .h-line {
                    border-bottom: $secondary-dark 2px solid;
                    opacity: 0.48;
                }
            }

            .map-wrapper {

                border-radius: 10px;

                #classifiedDetailMapAside {
                    filter: drop-shadow(0 0 1px rgba(155, 155, 155, .6));
                    height: 250px;
                }
            }
        }

        .enquire-funeral-location {
            .map-wrapper {
                #classifiedDetailMapAside {
                    height: 306px;
                }
            }
        }

        .enquire-funeral-form {
            width: 100%;
            padding: 0px;
            float: none;

            h3 {
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                width: 100%;
                margin-top: 10px;

                span {
                    font: normal normal bold 20px/27px $font-primary;
                }

            }

            .eqform {

                padding: $base-padding 0px;

                .fd-input {

                    height: 45px;
                    width: 354px;
                    padding: 0px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    font: normal normal normal 16px/54px $font-secondary;
                }

                .fd-input-submit {
                    background: $dark-green 0% 0% no-repeat padding-box;
                    height: 45px;
                    width: 354px;
                    padding: 0px 25px;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    color: $white;
                    cursor: pointer;
                    font: normal normal normal 16px/25px $font-secondary;

                }

                .fd-cmsg-lbl {
                    width: 269px;
                    height: 25px;
                    text-align: left;
                    font: normal normal normal 18px/54px $font-secondary;
                    letter-spacing: 0.05px;
                    color: $text-dark;

                }

                .fd-comments {
                    padding: 0px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    max-width: 354px;
                }

            }

        }


    }
}


//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
    .enquire-abt-funeral {
        width: 390px;
        padding: $base-padding + 3px;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 50px;
        height: auto;
        margin-top: 30px;

        .enquire-location {
            width: 100%;
            float: none;
            padding: 0px;

            h3 {
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                margin-bottom: 20px;

                .h-line {
                    border-bottom: $secondary-dark 2px solid;
                    opacity: 0.48;
                }



            }

            .map-wrapper {
                border-radius: 10px;

                #classifiedDetailMapAside {
                    filter: drop-shadow(0 0 1px rgba(155, 155, 155, .6));
                    height: 250px;
                }
            }
        }

        .enquire-funeral-location {
            .map-wrapper {
                #classifiedDetailMapAside {
                    height: 306px;
                }
            }
        }

        .enquire-funeral-form {
            width: 100%;
            padding: 0px;
            float: none;

            h3 {
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                width: 100%;
                margin-top: 10px;

                span {
                    font: normal normal bold 20px/27px $font-primary;
                }
            }

            .eqform {

                padding: $base-padding 0px;

                .fd-input {

                    height: 45px;
                    width: 354px;
                    padding: 0px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                }

                .fd-input-submit {
                    background: $dark-green 0% 0% no-repeat padding-box;
                    height: 45px;
                    width: 354px;
                    padding: 0px 25px;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    color: $white;
                    cursor: pointer;
                }

                .fd-cmsg-lbl {
                    width: 269px;
                    height: 25px;
                    text-align: left;
                    font: normal normal normal 18px/54px $font-secondary;
                    letter-spacing: 0.05px;
                    color: $text-dark;

                }

                .fd-comments {
                    padding: 10px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    max-width: 354px;
                }

            }

        }


    }

}



//When the screen width < 428px, render the 428px display
@include for-sm-down {
    .enquire-abt-funeral {
        width: 100%;
        padding: $base-padding + 3px;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: $border-radius-sm;
        margin-bottom: 50px;
        height: auto;
        margin-top: 30px;

        .enquire-location {
            width: 100%;
            float: none;
            padding: 0px;

            h3 {
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                margin-bottom: 20px;

                .h-line {
                    border-bottom: $secondary-dark 2px solid;
                    opacity: 0.48;
                }



            }

            .map-wrapper {
                border-radius: 10px;

                #classifiedDetailMapAside {
                    filter: drop-shadow(0 0 1px rgba(155, 155, 155, .6));
                    height: 250px;
                }
            }
        }

        .enquire-funeral-location {
            .map-wrapper {
                #classifiedDetailMapAside {
                    height: 306px;
                }
            }
        }

        .enquire-funeral-form {
            width: 100%;
            padding: 0px;
            float: none;

            h3 {
                height: auto;
                margin-left: 0px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                width: 100%;
                margin-top: 10px;

                span {
                    font: normal normal bold 20px/27px $font-primary;
                }
            }

            .eqform {

                padding: $base-padding 0px;

                .fd-input {

                    height: 45px;
                    width: 100%;
                    padding: 0px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                }

                .fd-input-submit {
                    background: $dark-green 0% 0% no-repeat padding-box;
                    height: 45px;
                    width: 100%;
                    padding: 0px 25px;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    color: $white;
                    cursor: pointer;
                }

                .fd-cmsg-lbl {
                    width: 100%;
                    height: 25px;
                    text-align: left;
                    font: normal normal normal 18px/54px $font-secondary;
                    letter-spacing: 0.05px;
                    color: $text-dark;

                }

                .fd-comments {
                    padding: 10px 25px;
                    background: $white 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    margin-bottom: 10px;
                    border: none;
                    width: 100%;
                }

            }

        }


    }
}