.funeral-director-info-premium-plus {
    width: 897px;
    padding: 20px;
    background: $secondary 0% 0% no-repeat padding-box;
    border-radius: $border-radius-sm;
    margin-bottom: 30px;

    .h-line {
        border: 0px;
        border-bottom: $bold 2px solid;
        opacity: 0.48;
        margin-top: 5px;
    }

    .showOnMobileView {
        display: none;
    }

    .ph-icon-premium-plus {
        background: url("././images/phone.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(40%) sepia(5%) saturate(278%) hue-rotate(228deg) brightness(88%) contrast(78%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;

    }

    .web-icon-premium-plus {
        background: url("././images/globe.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(40%) sepia(5%) saturate(278%) hue-rotate(228deg) brightness(88%) contrast(78%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .email-icon-premium-plus {
        background: url("././images/share-envelope.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(40%) sepia(5%) saturate(278%) hue-rotate(228deg) brightness(88%) contrast(78%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .home-icon-premium-plus {
        background: url("././images/home.svg") no-repeat;
        vertical-align: $middle;
        padding-left: 30px;
        filter: invert(40%) sepia(5%) saturate(278%) hue-rotate(228deg) brightness(88%) contrast(78%);
        background-size: 20px;
        background-position: 0% 60%;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    h3 {
        font: normal normal bold 25px/33px $font-primary;
        letter-spacing: 0.07px;
        color: $text-color;
    }

    .premium-plusinfo {
        padding: 0px 0px $base-padding + 5px 0px;

        .plusinfo-wrapper {
            .pinfo-item {
                .pinfo-headTxt {
                    width: 200px;
                    height: 28px;
                    text-align: left;
                    font: normal normal bold 20px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $text-dark;
                    vertical-align: $middle;
                }

                .pinfo-subText {
                    height: 28px;
                    text-align: left;
                    font: normal normal normal 20px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $text-dark;
                    padding-right: 22px;
                    vertical-align: $middle;
                    word-break: break-all;

                    a {
                        color: $primary-light;
                    }

                    a:hover {
                        text-decoration: underline;
                    }

                    a:visited {
                        color: $primary-link-dark;
                        text-decoration: underline;
                    }

                    span {
                        color: $text-dark;
                        vertical-align: $middle;
                        cursor: pointer;
                    }
                }

                .pinfo-subText1 {
                    height: 28px;
                    text-align: left;
                    font: normal normal normal 20px/30px $font-secondary;
                    letter-spacing: 0px;
                    color: $text-dark;
                    padding-right: 22px;
                    vertical-align: $middle;

                    span {
                        cursor: pointer;
                        color: $text-dark;
                        vertical-align: $middle;
                    }
                }
            }
        }

        .fd-offers {
            margin-top: 10px;

            .pinfo-section-wrapper {
                display: $grid;
                grid-template-columns: repeat(4, 0fr);
                margin-top: 20px;
                gap: 1.5em;

                .pinfo-section {
                    position: relative;
                    border-radius: $border-radius-sm;
                    background: $dark-green 0% 0% no-repeat padding-box;
                    display: $block;
                    justify-content: space-between;
                    align-items: center;
                    width: 188px;

                    .fd-offers-img-section {
                        text-align: center;
                        padding: 10px;

                        img {
                            vertical-align: $middle;
                            padding: 5px;
                            background-position: 50%;
                            height: 52px;
                        }
                    }

                    .pinfo-offerLink {
                        position: absolute;
                        bottom: 0;
                        height: 65px;
                    }

                    .pinfo-headTxt {
                        text-align: center;
                        font: normal normal bold 20px/54px $font-primary;
                        color: $white;
                        letter-spacing: -0.2px;
                        height: auto;
                        line-height: 1.15em;
                        width: 188px;
                        padding: 10px;
                        margin: 0 auto 60px;
                    }

                    .pinfo-subText {
                        width: 188px;
                        text-align: center;
                        font: normal normal normal 18px/26px $font-secondary;
                        letter-spacing: 0px;
                        color: $white;
                        display: inline-block;
                        padding: 15px;

                        a {
                            box-shadow: 0px 3px 6px $light-black;
                            border-radius: 38px;
                            background: $primary-lightest 0% 0% no-repeat padding-box;
                            border: none;
                            color: $primary;
                            cursor: pointer;
                            font: normal normal normal 18px/27px $font-secondary;
                            text-align: center;
                            padding: 8px 55px;
                        }
                    }
                }

                .info-pic-content {
                    float: right;
                    text-align: right;
                }
            }

            h3 {
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
            }
        }
    }
}



//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
    .funeral-director-info-premium-plus {
        width: 375px;
        padding: 10px;

        .showOnMobileView {
            display: none;
        }

        h3 {
            letter-spacing: 0.06px;
            font: normal normal bold 20px/27px $font-primary;
            color: $text-color;
        }

        .premium-plusinfo {
            .plusinfo-wrapper {
                .pinfo-item {
                    .pinfo-headTxt {
                        font: normal normal 600 16px/45px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .pinfo-subText {
                        font: normal normal normal 16px/45px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            cursor: pointer;
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/45px $font-secondary;
                        }
                    }

                    .pinfo-subText1 {
                        font: normal normal normal 16px/45px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            cursor: pointer;
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/45px $font-secondary;
                        }
                    }
                }
            }

            .fd-offers {
                .pinfo-section-wrapper {
                    display: $grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 20px;
                    gap: 1em;

                    .pinfo-section {
                        border-radius: 9px;
                        width: 168px;

                        .fd-offers-img-section {
                            img {
                                height: 63px;
                            }
                        }

                        .pinfo-offerLink {
                            height: 60px;
                        }

                        .pinfo-headTxt {
                            text-align: center;
                            font: normal normal bold 20px/27px $font-primary;
                            color: $white;
                            letter-spacing: -0.2px;
                            width: 168px;
                            margin-bottom: 55px;
                            word-break: normal;
                        }

                        .pinfo-subText {
                            width: 168px;
                            text-align: center;
                            font: normal normal 600 15px/23px $font-secondary;
                            letter-spacing: 0px;
                            color: $white;
                            display: inline-block;

                            a {
                                font: normal normal 600 15px/23px $font-secondary;
                                padding: 8px 52px;
                            }
                        }
                    }
                }

                h3 {
                    font: normal normal bold 25px/33px $font-primary;
                    letter-spacing: 0.07px;
                    color: $text-color;
                }
            }
        }
    }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
    .funeral-director-info-premium-plus {
        width: 390px;
        padding: 20px;

        .showOnMobileView {
            display: block;
        }

        h3 {
            letter-spacing: 0.07px;
            font: normal normal bold 22px/33px $font-primary;
            color: $text-color;
        }

        .premium-plusinfo {
            .plusinfo-wrapper {
                .pinfo-item {
                    .pinfo-headTxt {
                        font: normal normal 600 16px/42px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .pinfo-subText {
                        font: normal normal normal 16px/42px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/42px $font-secondary;
                            cursor: pointer;
                        }
                    }

                    .pinfo-subText1 {
                        font: normal normal normal 16px/42px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            cursor: pointer;
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/42px $font-secondary;
                        }
                    }
                }
            }

            .fd-offers {
                .pinfo-section-wrapper {
                    display: $grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 20px;
                    gap: 1em;

                    .pinfo-section {
                        border-radius: 9px;
                        width: 167px;

                        .pinfo-offerLink {
                            height: 60px;
                        }

                        .pinfo-headTxt {
                            color: $white;
                            font: normal normal bold 25px/27px $font-primary;
                            letter-spacing: -0.25px;
                            width: 167px;
                            margin-bottom: 55px;
                            text-align: center;
                            word-break: normal;
                        }

                        .pinfo-subText {
                            width: 167px;

                            text-align: center;
                            font: normal normal 600 16px/25px $font-secondary;
                            letter-spacing: 0px;
                            color: $white;
                            display: inline-block;

                            a {
                                font: normal normal 600 16px/25px $font-secondary;
                                padding: 8px 45px;
                            }
                        }
                    }
                }

                h3 {
                    font: normal normal bold 25px/33px $font-primary;
                    letter-spacing: 0.07px;
                    color: $text-color;
                }
            }
        }
    }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
    .funeral-director-info-premium-plus {
        width: 100%;
        padding: 20px;

        .showOnMobileView {
            display: block;
        }

        h3 {
            letter-spacing: 0.07px;
            font: normal normal bold 21px/33px $font-primary;
            color: $text-color;
        }

        .premium-plusinfo {
            .plusinfo-wrapper {
                .pinfo-item {
                    .pinfo-headTxt {
                        font: normal normal 600 16px/42px $font-secondary;
                        letter-spacing: 0px;
                    }

                    .pinfo-subText {
                        font: normal normal normal 16px/42px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/42px $font-secondary;
                            cursor: pointer;
                        }
                    }

                    .pinfo-subText1 {
                        font: normal normal normal 16px/42px $font-secondary;
                        letter-spacing: 0px;

                        span {
                            cursor: pointer;
                            color: $text-dark;
                            vertical-align: $middle;
                            font: normal normal normal 16px/42px $font-secondary;
                        }
                    }
                }
            }

            .fd-offers {
                .pinfo-section-wrapper {
                    display: $grid;
                    grid-template-columns: auto;
                    margin-top: 20px;
                    gap: 1em;

                    .pinfo-section {
                        border-radius: 9px;
                        width: 167px;
                        margin: 0px auto;

                        .pinfo-offerLink {
                            height: 60px;
                        }

                        .pinfo-headTxt {
                            color: $white;
                            font: normal normal bold 25px/27px $font-primary;
                            letter-spacing: -0.25px;
                            width: 167px;
                            margin-bottom: 55px;
                            text-align: center;
                            word-break: normal;
                        }

                        .pinfo-subText {
                            width: 167px;
                            text-align: center;
                            font: normal normal 600 16px/25px $font-secondary;
                            letter-spacing: 0px;
                            color: $white;
                            display: inline-block;

                            a {
                                font: normal normal 600 16px/25px $font-secondary;
                                padding: 8px 45px;
                            }
                        }
                    }
                }

                h3 {
                    font: normal normal bold 25px/33px $font-primary;
                    letter-spacing: 0.07px;
                    color: $text-color;
                }
            }
        }
    }
}