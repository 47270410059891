@mixin article-overflow-clip($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.article-text-overflow-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.articles-text-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.articles-textFormat {
  font: normal normal normal 18px/25px $font-secondary;
  letter-spacing: 1.08px;
  color: $dark-grey;
}

.articles-listing {
  width: $breakpoint-lg;
  height: auto;
  overflow: hidden;
  margin: 0px auto;
  .articles-search-block{
    overflow: hidden;
    background: $white-chocolate 0% 0% no-repeat padding-box;
    border-radius: $border-radius - 1;
    margin: 35px 10px 0px 10px;
    padding: $base-padding * 2 $base-padding * 3 $base-padding * 3 $base-padding * 3;
    width: 1260px;
    .articles-search-head{
      h2{
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        color: $text-dark;
        height: 56px;
        line-height: 52px;
        margin: 0px 0px $base-margin + 4 0px;
      }
    }
    .articles-search-extended{
      .articles-search-form{
        .articles-search-list{
          display: grid;
          grid-template-columns: 351px auto;
          grid-gap: 4px;
          .mb-category, .mb-type{
            display: none;
          }
          .search-extended-block{
            position: relative;
            .searchImg{
              display: none;
            }
          }
          .lg-articles-filters{
            display: grid;
            grid-template-columns: 323px 323px 182px;
            grid-gap: 4px;
          }

          .searchInut {
            @include widthAndHeight(351px, 68px, null, null, null, null);
            background: $white url("./images/search.svg") no-repeat;
            background-position: 30px 25px, 0px 0px;
            border-radius: 38px 0px 0px 38px;
            border: none;
            padding-left: 68px;
            padding-right: $base-padding + 5px;
            @extend .articles-text-elipsis ;
            @extend .articles-textFormat;
            &::placeholder {
              @include widthAndHeight(auto, 25px, 1px, 0px, relative, null);
            }
            outline: none;
          }
          input[type="search"]::-webkit-search-cancel-button {
            cursor: pointer;
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            background-image: url("./images/icon-clear.png");
            background-repeat: no-repeat;
            background-position: center;

          }

          .locationDropDown {
            @include widthAndHeight(323px, 68px, null, null, null, null);
            background: url("./images/Type.svg") no-repeat, url('./images/search-down.svg') center no-repeat;
            background-position: 25px 28px, 290px 28px;
            background-color: $white;
            padding-left: 60px;
            padding-right: 40px;
            border: none;
            &::placeholder {
              padding-top: 2px;
            }
            outline: none;
            @extend .articles-text-elipsis ;
            @extend .articles-textFormat;
          }

          .categoryDropDown {
            @include widthAndHeight(323px, 68px, null, null, null, null);
            background: url("./images/Category.svg") no-repeat, url('./images/search-down.svg') center no-repeat;
            background-position: 25px 26px, 290px 28px;
            background-color: $white;
            padding-right: 40px;
            padding-left: 60px;
            @extend .articles-text-elipsis ;
            @extend .articles-textFormat;
            border: none;
            outline: none;
          }

          .articles-goButton {
            @include widthAndHeight(182px, 68px, null, null, null, null);
            background: $primary 0% 0% no-repeat padding-box;
            border-radius: 0px 38px 38px 0px;
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            color: $primary;
            border: none;
            cursor: pointer;
            letter-spacing: 0px;
            color: $white;
          }
          .goButton{
            z-index: 1;
            position: absolute;
            right: 4px;
            bottom: 4px;
            height: 46px;
            background: $primary;
            top: 24px;
            width: 46px;
            text-align: center;
            border-radius: $border-radius * 2 + 5;
            visibility: hidden;
            transform: translate3d(100px, 0, 0);
            cursor: pointer;
            transition: 0.30s all ease-in-out;
            opacity: 0;
            border: none;
            .goImg{
              margin-top: 5px;
              filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(108%) contrast(100%);
            }
          }
          .mb-go{
            display: none;
          }
          .mb-articles-filters{
            display: none;
            text-align: center;
            span{
              font: normal normal 600 18px/54px $font-secondary;
              letter-spacing: 0.05px;
              color: $dim-secondary;
              border-bottom: 2px solid $dim-secondary;
              width: 125px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .articles-wrapper {
    overflow: hidden;
    padding: 0px $base-padding;
    .no-results-found{
      h1{
        color: $text-color;
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        line-height: 52px;
        margin: $base-margin * 5 0px $base-margin * 4 + 4 0px;
      }
      p{
        font: normal normal normal 18px/31px $font-secondary;
        letter-spacing: 0.05px;
        color: $text-color;
      }
      .results-sub-head{
        text-align: left;
        font: normal normal 600 24px/35px $font-secondary;
        letter-spacing: 0px;
        color: $text-color;
      }
      .btn-home-primary{
        width: 226px;
        height: 59px;
        color: $white;
        display: block;
        background: $primary 0% 0% no-repeat padding-box;
        border-radius: 34px;
        margin-top: $base-margin * 3 + 4;
        padding: 16px 20px;
        text-align: center;
        cursor: pointer;
        font: normal normal 600 18px/27px $font-secondary;
      }
    }
    .articles-header-wrap {
      overflow: hidden;
      display: $flex;
      justify-content: space-between;
      margin: 41px 0px 44px 0px;

      h1 {
        font: normal normal bold 42px/95px $font-primary;
        letter-spacing: 0.13px;
        color: $text-color;
        line-height: 52px;
        margin: 0px;
      }
    }

    .articles-grid-tiles {
      display: $grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 0.8fr;
      grid-gap: $gap - 4px;

      .article-advt-container {
        background: $white 0% 0% no-repeat padding-box;
        padding: 50px;
      }

      .articles-grid-item {
        width: 406px;
        height: 434px;
        background: $primary 0% 0% no-repeat padding-box;
        border-radius: 9px;
        transition: $allease-in-out;
        box-shadow: $box-shadow;
        transition: all .1s ease-out;


        .article-image-wrapper {
          overflow: hidden;
          width: 406px;
          height: 249px;
          border-radius: 9px 9px 0px 0px;

          .article-banner-img-card {
            overflow: hidden;
            max-height: 249px;

            img {
              object-fit: contain;
              width: 100%;
              height: auto;
              display: $block;
              &:hover {
                transform: scale(1.15);
                transition: $allease-in-out;
              }
            }
          }
        }

        .article-details {
          color: $white;
          height: 185px;
          padding: 18px 22px 23px;

          .article-details-title {
            font: normal normal bold 24px/32px $font-primary;
            margin-bottom: $base-margin + 5;
            @extend .article-text-overflow-elipsis;
          }

          .ad-desc {
            font: normal normal normal 18px/22px $font-secondary;
            letter-spacing: 0.05px;
            @include article-overflow-clip(4);
          }
        }

        &:hover {
          box-shadow: $box-shadow;
        }


      }

      .article-promotion-activity {
        box-sizing: content-box;
      }

      .ad-block.ad-mrec[data-google-query-id] {
        grid-row: 1;
        grid-column: 3;
        width: 314px;
        margin:auto;
      }
    }


  }
}

//When the screen width > 1920px, render the 1920px display
@include for-xl-up {
  .articles-listing {
    width: $breakpoint-lg;

    .articles-wrapper {
      .articles-grid-tiles .ad-block.ad-mrec[data-google-query-id] {
        grid-row: 1;
        grid-column: 3;
      }
    }
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .articles-listing {
    .articles-search-block{
      width: 1260px;
      padding: $base-padding * 2 $base-padding * 2 + 6 $base-padding * 3 $base-padding * 2 + 6;
      .articles-search-extended{
        .articles-search-form{
          .articles-search-list{
            .lg-articles-filters{
              grid-template-columns: 323px 323px 192px;
            }
            .articles-goButton {
              @include widthAndHeight(197px, 68px, null, null, null, null);
            }
          }
        }
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .articles-listing {
    width: $breakpoint-md;
    .articles-search-block{
      width: 768px;
      padding: $base-padding * 2 $base-padding * 2 + 7 $base-padding * 3;
      border-radius: 0px;
      margin: $base-margin * 3 + 5 0px 0px 0px;
      .articles-search-extended{
        .articles-search-form{
          .articles-search-list{
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 4px;
            .lg-articles-filters{
              display: grid;
              grid-template-columns: 180px 180px 170px;
              grid-gap: 4px;
            }
            .searchInut {
              @include widthAndHeight(170px, 68px, null, null, null, null);
            }
            .locationDropDown {
              @include widthAndHeight(180px, 68px, null, null, null, null);
              background-position: 25px 28px, 145px 30px;
            }

            .categoryDropDown {
              @include widthAndHeight(180px, 68px, null, null, null, null);
              background-position: 25px 26px, 145px 30px;
            }

            .articles-goButton {
              @include widthAndHeight(170px, 68px, null, null, null, null);
            }
          }
        }
      }
    }
    .articles-wrapper {
      padding: 0px $base-padding * 2 + 7px;

      .articles-header-wrap {
        margin: 25px 0px 15px 0px;

        h1 {
          font: normal normal bold 30px/95px $font-primary;
          line-height: 45px;
        }
      }

      .articles-grid-tiles {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $gap + 5px;

        .article-advt-container {
          padding: 30px 0px;
        }

        .articles-grid-item {
          height: 385px;
          width: 345px;

          .article-image-wrapper {
            overflow: hidden;
            width: 345px;
            height: 222px;
          }

          .article-details {
            height: 163px;
            padding: 20px;

            .article-details-title {
              font: normal normal bold 20px/27px $font-primary;
              letter-spacing: 0px;
              margin-bottom: 15px;

            }

            .ad-desc {
              font: normal normal normal 16px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }

        }

        .ad-block.ad-mrec[data-google-query-id] {
          grid-column: 2;
          grid-row: 2;
        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .articles-listing {
    width: $breakpoint-sm;
    .articles-search-block{
      width: 100%;
      padding: 0px $base-padding * 2 + 3;
      border-radius: 0px;
      margin: 0px;
      .articles-search-head{
        h2{
          display: none;
        }
      }
      .articles-search-extended{
        .articles-search-form{
          .articles-search-list{
            display: block;
            .mb-category, .mb-type{
              display: block;
              letter-spacing: 0.05px;
              color: $text-dark;
              font: normal normal normal 18px/54px $font-secondary;
              line-height: 22px;
              margin: 18px 0px 5px 0px;
            }
            .search-extended-block{
              .searchImg{
                position: relative;
                left: 25px;
                z-index: 1;
                top: 37px;
                display: block;
              }
              .searchInut {
                @include widthAndHeight(100%, 53px, null, null, null, null);
                background: $white;
                background-position: 20px 18px, 0px 0px;
                padding-left: 56px;
                padding-right: $base-padding + 5;
                border-radius: $border-radius * 3 + 8;
              }
              .goButton-visible {
                visibility: visible;
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }
              .mb-go{
                display: block;
              }
            }
            .locationDropDown {
              @include widthAndHeight(100%, 53px, null, null, null, null);
              background-position: 25px 20px, 332px 24px;
              padding-left: 56px;
              margin-bottom: $base-margin * 3 + 1;
              border-radius: $border-radius * 3 + 8;
            }

            .categoryDropDown {
              @include widthAndHeight(100%, 53px, null, null, null, null);
              background-position: 25px 18px, 332px 24px;
              border-radius: $border-radius * 3 + 8;
            }

            .articles-goButton {
              @include widthAndHeight(100%, 56px, null, null, null, null);
              border-radius: $border-radius * 3 + 8;
            }
            .mb-articles-filters{
              display: block;
              padding: 10px 0px 12px 0px;
            }
            .lg-articles-filters{
              display: none;
            }
            .lg-articles-filters.is-visible{
              visibility: visible;
              opacity: 1;
              display: block;
              transition: all .2s ease-in;
            }
          }
        }
      }
    }
    .articles-wrapper {
      padding: 0px $base-padding * 2;

      .articles-header-wrap {
        margin: 30px 0px 15px 0px;

        h1 {
          letter-spacing: -0.03px;
          font: normal normal bold 30px/40px $font-primary;
          width: 280px;
        }
      }

      .articles-grid-tiles {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: $gap;

        .article-advt-container {
          display: none;
        }

        .articles-grid-item {
          width: 382px;
          height: 432px;

          .article-image-wrapper {
            width: 382px;
            height: 249px;
          }

          .article-details {
            height: 183px;
            padding: 21px;
            width: 100%;
            .article-details-title {
              font: normal normal bold 26px/35px $font-primary;
              margin-bottom: 15px;
              letter-spacing: 0px;
            }

            .ad-desc {
              font: normal normal normal 18px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }

        }

        .ad-block.ad-mrec[data-google-query-id] {
          display: block;
          grid-column: 1;
          grid-row: 5;
        }
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .articles-listing {
    width: 100%;
    .articles-search-block{
      width: 100%;
      padding: 0px $base-padding * 2 + 3;
      border-radius: 0px;
      margin: 0px;
      .articles-search-head{
        h2{
          display: none;
        }
      }
      .articles-search-extended{
        .articles-search-form{
          .articles-search-list{
            display: block;
            .mb-category, .mb-type{
              display: block;
              letter-spacing: 0.05px;
              color: $text-dark;
              font: normal normal normal 18px/54px $font-secondary;
              line-height: 22px;
              margin: 18px 0px 5px 0px;
            }
            .search-extended-block{
              .searchImg{
                position: relative;
                left: 25px;
                z-index: 1;
                top: 37px;
                display: block;
              }
              .searchInut {
                @include widthAndHeight(100%, 53px, null, null, null, null);
                background: $white;
                background-position: 20px 18px, 0px 0px;
                padding-left: 56px;
                padding-right: $base-padding + 5;
                border-radius: $border-radius * 3 + 8;
              }

              .goButton-visible {
                visibility: visible;
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }
              .mb-go{
                display: block;
              }
            }
            .locationDropDown {
              @include widthAndHeight(100%, 53px, null, null, null, null);
              background-position: 25px 20px, 95% 24px;
              padding-left: 56px;
              margin-bottom: $base-margin * 3 + 1;
              border-radius: $border-radius * 3 + 8;
            }

            .categoryDropDown {
              @include widthAndHeight(100%, 53px, null, null, null, null);
              background-position: 25px 18px, 95% 24px;
              border-radius: $border-radius * 3 + 8;
            }

            .articles-goButton {
              @include widthAndHeight(100%, 56px, null, null, null, null);
              border-radius: $border-radius * 3 + 8;
            }
            .mb-articles-filters{
              display: block;
              padding: $base-padding 0px $base-padding + 2 0px;
            }
            .lg-articles-filters{
              display: none;
            }
            .lg-articles-filters.is-visible{
              visibility: visible;
              opacity: 1;
              display: block;
              transition: all .2s ease-in;
            }
          }
        }
      }
    }
    .articles-wrapper {
      padding: 0px $base-padding * 2;

      .articles-header-wrap {
        margin: 30px 0px 15px 0px;

        h1 {
          letter-spacing: -0.03px;
          font: normal normal bold 30px/40px $font-primary;
          width: 100%;
        }
      }

      .articles-grid-tiles {
        display: block;

        .article-advt-container {
          display: none;
        }

        .articles-grid-item {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: $base-margin * 2;
          overflow: hidden;


          .article-image-wrapper {
            width: 100%;
            height: auto;
          }

          .article-details {
            height: 183px;
            padding: 21px;
            width: 100%;
            .article-details-title {
              font: normal normal bold 26px/35px $font-primary;
              margin-bottom: 15px;
              letter-spacing: 0px;
            }

            .ad-desc {
              font: normal normal normal 18px/22px $font-secondary;
              letter-spacing: 0.05px;
            }
          }

        }

        .ad-block.ad-mrec[data-google-query-id] {
          display: block;
          grid-column: 1;
          grid-row: 5;
        }
      }
    }
  }

  .promotion-wrapper {
    display: block;
  }

}
