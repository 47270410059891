/*!
 * froala_editor v3.2.6-1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2021 Froala Labs
 */
 @import "src/vendor/froala.scss";

 .fr-view {
  word-wrap: break-word;

  p {
      padding: 10px 0px;
  }

  s {
      text-decoration: line-through !important;
  }

  u {
    text-decoration: underline !important;
}

ol li {
    list-style-type: decimal !important;
}

ul li {
    list-style-type: disc !important;
}

ul::marker,
ol::marker {
    text-align: left !important;
}

ul,
ol {
    list-style-position: inside !important;
    text-align: left;
    padding-left: 35px;
}

}


//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {

  .details-wrapper-content .container .details-right .details-right-content .inner-content .mb-view-header p {
      text-align: left;
  }
  .details-wrapper-content .container .details-right .details-right-content .inner-content .mb-view-header 
  .inner-content-desc .fr-view p {
      text-align: left !important;
  }
}