.articles-wrapper-content {

  box-shadow: 0px -3px 6px $light-black;
  background: $white 0% 0% no-repeat padding-box;
  opacity: 1;

  position: relative;
  top: 460px;
  width: 100%;
  height: 905px;

  .container {
    padding: 30px;
    width: 1800px;
    height: 500px;

    .centered.clearfix {

      .showOnMobileView { display: none;}

      .article-left {
        width: 430px;
        float: left;
        height: 515px;
        padding: 20px;


        .article-left-content {

          .inner-content-header {
            text-align: left;
            height: 100px;

            h3 {

              height: 45px;
              font: normal normal bold 25px/33px $font-primary;
              letter-spacing: 0.07px;
              color: $text-color;
              float: left;
            }


          }

          .left-inner-content {

            border-bottom: 2px solid $secondary-dark;

            height: 642px;
            width: 590px;

            .a-img {
              float: left;
              margin-right: 10px;

              img {
                width: 178px;
                height: 180px;
              }
            }

            .a-text-holder {
              h4 {
                text-align: left;
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                opacity: 1;
              }

              .a-text {
                text-align: left;
                font: normal normal 300 18px/24px Poppins;
                letter-spacing: 0.05px;
                color: $dark-grey;
                opacity: 1;

              }
            }
          }



        }

      }

      .article-right {
        width: 600px;
        float: left;
        padding: 20px;
        height: 500px;

        .article-right-content {

          width: 100%;

          .inner-content-link {
            text-align: right;
            height: 100px;

            .btn {
              padding: 16px 55px 17px 54px;
            }
          }

          .right-inner-content {

            border-bottom: 2px solid $secondary-dark;
            height: 200px;
            margin-bottom: 20px;

            .a-img {
              float: left;
              margin-right: 10px;

              img {
                width: 178px;
                height: 180px;
              }
            }

            .a-text-holder {
              h4 {
                text-align: left;
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                opacity: 1;
              }

              .a-text {
                text-align: left;
                font: normal normal 300 18px/24px Poppins;
                letter-spacing: 0.05px;
                color: $dark-grey;
                opacity: 1;

              }
            }
          }


        }


      }

    }

  }




}

/* Responsive Mobile Sizes */

@media only screen and (max-width: 428px) {


  .articles-wrapper-content {

    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;
    opacity: 1;

    position: relative; 
    width: 100%;
    height: 905px;

    .container {
      padding: 30px;
      width: 1800px;
      height: 500px;

      .centered.clearfix {


        .showOnMobileView { display: block;}

        .article-left {
          width: 430px;
          float: left;
          height: 515px;
          padding: 20px;


          .article-left-content {

            .inner-content-header {
              text-align: left;
              height: 100px;

              h3 {

                height: 45px;
                font: normal normal bold 25px/33px $font-primary;
                letter-spacing: 0.07px;
                color: $text-color;
                float: left;
              }


            }

            .left-inner-content {

              border-bottom: 2px solid $secondary-dark;

              height: 642px;
              width: 590px;

              .a-img {
                float: left;
                margin-right: 10px;

                img {
                  width: 178px;
                  height: 180px;
                }
              }

              .a-text-holder {
                h4 {
                  text-align: left;
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                  opacity: 1;
                }

                .a-text {
                  text-align: left;
                  font: normal normal normal 18px/25px Poppins;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  opacity: 1;

                }
              }
            }



          }

        }

        .article-right {
          width: 600px;
          float: left;
          padding: 20px;
          height: 500px;

          .article-right-content {

            width: 100%;

            .inner-content-link {
              text-align: right;
              height: 100px;

              .btn {
                padding: 16px 55px 17px 54px;
              }
            }

            .right-inner-content {

              border-bottom: 2px solid $secondary-dark;
              height: 200px;
              margin-bottom: 20px;

              .a-img {
                float: left;
                margin-right: 10px;

                img {
                  width: 178px;
                  height: 180px;
                }
              }

              .a-text-holder {
                h4 {
                  text-align: left;
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                  opacity: 1;
                }

                .a-text {
                  text-align: left;
                  font: normal normal normal 18px/25px Poppins;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  opacity: 1;

                }
              }
            }


          }


        }

      }

    }




  }



}


/* Responsive Desktop 1280 view */

@media only screen and (min-width:769px) and (max-width: 1280px) {}

/* Responsive Desktop 1280 view */



/* Responsive Tablet Sizes */
@media only screen and (min-width:430px) and (max-width: 768px) {}


/* Misc CSS */
.clearfix {

  &:before,
  &:after {
    content: "\0020";
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    clear: both;
  }

  .btn {
    padding: 0 12px;
  }
}

body {
  margin: 0;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}