@mixin text-overflow-clip($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-overflow-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.related-articles {
  @include widthAndHeight(100%, auto, null, null, null, null);
  box-shadow: 0px -3px 6px $light-black;
  background: $white 0% 0% no-repeat padding-box;
  overflow: hidden;
  display: block;

  .container {
    margin: 0px auto;
    width: $breakpoint-lg;

    .md-read-articles {
      display: none;
    }

    .header {
      display: $grid;
      margin-top: 92px;
      margin-bottom: 46px;
      grid-template-columns: repeat(2, 1fr);

      h2 {
        @include widthAndHeight(auto, 56px, null, null, null, null);
        text-align: left;
        font: normal normal bold 42px/56px $font-primary;
        letter-spacing: 0.13px;
        color: $text-color;
      }

      a {
        @include widthAndHeight(260px, 65px, null, null, null, null);
        background: $light1-grey 0% 0% no-repeat padding-box;
        border: 2px solid $primary;
        border-radius: 34px;
        text-align: center;
        display: $block;
        align-items: center;
        padding: 16px 20px;
        color: $primary;
        cursor: pointer;
        margin-top: 2px;
        float: right;
        font: normal normal 600 18px/27px $font-secondary;

        &:hover {
          background-color: $primary-lightest;
        }

        &::after {
          content: 'Read more articles'
        }
      }
    }

    .related-articles-grid {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: $gap + 11px;
      margin-bottom: $base-margin * 5 + 7;
      .related-articles-item {
        width: 625px;
        padding:13px 0px;
        border-bottom: 2px solid $secondary-dark;

        #showInMobileView {
          display: none;
        }

        overflow: hidden;

        .related-articles-content {
          border-bottom: 2px solid $secondary-dark;
          a {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 19px;
            .related-articles-img {
              @include widthAndHeight(178px, 180px, 0px, 0px, null, 1);
              overflow:hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &:hover {
                  transform: scale(1.04);
                  transition: $allease-in-out;
                }
              }
            }

            .related-articles-text {
              width: 430px;

              h2 {
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                margin: 18px 0px;
                @extend .text-overflow-elipsis;
              }

              p {
                font: normal normal 18px/25px $font-secondary;
                letter-spacing: 0.05px;
                color: $dark-grey;
                @include text-overflow-clip(4);

              }
            }
          }

          &:last-child {
            border: none;
          }

        }

      }

      .related-articles-item:first-child {
        width: 625px;
        grid-row: 1/span 3;
        padding:13px 0px;

        .related-articles-content {
          a {
            display: grid;
            grid-gap: 0px;
            grid-template-columns: auto;

            .related-articles-img {
              @include widthAndHeight(625px, 448px, 0px, 0px, null, 1);
              overflow:hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                &:hover {
                  transform: scale(1.04);
                  transition: $allease-in-out;
                }
              }
            }

            .related-articles-text {
              width: 625px;
              h2 {
                margin: 16px 0px 20px 0px;
                font: normal normal bold 29px/38px $font-primary;
                letter-spacing: 0.09px;
                color: $dark-grey;
                @extend .text-overflow-elipsis;
              }

              p {
                font: normal normal 300 18px/24px $font-secondary;
                letter-spacing: 0.05px;
                color: $dark-grey;
                @include text-overflow-clip(3);

              }
            }
          }
        }
      }
    }

  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .related-articles {
    height: auto;

    .container {
      padding: 0px $base-padding * 3;

      .header {
        display: $grid;

        h2 {
          font: normal normal 700 42px/40px $font-primary;
          letter-spacing: 0.12px;
        }

        a {
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          color: $primary;
          font: normal normal 600 18px/27px $font-secondary;
        }
      }

      .related-articles-grid {
        grid-column-gap:20px;
        .related-articles-item {
          width: 600px;
          .related-articles-content {
            border-bottom: 2px solid $secondary-dark;
            a {
              display: grid;
              grid-gap: 19px;
              .related-articles-img {
                @include widthAndHeight(178px, 180px, 0px, 0px, null, 1);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 400px;

                h2 {
                  font: normal normal bold 29px/38px $font-primary;
                  letter-spacing: 0.09px;
                  color: $dark-grey;
                  margin: 18px 0px;
                }

                p {
                  font: normal normal 18px/25px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(4);

                }
              }

            }
          }
        }

        .related-articles-item:first-child {
          width: 600px;

          .related-articles-content {
            a {
              grid-gap: 0px;

              .related-articles-img {
                width: 600px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 600px;

                h2 {
                  color: $dark-grey;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(3);
                }
              }
            }
          }
        }
      }

    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .related-articles {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;

    .container {
      width: $breakpoint-md;
      padding: 0px 27px 50px;

      .header {
        display: $grid;
        align-items: center;
        margin-top: 46px;

        h2 {
          @include widthAndHeight(auto, 40px, null, null, null, null);
          font: normal normal bold 30px/40px $font-primary;
          letter-spacing: 0.09px;
          color: $text-color;
        }

        a {
          @include widthAndHeight(241px, 55px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          color: $primary;
          padding: 14px 20px;
          font: normal normal 600 18px/27px $font-secondary;
        }
      }

      .related-articles-grid {
        display: $grid;
        grid-template-columns: auto;
        margin: 0px;
        grid-gap:0px;
        .related-articles-item {
          width: 714px;
          padding:0px;
          border: none;

          #showInMobileView {
            display: flex;
          }

          .related-articles-content {
            border-bottom: 2px solid $secondary-dark;
       
            a {
              display: grid;
              grid-column-gap: 19px;
              padding: 13px 0px;
              .related-articles-img {
                @include widthAndHeight(178px, 180px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 522px;

                h2 {
                  font: normal normal bold 20px/27px $font-primary;
                  letter-spacing: 0.06px;
                  color: $dark-grey;
                }

                p {
                  font: normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(4);
                }

              }
            }

            &:last-child {
              border-bottom: 2px solid $secondary-dark;
            }
          }
        }

        .related-articles-item:first-child {
          width: 714px;
          border: none;
          grid-row: auto;
          grid-gap:0px;
          padding:0px;

          #showInMobileView {
            display: flex;
          }

          .related-articles-content {
            border-bottom: 2px solid $secondary-dark;

            a {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              border-bottom: none;
              grid-column-gap: 19px;
              padding: 13px 0px;

              .related-articles-img {
                @include widthAndHeight(178px, 180px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 522px;

                h2 {
                  font: normal normal bold 20px/27px $font-primary;
                  letter-spacing: 0.06px;
                  color: $dark-grey;
                }

                p {
                  font: normal normal 16px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $dark-grey;
                  @include text-overflow-clip(4);
                }

              }
            }


          }

        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .related-articles {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;

    .container {
      width: $breakpoint-sm;
      padding: 0px 22px 40px;

      .md-read-articles {
        display: block;

        a {
          @include widthAndHeight(382px, 65px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          display: $block;
          align-items: center;
          padding: 16px 20px;
          color: $primary;
          cursor: pointer;
          margin: 33px 0px 1px;
          font: normal normal 600 18px/27px $font-secondary;

          &:hover {
            background-color: $primary-lightest;
          }

          &::after {
            content: 'Read more articles'
          }
        }
      }

      .header {
        @include widthAndHeight(382px, 40px, null, null, null, null);
        border-bottom: 2px solid $lightest-brown;
        margin: 40px 0px 0px;
        grid-template-columns: auto;

        .read-articles {
          display: none;
        }

        h2 {
          @include widthAndHeight(auto, 33px, null, null, null, null);
          font: normal normal bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
          margin-bottom: 5px;
        }

        a {
          @include widthAndHeight(382px, 72px, null, null, null, null);
          border: 2px solid $primary;
          border-radius: 34px;

          &::after {
            content: 'Read more articles';
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
          }
        }
      }

      .related-articles-grid {
        display: $grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 0px;
        grid-gap:0px;
        .related-articles-item {
          width: 382px;
          border: none;
          padding:0px;

          .related-articles-content {
            a {
              display: block;
              height: auto;
              width: 382px;
              padding:20px 0px 40px;
              grid-gap:0px;
              .related-articles-img {
                @include widthAndHeight(382px, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 382px;

                h2 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
        .related-articles-item:first-child{
          width: 382px;
          border: none;
          padding:0px;
          grid-gap:0px;

          .related-articles-content {
            a {
              display: block;
              height: auto;
              width: 382px;
              padding:20px 0px 40px;
              .related-articles-img {
                @include widthAndHeight(382px, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 382px;

                h2 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
      }
    }
  }

}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-sm-down {
  .related-articles {
    box-shadow: 0px -3px 6px $light-black;
    background: $white 0% 0% no-repeat padding-box;
    width: 100%;
    // overflow: unset;
    overflow: hidden;
    .container {
      width:100%;
      padding: 0px 22px 40px;

      .md-read-articles {
        display: block;

        a {
          @include widthAndHeight(100%, 65px, null, null, null, null);
          background: $light1-grey 0% 0% no-repeat padding-box;
          border: 2px solid $primary;
          border-radius: 34px;
          text-align: center;
          display: $block;
          align-items: center;
          padding: 16px 20px;
          color: $primary;
          cursor: pointer;
          margin: 33px 0px 1px;
          font: normal normal 600 18px/27px $font-secondary;

          &:hover {
            background-color: $primary-lightest;
          }

          &::after {
            content: 'Read more articles'
          }
        }
      }

      .header {
        @include widthAndHeight(100%, 40px, null, null, null, null);
        border-bottom: 2px solid $lightest-brown;
        margin: 40px 0px 0px;
        grid-template-columns: auto;

        .read-articles {
          display: none;
        }

        h2 {
          @include widthAndHeight(auto, 33px, null, null, null, null);
          font: normal normal bold 25px/33px $font-primary;
          letter-spacing: 0.07px;
          color: $text-color;
          margin-bottom: 5px;
        }

        a {
          @include widthAndHeight(100%, 72px, null, null, null, null);
          border: 2px solid $primary;
          border-radius: 34px;

          &::after {
            content: 'Read more articles';
            text-align: center;
            font: normal normal 600 18px/27px $font-secondary;
            letter-spacing: 0px;
            color: $primary;
          }
        }
      }

      .related-articles-grid {
        display: block;
        margin: 0px;
        .related-articles-item {
          width: 100%;
          border: none;
          padding:0px;

          .related-articles-content {
            a {
              display: block;
              height: auto;
              width: 100%;
              padding:20px 0px 40px;
              .related-articles-img {
                @include widthAndHeight(100%, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 100%;

                h2 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
        .related-articles-item:first-child{
          width: 100%;
          border: none;
          padding:0px;

          .related-articles-content {
            a {
              display: block;
              height: auto;
              width: 100%;
              padding:20px 0px 40px;
              .related-articles-img {
                @include widthAndHeight(100%, 220px, null, null, null, null);

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .related-articles-text {
                width: 100%;

                h2 {
                  font: normal normal bold 25px/29px $font-primary;
                  letter-spacing: 0.07px;
                  color: $text-dark;
                }

                p {
                  font: normal normal 300 18px/24px $font-secondary;
                  letter-spacing: 0.05px;
                  color: $text-dark;
                  @include text-overflow-clip(3);
                }
              }
            }

            &:last-child {
              border-bottom: 2px solid $lightest-brown;
            }

          }
        }
      }
    }
  } 
}
