.refine-search-filter {
  width: 315px;
  padding: 10px;
  background: $secondary;
  border-radius: $border-radius-sm;
  border: 1px solid $secondary-dark;

  select::-ms-expand {
    display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    border: none;
    outline: none;
  }

  .textFormat {
    color: $text-dark;
    font: normal 18px/normal $font-secondary;
    outline: none;
  }

  input {
    &:focus {
      outline: none;
      border: none;
    }
  }

  input[type="search"]::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }

  .refine-search-filter-header {
    padding-bottom: 5px;
    border-bottom: 1px solid $secondary-dark;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: $text-dark;
      font: bold 26px/normal $font-primary;
    }

    #refine-close {
      display: none;
    }
  }

  .refine-search-filter-form {
    padding: 15px 0 20px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .search {
      position: relative;

      .searchInput {
        width: 100%;
        height: 50px;
        padding: 0 20px 0 48px;
        @extend .textFormat;
        background-color: $white;
        border-radius: 100px;
        border: 1px solid $secondary-dark;
      }

      .searchImg {
        @include widthAndHeight(24px, 24px, 14px, 20px, absolute, 1);
      }
    }

    .category {
      width: 100%;
      height: 50px;
      padding: 0px 42px 0px 17px;
      @extend .textFormat;
      background: url('./images/search-down.svg') center no-repeat;
      background-position: calc(100% - 20px);
      background-color: $white;
      border-radius: 100px;
      border: 1px solid $secondary-dark;
    }

    .clearable-input [data-clear-sidebar-input] {
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      display: none;
      color: $white;
      background: $grey-light;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1em;
      font-weight: 100;
      line-height: 1em;
      padding: 0 0.2em;
      position: absolute;
      right: 10px;
      top: 35%;
      transform: translate(-50%);
      z-index: 9;
    }

    .location {
      position: relative;

      span.twitter-typeahead {
        width: 100%;
      }

      .tt-menu {
        height: 250px;
        display: none;
        background: $white;
        position: absolute;
        top: 100%;
        left: 0px;
        z-index: 99;
      }

      .tt-dataset-location {
        height: 250px;
        overflow-y: auto;
      }

      .tt-dataset.tt-dataset-fd {
        background: $white;
        position: relative;
        top: 4px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
        border-left: 5px solid transparent;
      }

      .tt-suggestion.Typeahead-selectable {
        border: none;
        padding: 10px 20px;
        font: normal 600 18px/27px $font-secondary;

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }

      .locationDropDown {
        height: 50px;
        width: 100%;
        padding: 0px 42px 0px 17px;
        @extend .textFormat;
        background-color: $white;
        border-radius: 100px;
        border: 1px solid $secondary-dark;

        &::placeholder {
          color: $dark-grey;
          text-align: left;
        }
      }
    }

    .locality-radius {
      width: 100%;
      height: 50px;
      padding: 0px 42px 0px 17px;
      @extend .textFormat;
      background: url('./images/search-down.svg') center no-repeat;
      background-position: calc(100% - 20px);
      background-color: $white;
      border-radius: 100px;
      border: 1px solid $secondary-dark;
    }

    .publication {
      width: 100%;
      height: 50px;
      padding: 0px 42px 0px 17px;
      @extend .textFormat;
      background: url('./images/search-down.svg') center no-repeat;
      background-position: calc(100% - 20px);
      background-color: $white;
      border-radius: 100px;
      border: 1px solid $secondary-dark;
    }

    .daterange-dropdown {
      display: none;
      width: 100%;
      height: 43px;
      padding-left: 15px;
      color: $text-dark;
      font: 400 #{$base-font-size - 2px}/normal $font-secondary;
      background: $white url("./images/search-down.svg") no-repeat calc(100% - 15px);
      border-radius: 21.5px;
      border: 1px solid $secondary-dark;
      outline: none;

      &::after {
        position: relative;
        top: 10px;
        color: $text-dark;
        font: 400 $base-font-size/normal $font-secondary;
      }
    }

    .date-calendar {
      width: 100%;
      position: relative;

      display: flex;
      justify-content: space-between;

      .from_calendar {
        width: 49%;
        height: 50px;
        padding-right: 30px;
        padding-left: $base-padding + 5px;
        @extend .textFormat;
        @extend .text-overflow-elipsis;
        background: url("./images/filter-date.svg") center no-repeat;
        background-position: calc(100% - 13px);
        background-color: $white;
        border-radius: 100px 0 0 100px;
        border: 1px solid $secondary-dark;
      }

      .to_calendar {
        width: 49%;
        height: 50px;
        padding-right: 40px;
        padding-left: $base-padding;
        @extend .textFormat;
        @extend .text-overflow-elipsis;
        background: url("./images/filter-date.svg") center no-repeat;
        background-color: $white;
        background-position: calc(100% - 13px);
        border-radius: 0 100px 100px 0;
        border: 1px solid $secondary-dark;
      }

      #calendarModalToLeft {
        position: absolute;
        left: 0;

        .pika-single {
          left: 0;
        }
      }

      #calendarModalToRight {
        position: absolute;
        right: 0;

        .pika-single {
          right: 0;
        }
      }
    }

    .director {
      span.twitter-typeahead {
        width: 100%;
      }

      .tt-menu {
        display: none;
        height: 100%;
        background: $white;
        position: absolute;
        top: 100%;
        left: 0px;
        z-index: 99;
      }

      .tt-dataset.tt-dataset-fd {
        background: $white;
        position: relative;
        top: 4px;
        border-left: 5px solid transparent;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
      }

      .tt-suggestion.Typeahead-selectable {
        border: none;
        padding: 10px 20px;
        font: normal 600 18px/27px $font-secondary;

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }

      .tt-dataset.tt-dataset-fd {
        height: 200px;
        background: $white;
        position: relative;
        top: 4px;
        left: 0px;
        overflow-y: scroll;
        border-left: 5px solid transparent;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
      }

      .funeral_directors_rf {
        width: 100%;
        height: 50px;
        padding: 0px 20px 0px 17px;
        @extend .textFormat;
        background-position: calc(100% - 0.99rem);
        border-radius: 100px;
        border: 1px solid $secondary-dark;
      }
    }

    .refine-search {
      height: 50px;
      color: $white;
      background: $primary;
      cursor: pointer;
      border-radius: 34px;
      border: 1px solid transparent;
      font: 600 18px/normal $font-secondary;

      &:hover {
        background: $primary-light;
      }
    }

    .clear-search {
      height: 50px;
      color: $primary;
      background: $secondary-light;
      cursor: pointer;
      border-radius: 34px;
      border: 1px solid $primary;
      font: 600 18px/normal $font-secondary;

      &:hover {
        background: $primary-lightest;
      }
    }
  }
}

// For screen width less than 768px
@include for-md-down {
  .funeral_listing_wrapper .funeral-listing-flex .left-column {
    display: block;
  }

  .refine-search-filter {
    display: none;
    width: 100%;
    height: 100vh;
    padding: 20px;
    border: none;

    // to overlap whitelabel site headers
    position: absolute;
    top: 0;
    z-index: 9999999;

    .textFormat {
      font-size: 16px;
    }

    .refine-search-filter-header #refine-close {
      display: block;
      font-size: 18px;
      cursor: pointer;
    }

    .refine-search-filter-form {
      padding-top: 20px;
      gap: 20px;

      .search .searchImg {
        top: 10px;
      }

      .search .searchInput,
      .category,
      .location .locationDropDown,
      .locality-radius,
      .publication,
      .director .funeral_directors_rf {
        height: 43px;
        border-radius: 21.5px;
      }

      .daterange-dropdown {
        display: block;
      }

      .date-calendar .from_calendar {
        height: 43px;
        border-radius: 21.5px 0 0 21.5px;
      }

      .date-calendar .to_calendar {
        height: 43px;
        border-radius: 0 21.5px 21.5px 0;
      }

      .refine-search,
      .clear-search {
        height: 43px;
        font-size: 16px;
        border-width: 1px;
      }
    }
  }
}