.hideArrow {
  -webkit-appearance: none;
  text-indent: 0;
  border: 0;
  outline: 0;
}


select#selectDropdown1,
select#selectDropdown2,
select#selectMbDropdown1,
select#selectMbDropdown2 {
  @extend .hideArrow;
}

select option {
  margin: 40px;
  background: rgb(255, 254, 254);
  color: $black;
}

.recent-notices-no-data {
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  h3 {
    font: 700 normal calc($base-font-size + 14px)/normal $font-primary;
  }

  p {
    font: 400 normal $base-font-size/normal $font-secondary;
  }

  .view-notice-btn {
    @include buttonCommon(192px, 50px, null, null, null, null, $primary, none, 100px);
    padding: 10px 35px;

    &::after {
      @include buttonContent("View notices", $white, 18px);
    }

    &:hover {
      background-color: $primary-light;
    }
  }
}

.mb-recent-notices {
  display: none;

  .recent-notices-no-data {
    margin: 40px 25px auto;

    h3 {
      font-size: calc($base-font-size + 11px);
    }

    p {
      font-size: $base-font-size;
    }
  }
}

section {
  height: auto;
}

.recent-notices-head {
  height: 78px;
  margin: 0 auto;
  position: absolute;
  margin-top: -78px;
  width: $breakpoint-xl;
  z-index: 9;
}

.recent-notices {
  display: block;
  margin-bottom: 135px;
  width: $breakpoint-xl;

  .container {
    .headerSection {
      @include widthAndHeight(1096px, 78px, null, 412px, absolute, null);
      padding: 0 40px;
      border-radius: 10px 10px 0px 0px;
      background: $dark-green 0% 0% no-repeat padding-box;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 60px;

      h2 {
        color: $white;
        margin-right: auto;
        letter-spacing: 0.13px;
        font: normal normal 700 calc(2 * $base-font-size + 6px)/56px $font-primary;
      }

      .selectDropdown1,
      .selectDropdown2 {
        color: $white;
        padding-right: 30px;
        letter-spacing: 1.02px;
        font: normal normal normal calc($base-font-size - 1px)/26px $font-secondary;
        background: url('./images/icon-ionic-ios-arrow-down.svg') no-repeat;
        background-position: right;
      }
    }

    .recent-notice-grid {
      @include widthAndHeight(1248px, 650px, null, null, null, null);
      margin: 0px auto;
      border-radius: 10px;
      border: 1px solid $secondary-dark;
      background: $secondary 0% 0% no-repeat padding-box;

      .nav {
        display: flex;

        width: 1096px;
        margin: 0 auto;

        a {
          flex: 1;

          text-align: center;
          color: $secondary-dark;
          padding: 20px 0;
          letter-spacing: 1.08px;
          border-bottom: 2px solid $secondary-dark;
          font: normal normal 600 $base-font-size/normal $font-secondary;

          &:hover {
            color: $dark-green;
          }

          &.active {
            color: $dark-green;
            background: $floral-white;
            border-bottom: 3px solid $dark-green;
          }
        }
      }

      .grid {
        margin: 0px 40px;
        height: auto;

        .recent-notice-items {
          display: block;
          height: 470px;
          overflow: hidden;
        }

        .notices_item {
          display: $grid;
          justify-content: space-between;
          max-height: 440px;
          overflow-x: hidden;
          overflow-y: auto;
          border-bottom: 1px solid $secondary;
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: .8fr;

          .notice-card {
            width: 272px;
            height: 95px;
            background: $white 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            margin: 10px 18px 10px 0px;
            padding: 22px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $dark-grey;

            &:hover {
              transform: scale(.96);
              transition: $allease-in-out;
            }

            .name {
              width: 160px;
              height: 25px;
              text-align: left;
              font: normal normal normal 18px/27px $font-secondary;
              letter-spacing: 1.08px;
              color: $dark-grey;
              opacity: 1;

            }

            .location_date {
              display: $flex;
              justify-content: space-between;
              height: 23px;
              text-align: left;
              font: normal normal normal 16px/25px $font-secondary;
              letter-spacing: 0.96px;
              color: $grey;
              opacity: 1;

              .location {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 125px;
              }
            }
          }

          .recent-notices-no-data {
            grid-column: 1 / -1;

            width: 640px;
            padding: 30px 40px;
            margin: 50px auto auto auto;
            border-radius: 10px;
            border: 1px solid $secondary-dark;
            background: $floral-white;
          }
        }

        hr {
          position: relative;
          left: 0px;
          width: 1162px;
          height: 0px;
          border: 0px;
          border-bottom: 1px solid $secondary-dark;
          opacity: 1;
          margin-top: 15px;
        }

        .viewAll {
          a {
            float: right;
            border-bottom: 3px solid $primary;
            color: $primary;
            font: normal normal 600 16px/25px $font-secondary;
            padding-right: 0px;
            padding-top: 12px;
            padding-bottom: 3px;

            img {
              height: 6px;
              transform: rotate(270deg);
              vertical-align: middle;
              padding-top: 0px;
              margin-left: 10px;
              filter: invert(78%) sepia(50%) saturate(2737%) hue-rotate(114deg) brightness(95%) contrast(91%);
            }
          }
        }

      }

      .grid .viewAll:hover {
        a {
          color: $primary-light;
          border-bottom: 3px solid $primary-light;
        }
      }

      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        height: 380px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $grey;
        border-radius: 8px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $grey;
        border-radius: 8px;
      }
    }
  }
}

//When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  .recent-notices {
    margin: 0px auto 136px;
  }
}

//When the screen width < 1920px and >= 1280px, render the 1280px display
@include for-xl-only {
  .recent-notices-head {
    width: $breakpoint-lg;
  }

  .recent-notices {
    width: $breakpoint-lg;
    margin: 0px auto;
    margin-bottom: 75px;

    .container {
      .headerSection {
        margin: 0px auto;
        @include widthAndHeight(1096px, 78px, null, null, static, 1);
      }

      .recent-notice-grid {
        @include widthAndHeight(1248px, 650px, null, null, null, null);
        margin: 0px auto;
        background: $secondary 0% 0% no-repeat padding-box;
        border-radius: 10px;

        .grid {
          width: 1168px;
          height: 446px;

          opacity: 1;

          .notices_item {
            display: $grid;
            justify-content: space-between;
            max-height: 440px;
            overflow-x: hidden;
            overflow-y: auto;
            border-bottom: 1px solid $secondary;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: .8fr;

            .notice-card {
              width: 272px;
              height: 95px;
              background: $white 0% 0% no-repeat padding-box;
              border-radius: 10px;
              opacity: 1;
              margin: 10px 10px 10px 0px;
              padding: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .name {
                width: 160px;
                height: 25px;
                text-align: left;
                font: normal normal normal 18px/27px $font-secondary;
                letter-spacing: 1.08px;
                color: $dark-grey;
                opacity: 1;

              }

              .location_date {
                display: $flex;
                justify-content: space-between;
                height: 23px;
                text-align: left;
                font: normal normal normal 16px/25px $font-secondary;
                letter-spacing: 0.96px;
                color: $grey;
                opacity: 1;

                .location {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 125px;
                }
              }
            }
          }


          hr {
            position: relative;
            left: 0px;
            width: 1162px;
            height: 0px;
            border: 0px;
            border-bottom: 1px solid $secondary-dark;
            opacity: 1;
            margin-top: 15px;
          }

          .viewAll {
            a {
              float: right;
              border-bottom: 3px solid $primary;
              color: $primary;
              font: normal normal 600 16px/25px $font-secondary;
              padding-right: 0px;
              padding-top: 20px;
              padding-bottom: 8px;

              img {
                height: 6px;
                transform: rotate(270deg);
                vertical-align: middle;
                padding: 0px;
                margin-left: 10px;
              }
            }
          }
        }

        /* width */
        ::-webkit-scrollbar {
          width: 8px;
          height: 380px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px $grey;
          border-radius: 8px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: $grey;
          border-radius: 8px;
        }
      }
    }
  }
}

//When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .recent-notices-head {
    width: $breakpoint-md;
    margin: -67px auto 0;
  }

  .recent-notices {
    width: $breakpoint-md;
    margin: 0px auto $base-margin * 6;

    .container {
      .headerSection {
        @include widthAndHeight(714px, 67px, null, null, static, 1);
        margin: 0px auto;
        gap: 30px;

        h2 {
          font-size: calc($base-font-size + 12px);
          line-height: 40px;
          letter-spacing: 0.09px;
        }

        .selectDropdown1,
        .selectDropdown2 {
          font-size: cacl($base-font-size - 2px);
          line-height: 25px;
          letter-spacing: 0.96px;
        }
      }

      .recent-notice-grid {
        @include widthAndHeight(714px, 650px, null, null, null, null);
        margin: 0px auto;
        border-radius: 0px 0px 10px 10px;

        .nav {
          width: 100%;
        }

        .grid {
          width: 660px;
          margin: 0px 28px;
          height: 445px;
          opacity: 1;

          .notices_item {
            display: $grid;
            justify-content: space-between;
            max-height: 440px;
            overflow-x: hidden;
            overflow-y: auto;
            border-bottom: 1px solid $secondary;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: .8fr;

            .notice-card {
              width: 201px;
              height: 64px;
              background: $white 0% 0% no-repeat padding-box;
              border-radius: 10px;
              opacity: 1;
              margin: 10px 10px 10px 0px;
              padding: 10px;

              .name {
                width: 112px;
                height: 23px;
                text-align: left;
                font: normal normal normal 16px/27px $font-secondary;
                letter-spacing: 0.96px;
                color: $dark-grey;
                opacity: 1;
              }

              .location_date {
                display: $flex;
                justify-content: space-between;
                height: 23px;
                text-align: left;
                font: normal normal normal 14px/25px $font-secondary;
                letter-spacing: 0.84px;
                color: $grey;
                opacity: 1;

                .location {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 125px;
                }
              }
            }

            .recent-notices-no-data {
              width: 580px;
            }
          }

          hr {
            width: 654px;
            border: 0px;
            border-bottom: 1px solid $secondary-dark;
            opacity: 1;
            margin-top: 15px;
            margin-left: 0px
          }

          .viewAll {
            a {
              float: right;
              border-bottom: 3px solid $primary;
              color: $primary;
              font: normal normal 600 16px/25px $font-secondary;
              padding-right: 0px;
              padding-top: 20px;
              padding-bottom: 3px;

              img {
                height: 6px;
                transform: rotate(270deg);
                vertical-align: middle;
                padding: 0px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

//When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .recent-notices {
    display: none;
  }

  .mb-recent-notices-no-data {
    text-align: center;
    color: $red;
    font: normal normal normal 16px/26px $font-secondary;

  }

  .mb-recent-notices {
    display: block;
    overflow: hidden;
    @include widthAndHeight($breakpoint-sm, auto, null, null, null, null);
    background: $secondary 0% 0% no-repeat padding-box;
    padding-bottom: 59px;

    .container {
      margin: 0px 23px;

      h2 {
        @include widthAndHeight(100%, 43px, null, null, null, null);
        text-align: left;
        font: normal normal bold 32px/43px $font-primary;
        letter-spacing: 0.1px;
        color: $text-dark;
        margin: 26px 0px 10px 0px;
        padding: 0px;
      }

      .mb-header {
        @include widthAndHeight(382px, 67px, null, null, null, null);
        z-index: 1;
        padding: 0 20px;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px 3px 6px $light-black;
        background: $dark-green 0% 0% no-repeat padding-box;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        .selectMbDropdown1,
        .selectMbDropdown2 {
          flex: 1;

          color: $white;
          text-align: left;
          letter-spacing: 1.02px;
          font: normal normal normal calc($base-font-size - 2px)/26px $font-secondary;
          background: url('./images/icon-ionic-ios-arrow-down.svg') no-repeat;
          background-position: right;
        }

        &:not(:has(.selectMbDropdown2)) .selectMbDropdown1 {
          flex: .45;
        }
      }

      .mb-grid {
        @include widthAndHeight(382px, auto, null, null, null, null);
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $light-black;
        border-radius: 0px;

        .mb-nav {
          width: 100%;
          display: flex;

          a {
            flex: 1;

            text-align: center;
            color: $secondary-dark;
            padding: 10px 0;
            border-bottom: 1px solid $secondary-dark;
            font: normal normal 600 calc($base-font-size - 2px)/normal $font-secondary;

            &:hover {
              color: $dark-green;
              border-bottom: 3px solid $dark-green;
            }

            &.active {
              color: $dark-green;
              background: $light-secondary;
              border-bottom: 3px solid $dark-green;
            }
          }
        }

        .grid {
          @include widthAndHeight(380px, 500px, 0px, 0px, null, 1);
          overflow: hidden;
          padding: 10px 0px;

          .notices_item_mb {
            .notice-card {
              display: $flex;
              flex-wrap: wrap;
              justify-content: space-between;
              position: relative;
              top: 10px;
              padding: 10px;

              span {
                font: normal normal normal 16px/25px $font-secondary;
                letter-spacing: 0.96px;
                color: $dark-gray;

                &.name {
                  @include widthAndHeight(180px, 23px, 23px, 10px, relative, 1);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &.location {
                  @include widthAndHeight(120px, 23px, 23px, -10px, relative, 1);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  text-align: right;
                }

              }
            }
          }


        }
      }

      .grid-bottom-border {
        @include widthAndHeight(340px, 0px, 0px, 0px, null, 1);
        margin: 0px 23px;
        border: 1px solid $light-gray;
      }

      /* Pagi Element */
      .pagi-dots a {
        cursor: pointer;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        display: inline-block;
        color: $white;
        margin: 0px 4px;
        margin-top: 10px;
        border: 2px solid $primary
      }

      .pagi-dots a.active {
        background-color: $primary;
        color: $green;
      }

      .pagi-right i img,
      .pagi-left i img {
        font-size: 30px;
        position: relative;
        color: $light-green;
        width: 30px;
        height: 40px;
        opacity: 1;
        filter: invert(30%) sepia(3%) saturate(2737%) hue-rotate(114deg) brightness(95%) contrast(91%);
      }

      .pagi-left i img {
        transform: rotate(90deg);

      }

      .pagi-right i img {
        transform: rotate(270deg);
      }

      .pagi-right.active i img,
      .pagi-left.active i img {
        filter: invert(64%) sepia(9%) saturate(5878%) hue-rotate(120deg) brightness(92%) contrast(91%);
      }

      #recent-pagi {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        height: auto;
        padding: 30px 0px;
      }

      .mb-footer {
        @include widthAndHeight(382px, 66px, null, null, null, null);
        background: $dark-green 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        padding: 21px 119px;

        a {
          @include widthAndHeight(134px, 24px, null, null, null, null);
          font: normal normal 600 17px/26px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }
    }
  }
}

//When the screen width < 428px, render the 428px display
@include for-sm-down {
  .recent-notices {
    display: none;
  }

  .mb-recent-notices-no-data {
    text-align: center;
    color: $red;
    font: normal normal normal 16px/26px $font-secondary;
  }

  .mb-recent-notices {
    display: block;
    overflow: hidden;
    @include widthAndHeight(100%, auto, null, null, null, null);
    background: $secondary 0% 0% no-repeat padding-box;
    padding-bottom: 59px;

    .container {
      margin: 0px 23px;

      h2 {
        @include widthAndHeight(100%, 43px, null, null, null, null);
        text-align: left;
        font: normal normal bold 32px/43px $font-primary;
        letter-spacing: 0.1px;
        color: $text-dark;
        margin: 26px 0px 10px 0px;
        padding: 0px;
      }

      .mb-header {
        @include widthAndHeight(100%, 67px, null, null, null, null);
        z-index: 1;
        padding: 0 20px;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px 3px 6px $light-black;
        background: $dark-green 0% 0% no-repeat padding-box;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .selectMbDropdown1,
        .selectMbDropdown2 {
          flex: 1;

          color: $white;
          text-align: left;
          letter-spacing: 1.02px;
          font: normal normal normal calc($base-font-size - 2px)/26px $font-secondary;
          background: url('./images/icon-ionic-ios-arrow-down.svg') no-repeat;
          background-position: right;
        }

        &:not(:has(.selectMbDropdown2)) .selectMbDropdown1 {
          flex: .45;
        }
      }

      .mb-grid {
        @include widthAndHeight(100%, auto, null, null, null, null);
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $light-black;
        border-radius: 0px;

        .mb-nav {
          width: 100%;
          display: flex;

          a {
            flex: 1;

            text-align: center;
            color: $secondary-dark;
            padding: 10px 0;
            border-bottom: 1px solid $secondary-dark;
            font: normal normal 600 calc($base-font-size - 2px)/normal $font-secondary;

            &:hover {
              color: $dark-green;
              border-bottom: 3px solid $dark-green;
            }

            &.active {
              color: $dark-green;
              background: $light-secondary;
              border-bottom: 3px solid $dark-green;
            }
          }
        }

        .grid {
          @include widthAndHeight(100%, 500px, 0px, 0px, null, 1);
          overflow: hidden;
          padding: 10px 0px;

          .notices_item_mb {
            .notice-card {
              display: $flex;
              justify-content: space-between;
              position: relative;
              top: 10px;
              padding: $base-padding $base-padding $base-padding $base-padding * 2;

              span {
                font: normal normal normal 16px/25px $font-secondary;
                letter-spacing: 0.96px;
                color: $dark-gray;

                &.name {
                  @include widthAndHeight(180px, 23px, null, null, null, null);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &.location {
                  @include widthAndHeight(120px, 23px, null, null, null, null);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  text-align: right;
                }

              }
            }
          }


        }
      }

      .grid-bottom-border {
        @include widthAndHeight(85%, 0px, 0px, 0px, null, null);
        border: 1px solid $light-gray;
        margin: 0px 23px;
      }

      /* Pagi Element */
      .pagi-dots a {
        cursor: pointer;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        display: inline-block;
        color: $white;
        margin: 0px 4px;
        margin-top: 10px;
        border: 2px solid $primary
      }

      .pagi-dots a.active {
        background-color: $primary;
        color: $green;
      }

      .pagi-right i img,
      .pagi-left i img {
        font-size: 30px;
        position: relative;
        color: $light-green;
        width: 30px;
        height: 40px;
        opacity: 1;
        filter: invert(30%) sepia(3%) saturate(2737%) hue-rotate(114deg) brightness(95%) contrast(91%);
      }

      .pagi-left i img {
        transform: rotate(90deg);

      }

      .pagi-right i img {
        transform: rotate(270deg);
      }

      .pagi-right.active i img,
      .pagi-left.active i img {
        filter: invert(64%) sepia(9%) saturate(5878%) hue-rotate(120deg) brightness(92%) contrast(91%);
      }

      #recent-pagi {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        height: auto;
        padding: 30px 0px;
      }



      .mb-footer {
        @include widthAndHeight(100%, 66px, null, null, null, null);
        background: $dark-green 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        padding: 21px 19px;

        a {
          @include widthAndHeight(134px, 24px, null, null, null, null);
          font: normal normal 600 17px/26px $font-secondary;
          letter-spacing: 0px;
          color: $white;
        }
      }
    }
  }
}